import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import fields from './fields';
import AuthFormLayout from '../authFormLayout';
import BackLink from '../backLink';

class ResetPasswordFormLayout extends PureComponent {
  render() {
    const { translations, history, name, withoutBackLink } = this.props;

    if (name && name.length > 0) {
      const personalizedTranslations = {
        ...translations,
        title: translations.welcome,
        description: translations.personalizedDescription.replace(
          '[name]',
          atob(name)
        ),
      };

      return (
        <AuthFormLayout
          privacyPolicyCheck
          {...this.props}
          translations={personalizedTranslations}
          fields={fields}
          setPassword={withoutBackLink}
        />
      );
    }
    return (
      <AuthFormLayout
        {...this.props}
        fields={fields}
        setPassword={withoutBackLink}
      >
        {withoutBackLink ? null : (
          <BackLink
            title={translations.backLink}
            history={history}
            to="/login"
          />
        )}
      </AuthFormLayout>
    );
  }
}

ResetPasswordFormLayout.defaultProps = {
  name: '',
};

ResetPasswordFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  name: PropTypes.string,
};

export default ResetPasswordFormLayout;
