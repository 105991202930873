import { primaryPalette } from '../styles/theme';

export const RESET_PASSWORD_URL = 'set-password';

export const UDEMY_BASE_URL = 'https://udemy.com';

export const FREE_PLAN_USERS_LIMIT =
  parseInt(process.env.REACT_APP_FREE_PLAN_USER_LIMIT, 10) || 15;

export const STRIPE_BUSINESS_PRICE_ID =
  process.env.REACT_APP_STRIPE_BUSINESS_PRICE_ID;

export const STRIPE_BUSINESS_PRICE_YEARLY_ID =
  process.env.REACT_APP_STRIPE_BUSINESS_YEARLY_PRICE_ID;

export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;

export const SALES_CONTACT_EMAIL = 'mia@kadar.app';

export const FEATURE_COLORS = {
  TO_DO: primaryPalette.blue1,
  PLANS: primaryPalette.pink1,
  COURSES: primaryPalette.bluish1,
  ONE_ON_ONE: primaryPalette.purple,
  TEAM: primaryPalette.yellow2,
  TASKS: primaryPalette.green1,
};
