import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import Gradient from '../../../assets/images/b-gradient.png';
import { ReactComponent as CheckIcon } from '../../../assets/icons/f-check.svg';
import CustomButton from '../../shared/customButton';
import ToggleButtonSwitch from '../../shared/toggleButtonSwitch';
import InputField from '../../shared/inputField';
import http from '../../../utility/http';
import { API_SUBSCRIPTION } from '../../../constants/apiRoutes';
import {
  FREE_PLAN_USERS_LIMIT,
  SALES_CONTACT_EMAIL,
  STRIPE_BUSINESS_PRICE_ID,
  STRIPE_BUSINESS_PRICE_YEARLY_ID,
} from '../../../constants/appConfig';
import { sticky } from '../../../constants/helperCssRules';
import { hasActiveSubscription } from '../../../utility/subscriptionHelper';

const styles = ({ palette: { primary }, spacing, breakpoints }) => ({
  divider: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },

  content: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish7,
    borderRadius: 16,
    padding: spacing(6),
    width: '100%',
    [breakpoints.up('xLg')]: {
      padding: spacing(8),
    },
  },
  plans: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    minWidth: 'auto',
    gap: 16,
    [breakpoints.up('xLg')]: {
      minWidth: '453px',
      gap: 24,
    },
  },
  plan: {
    display: 'flex',
    borderRadius: 16,
    padding: spacing(6, 4),
    [breakpoints.up('xl')]: {
      padding: spacing(10, 10),
    },
  },
  basic: {
    background: primary.white,
  },
  business: {
    background: `url(${Gradient})  center / cover no-repeat`,
  },
  enterprise: {
    background: `linear-gradient(97.98deg, #4071E9 -3.51%, ${primary.bluish2} 118.22%)`,
    '& p': {
      color: primary.white,
    },
    '& h1': {
      color: primary.white,
    },
  },
  featureTitle: {
    textAlign: 'center',
    margin: spacing(12, 0, 10),
  },
  features: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '32px',
    justifyContent: 'center',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(4),
  },
  featureText: {
    color: primary.bluish3,
    lineHeight: '24px',
    marginLeft: spacing(4),
  },
  planLeftSection: {},
  planName: {
    color: primary.bluish2,
  },
  planDescriptionLimitReached: {
    color: primary.red1,
  },
  planPrice: {
    textAlign: 'right',
    lineHeight: '40px',
  },
  planRightSection: {
    display: 'flex',
    marginLeft: 'auto',
    flexShrink: 0,
    flexDirection: 'column',
  },
  actionButton: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
  },
  contactSalesButton: {
    marginTop: spacing(10),
  },
  priceTypeContainer: {
    display: 'flex',
  },
  toggleButtonSwitch: {
    marginLeft: 'auto',
    marginBottom: spacing(2),
  },
  inputQuantity: {
    width: 70,
  },
  quantityContainer: {
    display: 'flex',
    minHeight: 40,
  },
  usersQuantity: {
    marginLeft: 10,
    lineHeight: '38px',
  },
  freePlanCounter: {
    lineHeight: '38px',
  },
});

const PriceTypes = [
  {
    id: STRIPE_BUSINESS_PRICE_ID,
    type: 'Monthly',
    name: 'Monthly',
    value: STRIPE_BUSINESS_PRICE_ID,
    label: 'Monthly',
    price: '$4',
  },
  {
    id: STRIPE_BUSINESS_PRICE_YEARLY_ID,
    type: 'Yearly',
    name: 'Yearly',
    value: STRIPE_BUSINESS_PRICE_YEARLY_ID,
    label: 'Yearly',
    price: '$3.2',
  },
];

class PricingPlansPage extends PureComponent {
  state = {
    quantity: 100,
  };

  componentDidMount() {
    this.props.getOrganizationSettings();
  }

  onContactSales = () => {
    window.location.href = `mailto:${SALES_CONTACT_EMAIL}?subject=Kadar%20Enterprice%20Plan&body=`;
  };

  onUpgrade = (priceId, additionalParams = {}) => {
    http
      .post(API_SUBSCRIPTION, {
        price_id: priceId,
        success_url: `${window.location.origin}/`,
        cancel_url: window.location.origin,
        ...additionalParams,
      })
      .then(({ data }) => {
        window.location.href = data.subscription_session_url;
      });
  };

  renderUserCount = (userCount = 0, hasSubscription = false) => {
    const { classes, translations } = this.props;

    if (userCount > FREE_PLAN_USERS_LIMIT && !hasSubscription) {
      return (
        <Typography className={classes.planDescriptionLimitReached}>
          {FREE_PLAN_USERS_LIMIT} {translations.employeeLimitReached}
        </Typography>
      );
    }

    return (
      <Typography className={classes.freePlanCounter}>
        <b>{userCount}</b> /{FREE_PLAN_USERS_LIMIT} {translations.users}
      </Typography>
    );
  };

  render() {
    const { translations, classes, history, organizationSettings, priceId } =
      this.props;

    const { quantity } = this.state;

    const isYearlyPricing = priceId === STRIPE_BUSINESS_PRICE_YEARLY_ID;

    const { subscription_end_at, subscription } = organizationSettings;

    const hasSubscription = hasActiveSubscription(
      subscription,
      subscription_end_at
    );

    const [monthlyPrice, yearlyPrice] = PriceTypes;

    return (
      <div>
        <div className={classes.divider} />
        <div className={classes.content}>
          <div className={classes.priceTypeContainer}>
            <div className={classes.toggleButtonSwitch}>
              <ToggleButtonSwitch
                value={priceId}
                items={PriceTypes}
                onSelect={newValue =>
                  history.push(`/pricing-plans/${newValue}`)
                }
              />
            </div>
          </div>
          <div className={classes.plans}>
            <div className={classNames(classes.plan, classes.basic)}>
              <div className={classes.planLeftSection}>
                <Typography variant="h1" className={classes.planName}>
                  {translations.basic}
                </Typography>
                {this.renderUserCount(
                  organizationSettings?.user_count,
                  hasSubscription
                )}
              </div>
              <div className={classes.planRightSection}>
                <Typography className={classes.planPrice}>
                  <b>$0</b> {translations.perUser}
                </Typography>
                <CustomButton
                  className={classes.actionButton}
                  disabled
                  type="withTextDarkRounded"
                >
                  {hasSubscription
                    ? translations.freePlan
                    : translations.current}
                </CustomButton>
              </div>
            </div>
            <div className={classNames(classes.plan, classes.business)}>
              <div className={classes.planLeftSection}>
                <Typography variant="h1" className={classes.planName}>
                  {translations.business}
                </Typography>
                <div className={classes.quantityContainer}>
                  {isYearlyPricing && !hasSubscription ? (
                    <>
                      <InputField
                        type="number"
                        name="quantity"
                        className={classes.inputQuantity}
                        onChange={e =>
                          this.setState({ quantity: e.target.value })
                        }
                        value={quantity}
                        label=""
                      />
                      <Typography className={classes.usersQuantity}>
                        {translations.users}
                      </Typography>
                    </>
                  ) : null}
                </div>
              </div>
              <div className={classes.planRightSection}>
                <Typography className={classes.planPrice}>
                  <b>
                    {isYearlyPricing ? yearlyPrice.price : monthlyPrice.price}
                  </b>{' '}
                  {translations.perUser}
                </Typography>
                <CustomButton
                  className={classes.actionButton}
                  type="withTextDarkRounded"
                  onClick={() => {
                    this.onUpgrade(
                      priceId,
                      isYearlyPricing ? { quantity } : {}
                    );
                  }}
                  disabled={hasSubscription || quantity < FREE_PLAN_USERS_LIMIT}
                >
                  {hasSubscription
                    ? translations.current
                    : translations.choosePlan}
                </CustomButton>
              </div>
            </div>
            <div className={classNames(classes.plan, classes.enterprise)}>
              <div className={classes.planLeftSection}>
                <Typography variant="h1" className={classes.planName}>
                  {translations.enterprise}
                </Typography>
                <Typography className={classes.freePlanCounter}>
                  {translations.unlimitedAndMore}
                </Typography>
              </div>
              <div className={classes.planRightSection}>
                <CustomButton
                  className={classNames(
                    classes.actionButton,
                    classes.contactSalesButton
                  )}
                  type="withTextDarkRounded"
                  onClick={this.onContactSales}
                >
                  {translations.contactSales}
                </CustomButton>
              </div>
            </div>
          </div>
          <Typography className={classes.featureTitle} variant="h3">
            {translations.allFeaturesTitle}
          </Typography>
          <div className={classes.features}>
            {translations.featureListing.map(features => {
              return (
                <div key={`list_${features[0]}`}>
                  {features.map(feature => {
                    return (
                      <div key={feature} className={classes.featureItem}>
                        <CheckIcon />
                        <Typography
                          className={classes.featureText}
                          variant="subtitle1"
                        >
                          {feature}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

PricingPlansPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(PricingPlansPage);
