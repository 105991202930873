import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Fade } from '@material-ui/core';
import ToggleButtonSwitch from 'components/shared/toggleButtonSwitch';
import { getToggleReportViewItems } from 'utility/reportUtils';
import Search from '../../search';
import CustomButton from '../../customButton';
import UserAvatar from '../../userAvatar';
import CustomScrollBar from '../../customScrollBar';
import ConditionalTooltip from '../../conditionalTooltip';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close-gray.svg';
import { getPersonShortName, isArrayEmpty } from '../../../../utility/helpers';
import {
  getSelectedUserIndex,
  isUserSelectDisabled,
  getDisabledUserMessage,
} from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    marginTop: spacing(6),
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(4, 0),
  },
  clearAllButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '22px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
  search: {
    width: 300,
    marginRight: spacing(2),
  },
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 198,
  },
  users: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chartHeaderGroup: {
    display: 'flex',
    width: 'calc(100% - 16px)',
    justifyContent: 'space-between',
  },
  user: {
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 60,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    marginRight: spacing(2),
    marginBottom: spacing(4),
    transition: 'background-color .3s ease',
  },
  selectedUser: {
    backgroundColor: primary.blue6,
  },
  disabledUser: {
    cursor: 'not-allowed',
    backgroundColor: primary.bluish7,
  },
  disabledTooltip: {
    cursor: 'not-allowed',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  },
  removeIcon: {
    flexShrink: 0,
    height: 14,
    width: 14,
    marginLeft: spacing(3),
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: 8,
    height: 'calc(100% - 16px)',
    width: 8,
  },
  toggleSwitchWrapper: {
    width: 253,
  },
}));

const UsersFilter = ({
  translations,
  hasFilter,
  title,
  users,
  selectedUsers,
  searchTerm,
  activeColors,
  maxSelectedUsers,
  shouldEnableOnlyFinishedUsers,
  isTeamReview,
  onClear,
  onSearch,
  onSelect,
  isAverageView,
  handleChangeView,
  hasReportChart,
  hasAverageChart,
}) => {
  const classes = useStyles();
  const switchItems = getToggleReportViewItems(translations, true);

  return (
    <div className={classes.root}>
      {hasFilter && (
        <>
          <Typography variant="body2">{title}</Typography>
          <div className={classes.searchWrapper}>
            <Search
              className={classes.search}
              placeholder={translations.search}
              value={searchTerm}
              onChange={onSearch}
            />
            <Fade in={!isArrayEmpty(selectedUsers)} unmountOnExit>
              <CustomButton
                className={classes.clearAllButton}
                type="addWithTextRounded"
                onClick={onClear}
              >
                {translations.clearAllButtonLabel}
              </CustomButton>
            </Fade>
          </div>
        </>
      )}
      <div className={classes.usersWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          passContentHeight
          verticalScroll
          removeScrollX
        >
          <div className={classes.chartHeaderGroup}>
            <div className={classes.users}>
              {hasFilter &&
                users.map(user => {
                  const isSelected = selectedUsers.includes(user.id);
                  const selectedUserIndex = getSelectedUserIndex(
                    user.id,
                    selectedUsers
                  );
                  const isDisabled = isUserSelectDisabled(
                    user,
                    selectedUsers,
                    maxSelectedUsers,
                    isSelected,
                    shouldEnableOnlyFinishedUsers
                  );

                  return (
                    <div key={`user_${user.id}`}>
                      <ConditionalTooltip
                        className={classNames({
                          [classes.disabledTooltip]: isDisabled,
                        })}
                        addTooltip={isDisabled}
                        message={getDisabledUserMessage(
                          translations,
                          user,
                          isTeamReview
                        )}
                      >
                        <div
                          style={{
                            backgroundColor:
                              isSelected && selectedUserIndex !== -1
                                ? activeColors[selectedUserIndex]
                                : undefined,
                          }}
                          className={classNames(classes.user, {
                            [classes.selectedUser]: isSelected,
                            [classes.disabledUser]: isDisabled,
                          })}
                          onClick={() =>
                            !isDisabled && onSelect(user.id, isSelected)
                          }
                        >
                          <UserAvatar
                            labelClass={classes.userLabel}
                            getCustomCaption={getPersonShortName}
                            user={user}
                            caption
                          />
                          <Fade in={isSelected} unmountOnExit>
                            <RemoveIcon className={classes.removeIcon} />
                          </Fade>
                        </div>
                      </ConditionalTooltip>
                    </div>
                  );
                })}
            </div>
            {hasReportChart && hasAverageChart && (
              <div className={classes.toggleSwitchWrapper}>
                <ToggleButtonSwitch
                  value={isAverageView}
                  items={switchItems}
                  onSelect={handleChangeView}
                  isFullWidth
                />
              </div>
            )}
          </div>
        </CustomScrollBar>
      </div>
    </div>
  );
};

UsersFilter.defaultProps = {
  selectedUsers: [],
  searchTerm: '',
  activeColors: [],
  maxSelectedUsers: 0,
  shouldEnableOnlyFinishedUsers: false,
};

UsersFilter.propTypes = {
  translations: PropTypes.object.isRequired,
  hasReportChart: PropTypes.bool.isRequired,
  hasAverageChart: PropTypes.bool.isRequired,
  hasFilter: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchTerm: PropTypes.string,
  selectedUsers: PropTypes.arrayOf(PropTypes.number),
  activeColors: PropTypes.arrayOf(PropTypes.string),
  maxSelectedUsers: PropTypes.number,
  shouldEnableOnlyFinishedUsers: PropTypes.bool,
  isTeamReview: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  handleChangeView: PropTypes.func.isRequired,
  isAverageView: PropTypes.bool.isRequired,
};

export default memo(UsersFilter);
