import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  createOrganizationUsingToken,
  setSignUp,
} from '../../store/modules/signUpResults';
import { signUpResultsSelector } from '../../store/selectors/signUpResultsSelector';
import { loadingSelector } from '../../store/selectors/loadingSelector';
import { setAuthInfo } from '../../store/modules/auth';
import SignUpSecondStepPage from '../../components/pages/signUpSecondStepPage';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...signUpResultsSelector(state),
  ...loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  createOrganizationUsingToken: data =>
    createOrganizationUsingToken(dispatch, data),
  setSignUpAction: data => dispatch(setSignUp(data)),
  setAuthInfo: data => dispatch(setAuthInfo(data)),
});

const SignUpSecondStepPageView = props => {
  const { token } = useParams();
  const translations = useTranslations(APP_PAGES.SIGN_UP_SECOND_STEP);

  return (
    <SignUpSecondStepPage
      {...props}
      token={token}
      translations={translations}
    />
  );
};

SignUpSecondStepPageView.propTypes = {
  signUpResults: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpSecondStepPageView);
