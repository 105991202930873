import { memo } from 'react';
import AuthContainer from '../../shared/authContainer';
import ForgottenPasswordForm from '../../shared/forgottenPasswordForm';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const ForgottenPasswordPage = props => {
  const translations = useTranslations(APP_PAGES.FORGOTTEN_PASSWORD);

  return (
    <AuthContainer translations={translations.authContainer}>
      <ForgottenPasswordForm {...props} translations={translations} />
    </AuthContainer>
  );
};

export default memo(ForgottenPasswordPage);
