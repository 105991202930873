import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { handleCustomHttpError } from '../../utility/helpers';
import { PERMISSIONS_LIST_BY_ROLE } from '../../constants/rolesAndPermissionList';
import { setAuthInfo } from '../../store/modules/auth';
import {
  getAllPresets,
  getOrganizationMenuSetup,
  getOrganizationSettings,
} from '../../store/modules/organizationSettings';
import { authSelector } from '../../store/selectors/authSelector';
import LoginPage from '../../components/pages/loginPage';
import { grantedPermissionsSelector } from '../../store/selectors/grantedPermissionsSelector';
import http, {
  AUTH,
  TOKEN_TYPE,
  API_DOMAIN,
  API_PROTOCOL,
} from '../../utility/http';
import { useTranslations } from '../../utility/useTranslations';
import { API_ME } from '../../constants/apiRoutes';
import StorageService from '../../utility/storageService';
import {
  getSubdomain,
  mergeAccessibleProfilesForUser,
} from '../../utility/uiUtils';
import { validateOrganizationName } from '../../store/modules/signUpResults';
import { organizationSettingsSelector } from '../../store/selectors/organizationSettingsSelector';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...authSelector(state),
  ...grantedPermissionsSelector(state),
  ...organizationSettingsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthInfo: data => dispatch(setAuthInfo(data)),
  getOrganizationSettings: () => getOrganizationSettings(dispatch),
  getAllPresets: () => getAllPresets(dispatch),
  getOrganizationMenuSetup: () => getOrganizationMenuSetup(dispatch),
});

const LoginPageView = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const {
    history,
    setAuthInfo: setAuth,
    getOrganizationSettings: getOrgInfo,
  } = props;
  const translations = useTranslations(APP_PAGES.LOGIN_PAGE);
  const subDomain = getSubdomain(window.location.hostname);

  const validateOrgName = useCallback(() => {
    setIsLoading(true);

    return validateOrganizationName(subDomain)
      .then(({ data }) => {
        if (data.exists) {
          if (token && token.length > 0) {
            http.defaults.headers.Authorization = `${TOKEN_TYPE} ${token}`;
            // fetch user by token
            return http.get(API_ME).then(user => {
              getOrgInfo();
              const authInfo = {
                ...user.data,
                access: token,
                permissions: PERMISSIONS_LIST_BY_ROLE[user.data.role],
                accessibleProfiles: mergeAccessibleProfilesForUser(user.data),
              };
              setAuth(authInfo);
              StorageService.set(AUTH, JSON.stringify(authInfo));
              return history.push('/');
            });
          }
          setIsLoading(false);
        } else {
          window.location = `${API_PROTOCOL}://${API_DOMAIN}${
            window.location.port.length > 0 ? `:${window.location.port}` : ''
          }`;
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleCustomHttpError(error, translations.somethingWentWrong);
      });
  }, [subDomain, token, translations, setAuth, getOrgInfo, history]);

  useEffect(() => {
    if (subDomain && subDomain.length > 0) {
      validateOrgName();
    }
  }, [subDomain, validateOrgName]);

  return (
    !isLoading && (
      <LoginPage {...props} subDomain={subDomain} translations={translations} />
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageView);
