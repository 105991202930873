import { isArrayEmpty } from '../../../utility/helpers';
import { primaryPalette } from '../../../styles/theme';
import { COMPETENCE_MAP_ATTRIBUTE_CONFIG } from '../../../constants/attributes';

export const NOTIFICATION_DELAY = 300;

export const NOTIFICATION_CLEAR_DELAY = 2000;

export const MAX_SELECTED_SCORES = 3;

export const getInitiallySelectedAttributes = measuredAttributes => {
  if (!isArrayEmpty(measuredAttributes)) {
    return measuredAttributes.slice(0, COMPETENCE_MAP_ATTRIBUTE_CONFIG.DEFAULT);
  }

  return measuredAttributes;
};

export const getChartData = attributeScores => ({
  datasets: [
    ...attributeScores,
    ...(attributeScores.length < COMPETENCE_MAP_ATTRIBUTE_CONFIG.MAX
      ? Array.from(
          {
            length:
              COMPETENCE_MAP_ATTRIBUTE_CONFIG.MAX - attributeScores.length,
          },
          (_, i) => ({
            name: `placeholder_bar_${i}`,
            stats: new Array(COMPETENCE_MAP_ATTRIBUTE_CONFIG.MAX * 2).fill(0),
          })
        )
      : []),
  ].reduce(
    (acc, attr) => [
      ...acc,
      {
        label: attr.name,
        data: Object.values(attr.stats),
        backgroundColor: attr.color,
        borderColor: primaryPalette.white,
        borderWidth: 1,
        barPercentage: 1,
        categoryPercentage: 0.98,
      },
    ],
    []
  ),
});

export const updateSelectedScores = (selectedScores, scoreDistribution) =>
  selectedScores.filter(selectedScore =>
    scoreDistribution.some(
      score => Object.values(score.stats)[selectedScore - 1]
    )
  );
