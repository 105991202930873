export const hasQuantitativeAttributeScores = attributes =>
  attributes.some(attribute => !!attribute?.score);

export const getTimelineTableColumns = labels => [
  { label: labels.sentAt },
  { label: labels.survey },
  { label: labels.status },
];

export const getFeedbackTableColumns = labels => [
  { label: labels.sentAt },
  { label: labels.comment },
  { label: labels.reviewer },
];

export const getAnswerWeight = answerWeight => parseFloat(answerWeight);
