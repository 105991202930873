import { memo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../assets/icons/logo-dark-big.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    [breakpoints.down('xs')]: {
      padding: spacing(0, 8),
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: spacing(8),
  },
  description: {
    marginTop: spacing(7),
    fontSize: 18,
    textAlign: 'center',
  },
}));

const SignUpFirstStepCompletedPage = () => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SIGN_UP_FIRST_STEP_COMPLETED);

  return (
    <div className={classes.main}>
      <Typography variant="h1" className={classes.title}>
        {translations.title}
      </Typography>
      <Logo />
      <Typography className={classes.description}>
        {translations.description}
      </Typography>
    </div>
  );
};

export default memo(SignUpFirstStepCompletedPage);
