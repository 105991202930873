import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

import ArrowTooltip from '../arrowTooltip';
import ActionButton from '../actionButton';
import InputField from '../inputField';
import ConditionalTooltip from '../conditionalTooltip';
import {
  replaceObjectInList,
  removeLastItemFromArray,
  asyncDebounce,
  isObjectEmpty,
  replaceObjectsInList,
} from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import {
  TITLE_FIELD,
  ANSWER_FIELD,
  ANSWER_TITLE_ERROR,
  QUESTION_TITLE_ERROR,
  TEXT,
  NEUTRAL_ANSWER,
  N_A,
  WARNING_BREAKPOINT_ANSWERS,
  WARNING_BREAKPOINTS_CHARACTERS,
  BOX_SIZE_BREAKPOINT,
  DELAY,
} from './config';
import { validateField } from '../../../utility/validation';

const styles = ({ palette: { primary }, spacing }) => ({
  questionWrapper: {
    marginBottom: spacing(6),
  },
  headerSection: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    padding: spacing(1, 2),
  },
  copyButton: {
    marginRight: spacing(2),
  },
  deleteButton: {
    paddingRight: 0,
  },
  answersWrapper: {
    padding: spacing(6, 0, 0, 9),
  },
  horizontalAnswerWeight: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: 12,
    fontFamily: 'ProximaNova-Regular',
  },
  verticalAnswerWeight: {
    marginBottom: spacing(2),
    marginLeft: spacing(3),
    fontSize: 12,
    fontFamily: 'ProximaNova-Regular',
  },
  verticalAnswerWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      width: '91.5%',
    },
  },
  verticalWeightLabel: {
    fontSize: 12,
    fontFamily: 'ProximaNova-Bold',
    justifyContent: 'end',
    display: 'flex',
    marginBottom: spacing(-3),
    marginRight: spacing(6),
    marginTop: spacing(3),
  },
  weightBlock: {
    display: 'flex',
  },
  horizontalWeightLabel: {
    fontSize: 12,
    fontFamily: 'ProximaNova-Bold',
    marginRight: spacing(6),
    marginTop: spacing(17.5),
  },
  horizontalAnswers: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  horizontalAnswerWrapper: {
    marginRight: spacing(2),
    display: 'grid',
    justifyContent: 'flex-start',
    '&:last-child': {
      marginRight: 0,
    },
  },
  horizontalMediumBox: {
    width: 78.5,
  },
  horizontalLargeBox: {
    width: 107,
  },
  horizontalSmallBox: {
    width: 'auto',
  },
  inputStyle: {
    backgroundColor: primary.white,
    marginBottom: spacing(2),
  },
  inputTitleStyle: {
    marginBottom: 0,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 18,
    lineHeight: '22px',
  },
  inputHorizontalStyle: {
    padding: spacing(0, 0.5),
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: spacing(1),
  },
  tooltip: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '14px',
    lineHeight: '20px',
    color: primary.bluish2,
    margin: '7px 8px',
  },
  titleErrorMessage: {
    position: 'absolute',
    bottom: -20,
  },
  fieldWithError: {
    marginBottom: 0,
  },
  inputHorizontalAnswers: {
    width: '100%',
    textAlign: 'center',
  },
  dragButton: {
    marginRight: spacing(1.5),
  },
});

const { DELETE_BUTTON, DUPLICATE_BUTTON, QUESTION_TITLE, QUESTION_ANSWER } =
  AUTOMATION_ID;

class QuestionEntry extends PureComponent {
  constructor(props) {
    super(props);
    this.handleInputBlur = asyncDebounce(this.handleInputBlur, DELAY);
  }

  state = {
    answers: [],
    titleText: '',
    errors: {},
    currentInputInitialValue: '',
    trackInitialValueLength: '',
    isMaxReached: false,
    isDirty: false,
  };

  componentDidMount() {
    const { isVertical, item, switchHorizontalWarning } = this.props;
    const { answers, text } = item;

    if (!isVertical) {
      const isMaxReached = answers.some(answer => {
        return this.isMaxNumberOfCharactersReached(answer.text, answers.length);
      });
      if (isMaxReached) {
        this.setIsMaxReached(true);
        switchHorizontalWarning(item.id);
      }
    }
    this.setState({ answers, titleText: text });
  }

  componentDidUpdate(prevProps) {
    const { answers, isMaxReached } = this.state;
    const { switchHorizontalWarning, isVertical, item } = this.props;
    if (prevProps.isVertical !== isVertical && !isVertical) {
      const checkIsMaxReached = answers.some(answer => {
        return this.isMaxNumberOfCharactersReached(answer.text, answers.length);
      });
      if (isMaxReached !== checkIsMaxReached) {
        switchHorizontalWarning(item.id);
        this.setIsMaxReached(checkIsMaxReached);
      }
    }
  }

  componentWillUnmount() {
    const { switchHorizontalWarning, item } = this.props;
    const { isMaxReached } = this.state;
    if (isMaxReached) {
      switchHorizontalWarning(item.id);
    }
  }

  setIsMaxReached = isMaxReached => {
    this.setState({ isMaxReached });
  };

  isMaxNumberOfCharactersReached = (answer, numberOfAnswers) => {
    const { hasNeutralAnswer } = this.props;
    let reducedNumberOfAnswers = numberOfAnswers;
    if (hasNeutralAnswer) {
      reducedNumberOfAnswers -= 1;
      if (answer === NEUTRAL_ANSWER) {
        return false;
      }
    }
    const numberOfCharacters = answer.length;
    return (
      (reducedNumberOfAnswers <= WARNING_BREAKPOINT_ANSWERS &&
        numberOfCharacters > WARNING_BREAKPOINTS_CHARACTERS.MEDIUM) ||
      (reducedNumberOfAnswers > WARNING_BREAKPOINT_ANSWERS &&
        numberOfCharacters > WARNING_BREAKPOINTS_CHARACTERS.SMALL)
    );
  };

  validateSingleField = async (value, fieldName, field) => {
    await validateField(field, value).then(errorType => {
      this.setState(prevState => {
        if (errorType) {
          return {
            errors: { ...prevState.errors, [fieldName]: errorType },
          };
        }
        const { [fieldName]: removedId, ...trimmedErrors } = prevState.errors;
        return { errors: { ...trimmedErrors } };
      });
    });
  };

  tooltipText = () => {
    const { classes, translations, disableActions } = this.props;
    return (
      <div className={classes.tooltip}>
        {disableActions
          ? translations.draggableTooltipDisabled
          : translations.draggableTooltip}
      </div>
    );
  };

  prepareData = fieldName => {
    const { item, hasNeutralAnswer } = this.props;
    const { answers, titleText, errors } = this.state;
    let trimmedAnswers = answers;
    if (hasNeutralAnswer) {
      trimmedAnswers = removeLastItemFromArray(answers);
    }
    if (fieldName === QUESTION_TITLE_ERROR) {
      return { ...item, answers: trimmedAnswers, text: titleText };
    }

    if (!isObjectEmpty(errors)) {
      const indexesWithError = Object.keys(errors).map(errorFieldName =>
        Number(errorFieldName.slice(-1))
      );
      const replacementValues = indexesWithError.map(index => ({
        [TEXT]: item.answers[index].text,
      }));
      const answersWithErrorReplaced = replaceObjectsInList(
        trimmedAnswers,
        indexesWithError,
        replacementValues
      );
      return { ...item, answers: answersWithErrorReplaced };
    }

    return { ...item, answers: trimmedAnswers };
  };

  handleInputBlur = async (inputValue, fieldName) => {
    const { isDirty, errors } = this.state;
    const { onSave } = this.props;

    if (isDirty) {
      if (!errors[fieldName]) {
        const preparedData = this.prepareData(fieldName);
        await onSave(preparedData);
      }
      this.setState({ isDirty: false });
    }
  };

  handleTitleChange = e => {
    const { value } = e.target;
    const { currentInputInitialValue } = this.state;
    this.setState(
      { titleText: value, isDirty: currentInputInitialValue !== value },
      () => {
        this.validateSingleField(value, QUESTION_TITLE_ERROR, TITLE_FIELD);
      }
    );
  };

  handleAnswerChange = (e, fieldName, index) => {
    const { isVertical, item, switchHorizontalWarning } = this.props;
    const {
      answers,
      currentInputInitialValue,
      isMaxReached,
      trackInitialValueLength,
    } = this.state;
    const { value } = e.target;

    if (!isVertical) {
      const isWarningConditionReached = this.isMaxNumberOfCharactersReached(
        value,
        answers.length
      );
      const initialWarningCondition = this.isMaxNumberOfCharactersReached(
        trackInitialValueLength,
        answers.length
      );

      if (initialWarningCondition !== isWarningConditionReached) {
        if (isWarningConditionReached) {
          if (isMaxReached !== isWarningConditionReached) {
            this.setState(
              {
                isMaxReached: isWarningConditionReached,
                trackInitialValueLength: value,
              },
              () => switchHorizontalWarning(item.id)
            );
          }
        } else {
          const updateMaxReached = answers.some((answer, i) => {
            if (i === index && answer.text !== value) {
              return this.isMaxNumberOfCharactersReached(value, answers.length);
            }
            return this.isMaxNumberOfCharactersReached(
              answer.text,
              answers.length
            );
          });

          if (isMaxReached !== updateMaxReached) {
            this.setState(
              {
                isMaxReached: updateMaxReached,
                trackInitialValueLength: value,
              },
              () => switchHorizontalWarning(item.id)
            );
          }
        }
      }
    }

    this.setState(
      {
        answers: replaceObjectInList(answers, index, {
          [TEXT]: value,
        }),
        isDirty: currentInputInitialValue !== value,
      },
      () => this.validateSingleField(value, fieldName, ANSWER_FIELD)
    );
  };

  render() {
    const {
      id,
      classes,
      item,
      onDuplicate,
      onDelete,
      translations,
      isDuplicateDisabled,
      disableActions,
      hasDefinedAnswers,
      forwardedRef,
      draggableProps,
      isVertical,
      answerWeights,
    } = this.props;

    const { answers, titleText, errors } = this.state;

    const titleHasError = !!errors[QUESTION_TITLE_ERROR];
    const numberOfAnswers = item.answers.length;

    return (
      <div
        id={id}
        {...draggableProps.draggableProps}
        ref={forwardedRef}
        className={classes.questionWrapper}
      >
        <Grid container spacing={0} className={classes.headerSection}>
          <Grid item xs={11} className={classes.leftSection}>
            <ArrowTooltip tooltipLabel={this.tooltipText()} position="top">
              <ActionButton
                className={classes.dragButton}
                type={ACTION_BUTTON_TYPES.DRAG}
                {...draggableProps.dragHandleProps}
              />
            </ArrowTooltip>
            <InputField
              id={QUESTION_TITLE}
              className={classes.inputTitleStyle}
              customErrorClass={classes.titleErrorMessage}
              value={titleText}
              placeholder={translations.placeholders.questionPlaceholder}
              error={titleHasError}
              errorMessage={translations[errors[QUESTION_TITLE_ERROR]]}
              onClick={e => e.stopPropagation()}
              onChange={this.handleTitleChange}
              onInputBlur={value =>
                this.handleInputBlur(value, QUESTION_TITLE_ERROR)
              }
              onInputFocus={value => {
                this.setState({
                  currentInputInitialValue: value,
                });
              }}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={1} className={classes.actionButtons}>
            <ConditionalTooltip
              addTooltip={isDuplicateDisabled}
              message={translations.duplicateQuestionDisabled}
            >
              <ActionButton
                id={DUPLICATE_BUTTON}
                className={classes.copyButton}
                type={ACTION_BUTTON_TYPES.COPY}
                isDisabled={isDuplicateDisabled}
                onClickHandler={() => {
                  onDuplicate(item);
                }}
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              addTooltip={disableActions}
              message={translations.deleteQuestionDisabled}
            >
              <ActionButton
                id={DELETE_BUTTON}
                className={classes.deleteButton}
                type={ACTION_BUTTON_TYPES.DELETE_FILL}
                onClickHandler={() => {
                  onDelete(item.id);
                }}
              />
            </ConditionalTooltip>
          </Grid>
        </Grid>
        {hasDefinedAnswers && isVertical && (
          <div className={classes.verticalWeightLabel}>
            {translations.weight}
          </div>
        )}
        {hasDefinedAnswers && (
          <div className={classes.weightBlock}>
            <Grid
              item
              xs={12}
              className={classNames(classes.answersWrapper, {
                [classes.horizontalAnswers]: !isVertical,
              })}
            >
              {answers.map((answer, index) => {
                const fieldName = `${ANSWER_TITLE_ERROR}_${index}`;
                const answerHasError = !!errors[fieldName];
                const answerInputClasses = classNames(classes.inputStyle, {
                  [classes.inputHorizontalStyle]: !isVertical,
                  [classes.fieldWithError]: answerHasError,
                });
                const isNeutralAnswer = answer.text === NEUTRAL_ANSWER;
                const inputValue = () => {
                  if (isNeutralAnswer) {
                    return N_A;
                  }
                  return answer.text;
                };
                const weightValue = isNeutralAnswer
                  ? ''
                  : `${answerWeights[index]}`;

                return (
                  <div
                    key={answer.id}
                    className={classNames({
                      [classes.horizontalAnswerWrapper]: !isVertical,
                      [classes.verticalAnswerWrapper]: isVertical,
                      [classes.horizontalMediumBox]:
                        numberOfAnswers <
                          BOX_SIZE_BREAKPOINT.THRESHOLD_FOR_LARGE &&
                        !isVertical,
                      [classes.horizontalLargeBox]:
                        numberOfAnswers <
                          BOX_SIZE_BREAKPOINT.THRESHOLD_FOR_MEDIUM &&
                        !isVertical,
                      [classes.horizontalSmallBox]:
                        numberOfAnswers >=
                          BOX_SIZE_BREAKPOINT.THRESHOLD_FOR_LARGE &&
                        !isVertical,
                    })}
                  >
                    {!(!isVertical && isNeutralAnswer) && (
                      <InputField
                        id={`${QUESTION_ANSWER}-${index}`}
                        className={answerInputClasses}
                        multiline={isVertical}
                        customInputClass={
                          !isVertical ? classes.inputHorizontalAnswers : ''
                        }
                        disabled={isNeutralAnswer}
                        placeholder={translations.placeholders.answer}
                        value={inputValue()}
                        error={answerHasError}
                        errorMessage={translations[errors[fieldName]]}
                        onClick={e => e.stopPropagation()}
                        onChange={e =>
                          this.handleAnswerChange(e, fieldName, index)
                        }
                        onInputBlur={value =>
                          this.handleInputBlur(value, fieldName)
                        }
                        onInputFocus={value =>
                          this.setState({
                            currentInputInitialValue: value,
                            trackInitialValueLength: value,
                          })
                        }
                      />
                    )}
                    <div
                      className={classNames({
                        [classes.verticalAnswerWeight]: isVertical,
                        [classes.horizontalAnswerWeight]: !isVertical,
                      })}
                    >
                      {weightValue}
                    </div>
                  </div>
                );
              })}
            </Grid>
            {!isVertical && (
              <div className={classes.horizontalWeightLabel}>
                {translations.weight}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

QuestionEntry.defaultProps = {
  id: undefined,
  disableActions: false,
  forwardedRef: () => {},
  draggableProps: {},
  hasDefinedAnswers: false,
};

QuestionEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  item: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  disableActions: PropTypes.bool,
  isVertical: PropTypes.bool.isRequired,
  hasDefinedAnswers: PropTypes.bool,
  hasNeutralAnswer: PropTypes.bool.isRequired,
  forwardedRef: PropTypes.func,
  draggableProps: PropTypes.object,
  isDuplicateDisabled: PropTypes.bool.isRequired,
  switchHorizontalWarning: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  answerWeights: PropTypes.array.isRequired,
};

export default withStyles(styles)(QuestionEntry);
