import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withFormik } from 'formik';

import Typography from '@material-ui/core/Typography';

import CustomButton from '../customButton';
import InputField from '../inputField';
import { API_DOMAIN } from '../../../utility/http';
import { validateOrganizationName } from '../../../store/modules/signUpResults';
import {
  isEmpty,
  validateOrganizationUrl,
  validateLength,
  validateAll,
} from '../../../utility/validation';
import { handleCustomHttpError } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    minHeight: 88,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    color: primary.bluish2,
    marginTop: spacing(7),
    marginBottom: spacing(6),
  },
  domain: {
    marginTop: spacing(8),
  },
  subDomain: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: 278,
    marginRight: spacing(3),
  },
  enter: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
});

const { WORKSPACE_URL, SUB_DOMAIN_SIGN_IN } = AUTOMATION_ID;

const fields = [
  {
    id: WORKSPACE_URL,
    name: 'subDomain',
    type: 'text',
    label: 'enterWorkspaceSubdomain',
    required: true,
    validators: [
      {
        translationKey: 'requiredWorkspaceUrl',
        validator: isEmpty,
      },
      {
        translationKey: 'invalidWorkspaceUrl',
        validator: validateOrganizationUrl,
      },
      {
        translationKey: 'invalidWorkspaceUrl',
        validator: validateLength,
      },
    ],
  },
];

const FIELD_NAME = 'subDomain';

class EnterOrganizationLoginForm extends PureComponent {
  render() {
    const {
      classes,
      translations,
      values,
      errors,
      isSubmitting,
      handleSubmit,
      handleChange,
    } = this.props;

    return (
      <div className={classes.main}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">
            {translations.enterWorkspaceTitle}
          </Typography>
          <Typography variant="h4" className={classes.subtitle}>
            {translations.enterWorkspaceSubTitle}
          </Typography>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              <div className={classes.subDomain}>
                {fields.map(field => (
                  <InputField
                    id={field.id}
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    onChange={handleChange}
                    onBlur={() => {}}
                    value={values[field.name] || ''}
                    label={translations[field.label]}
                    error={Boolean(Object.keys(errors).length)}
                    errorMessage={errors[field.name]}
                    fullWidth
                  />
                ))}
              </div>
              <Typography className={classes.domain}>.{API_DOMAIN}</Typography>
            </div>
            <CustomButton
              id={SUB_DOMAIN_SIGN_IN}
              className={classes.enter}
              submit
              type="withTextDarkRounded"
              disabled={isSubmitting || Boolean(Object.keys(errors).length)}
            >
              {translations.continue}
            </CustomButton>
          </form>
        </div>
      </div>
    );
  }
}

EnterOrganizationLoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withFormik({
    mapPropsToValues: () => ({ subDomain: '' }),

    validate: (values, props) => {
      return validateAll(fields, values, props.translations);
    },

    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      validateOrganizationName(values[FIELD_NAME])
        .then(({ data }) => {
          setSubmitting(false);

          if (data.exists) {
            const { port, protocol } = window.location;
            const currentPort = port && port.length > 0 ? `:${port}` : '';
            window.location = `${protocol}//${values[FIELD_NAME]}.${API_DOMAIN}${currentPort}/login`;
          } else {
            setFieldError(FIELD_NAME, props.translations.nonExistingWorkspace);
          }
        })
        .catch(error => {
          setSubmitting(false);
          handleCustomHttpError(error, props.translations.somethingWentWrong);
        });
    },
  })(EnterOrganizationLoginForm)
);
