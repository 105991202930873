import { useState, useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import UserAvatar from '../userAvatar';
import SelectField from '../selectField';
import CustomScrollBar from '../customScrollBar';
import ManageUserTagsDialog from '../manageUserTagsDialog';
import {
  isArrayEmpty,
  isObjectEmpty,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { useAvailableUsers } from '../../../utility/hooks';
import { UserStatuses } from '../../../constants/statuses';
import { PARAMS } from '../../../constants/pages';
import { USER_INFO } from '../../../constants/people';
import { BULK_ACTION_OPTIONS_VALUES } from '../../../constants/bulkActionOptions';
import { fieldsToShow } from './fields';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 186,
    marginBottom: spacing(4),
  },
  avatars: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  userAvatar: {
    width: '100%',
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  fields: {
    position: 'relative',
  },
  field: {
    marginBottom: spacing(6),
  },
  hasError: {
    marginBottom: spacing(1),
  },
  inviteMessage: {
    marginBottom: spacing(4),
  },
  usersToInvite: {
    marginTop: spacing(1),
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const BulkEditDialog = props => {
  const {
    translations,
    isOpened,
    selectedBulkAction,
    organizationUser,
    users,
    idleUsers,
    categories,
    onCancel,
    onSave,
  } = props;
  const [, Invited] = UserStatuses;
  const classes = useStyles();

  const [selected, setSelected] = useState({
    jobTitle: null,
    reportTo: null,
    role: null,
    track: null,
    level: null,
  });
  const [errors, setErrors] = useState({});

  const isChangeReport = useMemo(
    () =>
      selectedBulkAction.value === BULK_ACTION_OPTIONS_VALUES.changeReportingTo,
    [selectedBulkAction]
  );

  const params = useMemo(() => {
    if (!isOpened) return {};

    return isChangeReport && !isArrayEmpty(users)
      ? {
          [PARAMS.AVAILABLE_MANAGERS]: getObjectToNumberArray(users),
        }
      : {};
  }, [isOpened, users, isChangeReport]);

  const availableManagers = useAvailableUsers(params, organizationUser);

  const isInviteDisabled = useMemo(() => isArrayEmpty(idleUsers), [idleUsers]);
  const isInvite = useMemo(
    () => isArrayEmpty(fieldsToShow[selectedBulkAction.value]),
    [selectedBulkAction]
  );

  const getOptionsByField = useCallback(
    field => {
      if (field.options) {
        if (field.parent) {
          const parentValue = selected[field.parent.name];
          if (!parentValue) {
            return [];
          }
          const parent = props[field.parent.options].find(
            elem => elem.id === parentValue
          );

          return (parent && parent[field.options]) || [];
        }
        return props[field.options] || [];
      }
      return [];
    },
    [props, selected]
  );

  const resetDependants = fieldDependants => {
    if (fieldDependants && !isArrayEmpty(fieldDependants)) {
      const dependantsValues = fieldDependants.reduce((acc, dependant) => {
        return { ...acc, [dependant.name]: dependant.value };
      }, {});

      return setSelected(prevState => ({
        ...prevState,
        ...dependantsValues,
      }));
    }
  };

  const resetState = () => {
    setSelected(prevSelected => ({
      ...prevSelected,
      ...{
        jobTitle: null,
        reportTo: null,
        role: null,
        track: null,
        level: null,
      },
    }));
    setErrors({});
  };

  const onCloseDialog = () => {
    resetState();
    onCancel();
  };

  const handleSaveTags = ({ tags }) => {
    if (isArrayEmpty(tags)) {
      return onCloseDialog();
    }

    const postData = users.reduce((acc, user) => {
      return [
        ...acc,
        {
          id: user.id,
          ...(tags ? { tags } : {}),
        },
      ];
    }, []);

    onSave(postData);
  };

  // handleReportToChange = (value, fieldName) => {
  //   const { users, organizationMentor } = this.props;
  //   const trimmedValue = trimString(value);
  //   const userIds = users.reduce((acc, user) => [...acc, user.id], []);

  //   const params = {
  //     page: 1,
  //     page_size: 12,
  //     ordering: 'first_name',
  //     search: trimmedValue,
  //     status: [Idle.id, Invited.id, Activated.id],
  //     role: EMPLOYEE_ROLES_LIST,
  //     available_managers: userIds,
  //   };

  //   this.setState({
  //     [fieldName]: [],
  //   });

  //   if (trimmedValue) {
  //     return http
  //       .get(API_USERS, {
  //         params,
  //         paramsSerializer: data => parseDuplicateParameters(data),
  //       })
  //       .then(({ data: { results } }) => {
  //         const mentors = [organizationMentor].concat(results);
  //         this.setState({ mentors });
  //       });
  //   }

  //   return Promise.resolve(this.setState({ mentors: [], [fieldName]: [] }));
  // };

  // handleReportToSelect = (selectedMentor, fieldName) => {
  //   const { errors } = this.state;
  //   const updatedErrors = { ...errors };
  //   if (errors && errors[fieldName]) {
  //     updatedErrors[fieldName] = null;
  //   }

  //   this.setState({
  //     mentors: [],
  //     errors: updatedErrors,
  //     [fieldName]: selectedMentor,
  //   });
  // };

  const handleSelectValue = (name, dependants) => value => {
    const updatedErrors = { ...errors };

    if (errors && errors[name]) {
      updatedErrors[name] = null;
    }

    setSelected(prevSelected => ({
      ...prevSelected,
      [name]: value,
    }));
    resetDependants(dependants);
    setErrors(prevErrors => ({ ...prevErrors, ...updatedErrors }));
  };

  const validateAndSubmit = () => {
    const { JOB_TITLE, REPORT_TO, STATUS, TRACK_LEVEL } = USER_INFO;
    const { jobTitle, reportTo, role, track, level } = selected;
    const updatedErrors = {};
    const usersToHandle = isInvite ? idleUsers : users;

    const postData = usersToHandle.reduce((acc, user) => {
      return [
        ...acc,
        {
          id: user.id,
          ...(jobTitle ? { [JOB_TITLE]: jobTitle } : {}),
          ...(reportTo ? { [REPORT_TO]: reportTo?.id } : {}),
          ...(isInvite ? { [STATUS]: Invited.id } : {}),
          ...(role ? { role } : {}),
          ...(track ? { track } : {}),
          ...(level ? { [TRACK_LEVEL]: level } : {}),
        },
      ];
    }, []);

    fieldsToShow[selectedBulkAction.value].forEach(field => {
      if (field.validators && field.validators.length) {
        // go through all validators for a field
        for (let i = 0; i < field.validators.length; i += 1) {
          const isValid = field.validators[i].validator(selected[field.name]);

          if (!isValid && field.required) {
            // only store if it's invalid or required
            updatedErrors[field.name] =
              translations.fieldValidations[field.translationKey][
                field.validators[i].type
              ];
            // the first one that's not passing the check is being returned
            break;
          }
        }
      }
    });

    setErrors(updatedErrors);

    if (isObjectEmpty(updatedErrors)) {
      onSave(postData).then(() => {
        resetState();
      });
    }
  };

  const renderAvatars = () => {
    const usersToRender = isInvite ? idleUsers : users;

    return (
      <div className={classes.avatars}>
        {usersToRender.map(user => {
          return (
            <UserAvatar
              key={user.id}
              className={classes.userAvatar}
              user={user}
              variant="subtitle2"
              small
              caption
            />
          );
        })}
      </div>
    );
  };

  const renderListOfUsers = () =>
    !isArrayEmpty(users) && (
      <div className={classes.usersWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          passContentHeight
          verticalScroll
          removeScrollX
        >
          {renderAvatars()}
        </CustomScrollBar>
      </div>
    );

  const renderField = field => {
    const label = translations.fields[field.translationKey];
    const { placeholders } = translations.fields;
    const errorMessage = errors[field.name] || '';
    const hasError = !!errors[field.name];
    const fieldClasses = classNames(classes.field, {
      [classes.hasError]: hasError,
    });
    const labelHelpData = field.labelHelp && {
      tooltipText: translations.labelHelpData[field.translationKey].text,
    };

    return (
      <div key={field.name} className={fieldClasses}>
        <SelectField
          errorClass={classes.error}
          name={field.name}
          label={label}
          placeholder={placeholders[field.translationKey]}
          labelHelp={labelHelpData}
          value={selected[field.name]}
          hasError={hasError}
          errorMessage={errorMessage}
          shouldReturnOption={field.shouldReturnOption}
          isUser={field.isUser}
          options={
            field.isReportTo ? availableManagers : getOptionsByField(field)
          }
          parser={field.parser}
          required={field.required}
          isDisabled={
            (field.parent && !selected[field.parent.name]) ||
            (!isChangeReport && isArrayEmpty(getOptionsByField(field)))
          }
          isSearchDisabled={field.isSearchDisabled}
          isClearable={field.isClearable}
          onChange={handleSelectValue(field.name, field.dependants)}
          isFullWidth
        />
      </div>
    );
  };

  const renderInviteUsersMessage = () => {
    const {
      following,
      invited,
      userText,
      usersText,
      userHas,
      usersHave,
      noInvitation,
    } = translations.inviteMessage;
    const numberOfUsersToInvite = idleUsers.length;
    const numberOfUsersNotToInvite = users.length - numberOfUsersToInvite;

    return (
      <div className={classes.inviteMessage}>
        <Typography>
          {numberOfUsersNotToInvite > 0 &&
            `${noInvitation}${numberOfUsersNotToInvite}${
              numberOfUsersNotToInvite === 1 ? userHas : usersHave
            }`}
        </Typography>
        <Typography className={classes.usersToInvite}>
          {numberOfUsersToInvite > 0 &&
            `${following}${
              numberOfUsersToInvite === 1 ? userText : usersText
            } ${invited}`}
        </Typography>
      </div>
    );
  };

  return selectedBulkAction.key === 'applyTags' ? (
    <ManageUserTagsDialog
      translations={translations}
      isOpened={isOpened}
      categories={categories}
      userAvatars={renderListOfUsers()}
      onCancel={onCloseDialog}
      onSave={handleSaveTags}
    />
  ) : (
    <CustomModal
      title={translations[selectedBulkAction.key]}
      confirmButtonLabel={
        isInvite ? translations.inviteUsers : translations.save
      }
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      isConfirmDisabled={isInvite && isInviteDisabled}
      onClose={onCloseDialog}
      onConfirm={validateAndSubmit}
      isMedium
    >
      {isInvite && <div>{renderInviteUsersMessage()}</div>}
      {renderListOfUsers(isInvite)}
      <div className={classes.fields}>
        {fieldsToShow[selectedBulkAction.value]?.map(renderField)}
      </div>
    </CustomModal>
  );
};

BulkEditDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  jobTitles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isOpened: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationUser: PropTypes.shape({}).isRequired,
  selectedBulkAction: PropTypes.object.isRequired,
  idleUsers: PropTypes.array.isRequired,
};

export default memo(BulkEditDialog);
