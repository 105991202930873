import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down-dark.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DragIcon } from '../../../assets/icons/new-drag.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as DeleteFillIcon } from '../../../assets/icons/delete-new-icon.svg';
import { ReactComponent as DeleteFillIconDisabled } from '../../../assets/icons/delete-new-icon-gray.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove.svg';
import { ReactComponent as RemoveIconDisabled } from '../../../assets/icons/remove-gray.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/bar_chart_dark.svg';
import { ReactComponent as AttributeReportIcon } from '../../../assets/icons/details-dark.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon-new.svg';
import { ReactComponent as DotsIcon } from '../../../assets/icons/more_horiz.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close-red-background.svg';
import { ReactComponent as PreviewIcon } from '../../../assets/icons/remove_red_eye.svg';
import { ReactComponent as ResolveIcon } from '../../../assets/icons/playlist_add_check.svg';
import { ReactComponent as RescheduleIcon } from '../../../assets/icons/more_time.svg';
import { ReactComponent as AcceptIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg';
import { ReactComponent as ReloadIcon } from '../../../assets/icons/refresh-dark.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people-dark.svg';
import { ReactComponent as SyncIcon } from '../../../assets/icons/sync.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as SendIconDisabled } from '../../../assets/icons/send-gray.svg';
import { ReactComponent as AiIcon } from '../../../assets/icons/ai-icon.svg';
import { primaryPalette } from '../../../styles/theme';
import { AUTOMATION_ID } from '../../../constants/automationId';

export const ACTION_BUTTON_TYPES = {
  ATTRIBUTE_REPORT: 'ATTRIBUTE_REPORT',
  AI: 'AI',
  ACCEPT: 'ACCEPT',
  ARROW: 'ARROW',
  CLOSE: 'CLOSE',
  CLEAR: 'CLEAR',
  COPY: 'COPY',
  DELETE: 'DELETE',
  DELETE_FILL: 'DELETE_FILL',
  DRAG: 'DRAG',
  DOTS: 'DOTS',
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
  REPORT: 'REPORT',
  PREVIEW: 'PREVIEW',
  RELOAD: 'RELOAD',
  RESOLVE: 'RESOLVE',
  RESCHEDULE: 'RESCHEDULE',
  SYNC: 'SYNC',
  DOWNLOAD: 'DOWNLOAD',
  PEOPLE: 'PEOPLE',
  SEND: 'SEND',
};

export const BUTTON_ICONS = {
  [ACTION_BUTTON_TYPES.ACCEPT]: {
    id: AUTOMATION_ID.ACCEPT_BUTTON,
    icon: AcceptIcon,
  },
  [ACTION_BUTTON_TYPES.AI]: {
    icon: AiIcon,
  },
  [ACTION_BUTTON_TYPES.ARROW]: {
    icon: ArrowIcon,
  },
  [ACTION_BUTTON_TYPES.CLOSE]: {
    icon: CloseIcon,
  },
  [ACTION_BUTTON_TYPES.CLEAR]: {
    icon: ClearIcon,
  },
  [ACTION_BUTTON_TYPES.COPY]: {
    icon: CopyIcon,
  },
  [ACTION_BUTTON_TYPES.DELETE]: {
    icon: DeleteIcon,
  },
  [ACTION_BUTTON_TYPES.DELETE_FILL]: {
    icon: DeleteFillIcon,
    iconDisabled: DeleteFillIconDisabled,
  },
  [ACTION_BUTTON_TYPES.DRAG]: {
    icon: DragIcon,
  },
  [ACTION_BUTTON_TYPES.DOTS]: {
    icon: DotsIcon,
  },
  [ACTION_BUTTON_TYPES.EDIT]: {
    icon: EditIcon,
  },
  [ACTION_BUTTON_TYPES.PEOPLE]: {
    borderColor: primaryPalette.bluish1,
    icon: PeopleIcon,
  },
  [ACTION_BUTTON_TYPES.REMOVE]: {
    icon: RemoveIcon,
    iconDisabled: RemoveIconDisabled,
  },
  [ACTION_BUTTON_TYPES.RESOLVE]: {
    id: AUTOMATION_ID.RESOLVE_BUTTON,
    icon: ResolveIcon,
    borderColor: primaryPalette.bluish1,
  },
  [ACTION_BUTTON_TYPES.RESCHEDULE]: {
    id: AUTOMATION_ID.RESCHEDULE_BUTTON,
    icon: RescheduleIcon,
  },
  [ACTION_BUTTON_TYPES.REPORT]: {
    icon: ReportIcon,
  },
  [ACTION_BUTTON_TYPES.ATTRIBUTE_REPORT]: {
    icon: AttributeReportIcon,
  },
  [ACTION_BUTTON_TYPES.PREVIEW]: {
    icon: PreviewIcon,
  },
  [ACTION_BUTTON_TYPES.DOWNLOAD]: {
    icon: DownloadIcon,
  },
  [ACTION_BUTTON_TYPES.RELOAD]: {
    icon: ReloadIcon,
  },
  [ACTION_BUTTON_TYPES.SYNC]: {
    borderColor: primaryPalette.bluish1,
    icon: SyncIcon,
  },
  [ACTION_BUTTON_TYPES.SEND]: {
    icon: SendIcon,
    iconDisabled: SendIconDisabled,
    borderColor: primaryPalette.bluish1,
    borderDisabledColor: primaryPalette.bluish5,
  },
};
