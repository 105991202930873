import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import UpgradePopover from '../upgradePopover';
import VisibleForPermission from '../visibleForPermission';
import HorizontalNavigation from '../horizontalNavigation';
import UserMenu from '../userMenu';
import { sticky } from '../../../constants/helperCssRules';
import { PERMISSIONS } from '../../../constants/rolesAndPermissionList';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    borderBottom: `1px solid ${primary.bluish8}`,
    ...sticky(primary.white),
  },
  mainPerson: {
    borderBottom: 'none',
  },
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: spacing(8, 0, 6, 0),
    width: '100%',
  },
  welcomeTitle: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(4),
  },
  section: {
    marginLeft: 'auto',
  },
}));

const PageHeader = ({
  translations,
  user,
  title,
  navItems,
  userMenuItems,
  renderTitle,
  onGoToPricingPlan,
  organizationData,
  shouldHideUpgrade,
  permissions,
  onGoToProfile,
  anchorSubEl,
  handleClose,
}) => {
  const classes = useStyles();
  const { canAddAppSubscription } = PERMISSIONS;
  const hasNavigation = !isArrayEmpty(navItems);

  return (
    <div
      className={classNames(classes.main, {
        [classes.mainPerson]: hasNavigation,
      })}
    >
      <div className={classes.header}>
        {title ? (
          <Typography variant="h1" className={classes.welcomeTitle}>
            <span>{title}</span>
          </Typography>
        ) : (
          renderTitle()
        )}
        <VisibleForPermission
          permission={canAddAppSubscription}
          permissions={permissions}
          additionalCondition={!shouldHideUpgrade}
        >
          <div className={classes.section}>
            <UpgradePopover
              translations={translations}
              organizationData={organizationData}
              onGoToPricingPlan={onGoToPricingPlan}
            />
          </div>
        </VisibleForPermission>
        <UserMenu
          className={classNames({ [classes.section]: shouldHideUpgrade })}
          translations={translations}
          user={user}
          organizationData={organizationData}
          menuItems={userMenuItems}
          onGoToProfile={onGoToProfile}
          onGoToPricingPlan={onGoToPricingPlan}
          handleClose={handleClose}
          anchorSubEl={anchorSubEl}
        />
      </div>
      {hasNavigation && <HorizontalNavigation navItems={navItems} />}
    </div>
  );
};

PageHeader.defaultProps = {
  title: '',
  translations: {},
  navItems: [],
  renderTitle: () => {},
  shouldHideUpgrade: false,
  onGoToPricingPlan: () => {},
  handleClose: () => {},
};

PageHeader.propTypes = {
  translations: PropTypes.object,
  organizationData: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  userMenuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string,
  shouldHideUpgrade: PropTypes.bool,
  renderTitle: PropTypes.func,
  onGoToPricingPlan: PropTypes.func,
  handleClose: PropTypes.func,
  onGoToProfile: PropTypes.func.isRequired,
};

export default memo(PageHeader);
