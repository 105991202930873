import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as DarkCheck } from '../../../assets/icons/bold-check-dark.svg';
import { getPersonFullName } from '../../../utility/uiUtils';
import { ellipsis } from '../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  userCaption: {
    textAlign: 'left',
    maxWidth: 250,
    marginRight: spacing(2),
    color: primary.blue1,
    cursor: 'pointer',
    ...ellipsis(),
  },
  userCaptionDisabled: {
    color: primary.bluish1,
    cursor: 'default',
  },
  checkIcon: {
    marginRight: spacing(2),
  },
}));

const UserLabel = ({ className, user, isDisabled, onClickHandler }) => {
  const classes = useStyles();
  const userClass = classNames(classes.userCaption, className, {
    [classes.userCaptionDisabled]: isDisabled,
  });
  const name = getPersonFullName(user);

  const handleClick = e => {
    if (!isDisabled) {
      return onClickHandler(e);
    }
  };

  return (
    <Typography variant="subtitle2" className={userClass} onClick={handleClick}>
      {user?.is_submitted ? <DarkCheck className={classes.checkIcon} /> : null}
      {name}
    </Typography>
  );
};

UserLabel.defaultProps = {
  isDisabled: false,
  onClickHandler: () => {},
  className: '',
};

UserLabel.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    id: PropTypes.number,
  }).isRequired,
  isDisabled: PropTypes.bool,
  onClickHandler: PropTypes.func,
  className: PropTypes.string,
};

export default memo(UserLabel);
