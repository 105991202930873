import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem, makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import NavigationSection from './navigationSection';
import NavigationLink from './navigationLink';
import DraggableScroll from '../draggableScroll';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo-dark.svg';
import { getWorkspaceNameFromHostName } from '../../../utility/uiUtils';
import { getSidebarNavigationItems } from '../../../utility/navigationUtils';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_SMALL,
} from '../../../constants/sidebarNavigation';
import { shouldSidebarRender, getActiveSectionId } from './config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    root: {
      backgroundColor: primary.blue8,
      boxSizing: 'border-box',
      borderRight: `1px solid ${primary.bluish7}`,
      position: 'fixed',
      top: 0,
      left: 0,
      padding: spacing(9, 0),
      height: '100vh',
      width: SIDEBAR_WIDTH_SMALL,
      zIndex: 1002,
      [breakpoints.up('xl')]: {
        padding: spacing(9, 0),
        width: SIDEBAR_WIDTH,
      },
    },
    logo: {
      flexShrink: 0,
      width: 32,
      height: 32,
      margin: spacing(0, 2),
    },
    navigationItems: {
      padding: 0,
      width: '100%',
      maxHeight: 'calc(100vh - 144px)',
      maxWidth: 195,
      [breakpoints.up('xl')]: {
        maxWidth: 224,
      },
    },
    companyWrapper: {
      boxSizing: 'border-box',
      padding: spacing(0, 2),
      marginBottom: spacing(12),
      width: '100%',
      [breakpoints.up('xl')]: {
        padding: spacing(0, 6),
      },
    },
    company: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      height: 36,
      width: '100%',
    },
    link: {
      borderRadius: 8,
      padding: 0,
      marginBottom: spacing(5),
    },
    scroll: {
      boxSizing: 'border-box',
      cursor: 'default',
      [breakpoints.up('sm')]: {
        paddingRight: 0,
        paddingLeft: spacing(2),
        '&::-webkit-scrollbar': {
          width: '4px !important',
          height: 'calc(100% - 4px)',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          '&::-webkit-scrollbar-track': {
            backgroundColor: primary.bluish9,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: primary.bluish7,
          },
        },
      },
      [breakpoints.up('xl')]: {
        paddingLeft: spacing(6),
      },
    },
    colorBox: {
      backgroundColor: primary.bluish6,
      marginRight: spacing(2),
    },
  })
);

const SidebarNavigation = ({
  translations,
  user,
  organizationSettings,
  organizationMenuSetup,
}) => {
  const { pathname } = useLocation();
  const classes = useStyles();

  const navSections = getSidebarNavigationItems(
    translations,
    user,
    organizationSettings,
    organizationMenuSetup,
    classes
  );
  const [dashboardSection] = navSections;
  const activeRouteId = getActiveSectionId(navSections, pathname);
  const [expandedSectionIds, setExpandedSectionIds] = useState([
    ...(activeRouteId ? [activeRouteId] : [dashboardSection.id]),
  ]);

  const handleSectionExpand = sectionId => () => {
    const isExpanded = expandedSectionIds.includes(sectionId);

    if (isExpanded) {
      return setExpandedSectionIds(
        expandedSectionIds.filter(sId => sId !== sectionId)
      );
    }

    return setExpandedSectionIds([...expandedSectionIds, sectionId]);
  };

  return (
    shouldSidebarRender(pathname) && (
      <div className={classes.root}>
        <div className={classes.companyWrapper}>
          <NavLink className={classes.company} to="/">
            <LogoIcon className={classes.logo} />
            <TextBoxWithTooltip
              variant="h4"
              text={
                organizationSettings?.name ||
                getWorkspaceNameFromHostName(window.location.hostname)
              }
            />
          </NavLink>
        </div>
        <DraggableScroll className={classes.scroll} showDesktopScrollbars>
          <List component="nav" className={classes.navigationItems}>
            {navSections.map(section => {
              if (section.items) {
                return (
                  <NavigationSection
                    key={`nav_item_section_${section.title}`}
                    section={section}
                    activeRouteId={activeRouteId}
                    isExpanded={expandedSectionIds.includes(section.id)}
                    onExpand={handleSectionExpand(section.id)}
                  />
                );
              }

              return (
                <ListItem
                  key={`nav_item_section_${section.title}`}
                  className={classes.link}
                  button
                >
                  <NavigationLink linkData={section} isSectionLink />
                </ListItem>
              );
            })}
          </List>
        </DraggableScroll>
      </div>
    )
  );
};

SidebarNavigation.propTypes = {
  translations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  organizationMenuSetup: PropTypes.array.isRequired,
};

export default memo(SidebarNavigation);
