import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import SharedIcon from '../sharedIcon';
import ConditionalTooltip from '../conditionalTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  label: {
    color: primary.bluish3,
    lineHeight: '20px',
    marginBottom: spacing(2),
  },
  tooltip: {
    width: 'fit-content',
  },
  type: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  typeIcon: {
    width: 16,
    height: 16,
    marginRight: spacing(2),
  },
}));

const SurveyType = ({
  className,
  tooltipClass,
  label,
  type,
  hasTooltip,
  isIconStatus,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {label && (
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
      )}
      <ConditionalTooltip
        className={classNames(classes.tooltip, tooltipClass)}
        addTooltip={hasTooltip || isIconStatus}
        message={isIconStatus ? type.name : type.explanation}
      >
        <div className={classes.type}>
          <SharedIcon
            customIconWrapperClass={classes.iconWrapper}
            iconConfig={{ className: classes.typeIcon }}
            icon={type.surveyIcon}
            showIcon
          />
          {!isIconStatus && (
            <Typography variant="subtitle1">{type.name}</Typography>
          )}
        </div>
      </ConditionalTooltip>
    </div>
  );
};

SurveyType.defaultProps = {
  label: '',
  className: '',
  tooltipClass: '',
  hasTooltip: false,
  isIconStatus: false,
};

SurveyType.propTypes = {
  className: PropTypes.string,
  tooltipClass: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.shape({}).isRequired,
  hasTooltip: PropTypes.bool,
  isIconStatus: PropTypes.bool,
};

export default SurveyType;
