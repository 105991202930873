import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import ResetPasswordFormLayout from './layout';
import { validateAll } from '../../../utility/validation';
import fields from './fields';

const ResetPasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', repeatPassword: '' }),
  validate: (values, props) => {
    const errors = validateAll(fields, values, props.translations);

    if (
      values.password !== values.repeatPassword &&
      values.repeatPassword !== ''
    ) {
      errors.password = props.translations.passwordMismatch;
      errors.repeatPassword = props.translations.passwordMismatch;
    }

    return errors;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.handleSubmitHandler(values, props, setSubmitting);
  },
})(ResetPasswordFormLayout);

ResetPasswordForm.defaultProps = {
  withoutBackLink: false,
  signUpResults: {},
};
ResetPasswordForm.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmitHandler: PropTypes.func.isRequired,
  withoutBackLink: PropTypes.bool,
  signUpResults: PropTypes.object,
};

export default ResetPasswordForm;
