import { memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import VisibleForPermission from '../visibleForPermission';
import TagsCategory from '../tagsCategory';
import { PERMISSIONS, ROLES } from '../../../constants/rolesAndPermissionList';
import { isArrayEmpty, checkUserRole } from '../../../utility/helpers';
import { UserStatuses } from '../../../constants/statuses';
import NotificationCard from '../notificationCard';

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(8),
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(3),
  },
  title: {
    marginBottom: spacing(2),
  },
  description: {
    width: 500,
  },
  category: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const EmployeeTags = ({
  translations,
  grantedPermissions,
  categories,
  currentUser,
  user,
  onAddButton,
}) => {
  const classes = useStyles();
  const isAdmin =
    currentUser &&
    currentUser.role &&
    checkUserRole(currentUser.role, ROLES.ADMIN);
  const [, , , Suspended] = UserStatuses;
  const disabledAddButton =
    user.status === Suspended.id ||
    (currentUser.id === user.id && !isAdmin) ||
    (!currentUser.reporters[user.id] && !isAdmin);
  const { canEditGeneralInfo } = PERMISSIONS;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div>
          <Typography variant="h5" className={classes.title}>
            {translations.tagsInfo}
          </Typography>
          <Typography className={classes.description} variant="body2">
            {translations.tags.description}
          </Typography>
        </div>
        <VisibleForPermission
          permission={canEditGeneralInfo}
          permissions={grantedPermissions}
        >
          <CustomButton
            disabled={disabledAddButton}
            type="addDarkRoundedOutlined"
            onClick={onAddButton}
          >
            {translations.add}
          </CustomButton>
        </VisibleForPermission>
      </div>
      {!isArrayEmpty(categories) ? (
        <TransitionGroup>
          {categories.map(category => (
            <Fade
              key={`category_${category.id}`}
              className={classes.category}
              in
              appear
            >
              <div>
                <TagsCategory translations={translations} category={category} />
              </div>
            </Fade>
          ))}
        </TransitionGroup>
      ) : (
        <NotificationCard content={translations.noTags} />
      )}
    </div>
  );
};

EmployeeTags.propTypes = {
  translations: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddButton: PropTypes.func.isRequired,
};

export default memo(EmployeeTags);
