import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

const SET_DIALOG_VISIBILITY = 'SET_DIALOG_VISIBILITY';

const initialState = {
  dialogs: {
    confirmQuestionDeletionOpened: false,
    confirmQuestionDuplicateOpened: false,
    confirmAttributeDeletionOpened: false,
    warningDialogOpened: false,
    chooseNameDialogOpened: false,
    previewAttributeDialogOpened: false,
    questionStyleChangeDialogOpened: false,
    bulkEditDialogOpened: false,
    addUserTagsDialogOpened: false,
    forceCompleteSurveyDialogOpened: false,
    forceDeleteSurveyDialogOpened: false,
    forceDeleteCourseDialogOpened: false,
    deleteOneOnOneRecordOpened: false,
    deleteOneOnOneTopicOpened: false,
    upgradeRequiredDialogOpened: false,
    upgradeWarningDialogOpened: false,
    employeeShareDialogOpened: false,
    employeeUnshareDialogOpened: false,
  },
};

// ------------------------------------
// Actions
// ------------------------------------
export const setDialogVisibility = createAction(SET_DIALOG_VISIBILITY);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_DIALOG_VISIBILITY]: (state, { payload }) => {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        [`${payload.dialogName}Opened`]: payload.opened,
      },
    };
  },
};

export default handleActions(reducers, initialState);
