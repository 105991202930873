import { memo } from 'react';
import AuthContainer from '../../shared/authContainer';
import OrganizationSignUpForm from '../../shared/organizationSignUpForm';

const SignUpFirstStepPage = props => {
  return (
    <AuthContainer translations={props.translations.authContainer} signUpPage>
      <OrganizationSignUpForm {...props} />
    </AuthContainer>
  );
};

export default memo(SignUpFirstStepPage);
