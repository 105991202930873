import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import SurveyReportInfo from '../../shared/surveyReportInfo';
import ReviewReport from '../../shared/reviewReport';
import ReportDescription from '../../shared/reportDescription';
import NotificationCard from '../../shared/notificationCard';
import { transformReviewData } from '../../../utility/reportUtils';
import { isArrayEmpty, isUserClickable } from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  api_review_score_per_reviewer,
  api_review_score_per_subject,
} from '../../../constants/apiRoutes';
import {
  SURVEY_TYPES,
  CREATE_SURVEY_FIELD_NAMES,
} from '../../../constants/survey';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';
import { sticky } from '../../../constants/helperCssRules';
import {
  getSurveyStatuses,
  getTranslatedSurveyTypes,
} from '../surveysPage/config';

const styles = ({ palette: { primary }, spacing }) => ({
  divider: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  description: {
    marginTop: spacing(6),
    marginBottom: spacing(2),
  },
});

class SurveyReportPage extends PureComponent {
  state = {
    reviewReportId: null,
    textReplyQuestionId: null,
  };

  componentDidMount() {
    const { history, survey, attributes } = this.props;
    const [attribute] = attributes;
    const isDraft =
      survey[CREATE_SURVEY_FIELD_NAMES.STATUS] === SURVEY_STATUS_TYPES.DRAFT;

    if (isDraft) {
      return history.replace(`/not-found`);
    }

    this.handleViewReport(attribute.id)();
  }

  goToProfilePage = userId => {
    const { history } = this.props;
    history.push(`/people/${userId}`);
  };

  handleViewReport = attributeId => () => {
    const {
      survey,
      getAttribute,
      getReviewResults,
      getTextReplyReviewResults,
      getReviewParticipants,
      getReviewFeedback,
    } = this.props;
    const { reviewReportId } = this.state;

    if (attributeId !== reviewReportId) {
      return getAttribute(attributeId)
        .then(() => {
          const { review } = this.props;

          return Promise.all([
            getReviewParticipants(survey.id, attributeId),
            ...[
              review.feedback_field
                ? [getReviewFeedback(survey.id, attributeId)]
                : [],
            ],
            review.text_reply_questions
              ? getTextReplyReviewResults(survey.id, attributeId)
              : getReviewResults(survey.id, attributeId),
          ]);
        })
        .then(() =>
          this.setState({
            reviewReportId: attributeId,
            textReplyQuestionId: null,
          })
        );
    }

    this.setState({ reviewReportId: null, textReplyQuestionId: null });
  };

  handleViewTextReply = questionId => e => {
    e.stopPropagation();

    this.setState(prevState => ({
      textReplyQuestionId:
        prevState.textReplyQuestionId !== questionId ? questionId : null,
    }));
  };

  handleViewIndividualScores = attributeId => () => {
    const { survey } = this.props;
    return survey.type === SURVEY_TYPES.TEAM
      ? http.get(api_review_score_per_subject(survey.id, attributeId))
      : http.get(api_review_score_per_reviewer(survey.id, attributeId));
  };

  render() {
    const {
      classes,
      translations,
      organizationSettings,
      auth,
      survey,
      attributes,
      review,
      reviewResults,
      reviewFeedback,
      participants,
    } = this.props;
    const { reviewReportId, textReplyQuestionId } = this.state;
    const { type, createdFor, reviewers } = survey;
    const isTeamReview = type === SURVEY_TYPES.TEAM;
    const statuses = getSurveyStatuses(translations.statuses);
    const types = getTranslatedSurveyTypes(translations);
    const shouldHideReport =
      isArrayEmpty(createdFor) || isArrayEmpty(reviewers);

    return (
      <div>
        <div className={classes.divider} />
        {!shouldHideReport && (
          <>
            <SurveyReportInfo
              labels={translations.info}
              survey={survey}
              statuses={statuses}
              types={types}
              isTeamReview={isTeamReview}
              isUserClickable={isUserClickable(
                auth,
                organizationSettings.global_see_himself
              )}
              onGoToPersonProfile={this.goToProfilePage}
            />
            <ReportDescription
              className={classes.description}
              translations={translations.surveyTypesDescription}
              createdFor={createdFor}
              reviewers={reviewers}
              surveyType={type}
              isUserClickable={isUserClickable(
                auth,
                organizationSettings.global_see_himself
              )}
              onGoToPersonProfile={this.goToProfilePage}
            />
            {attributes.map(attribute => (
              <ReviewReport
                key={`review_${attribute.id}`}
                translations={translations.review}
                attribute={attribute}
                survey={survey}
                review={transformReviewData(review)}
                reviewResults={reviewResults}
                isExpanded={
                  reviewReportId === review.id &&
                  reviewReportId === attribute.id
                }
                isTeamReview={isTeamReview}
                textReplyQuestionId={textReplyQuestionId}
                feedback={reviewFeedback}
                participants={participants}
                isUserClickable={isUserClickable(
                  auth,
                  organizationSettings.global_see_himself
                )}
                onViewIndividualScores={this.handleViewIndividualScores(
                  attribute.id
                )}
                onGoToPersonProfile={this.goToProfilePage}
                onViewTextReply={this.handleViewTextReply}
                onViewReport={this.handleViewReport(attribute.id)}
              />
            ))}
          </>
        )}
        <NotificationCard
          title={translations.noReport}
          shouldFade={shouldHideReport}
        />
      </div>
    );
  }
}

SurveyReportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  review: PropTypes.shape({}).isRequired,
  reviewResults: PropTypes.shape({}).isRequired,
  reviewFeedback: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getReviewResults: PropTypes.func.isRequired,
  getTextReplyReviewResults: PropTypes.func.isRequired,
  getReviewFeedback: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  getReviewParticipants: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveyReportPage);
