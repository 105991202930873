import {
  isEmpty,
  validateLength,
  validateEmail,
  validateName,
  validateOrganizationUrl,
} from '../../../utility/validation';
import { AUTOMATION_ID } from '../../../constants/automationId';

const { USER_FIRST_NAME, USER_LAST_NAME, USER_EMAIL, WORKSPACE_URL } =
  AUTOMATION_ID;

export const personalInformation = [
  {
    id: USER_FIRST_NAME,
    name: 'first_name',
    type: 'text',
    label: 'firstName',
    required: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: validateName,
        type: 'invalid',
      },
      {
        validator: value => validateLength(value, 2, undefined, true),
        type: 'minLength',
      },
      {
        validator: value => validateLength(value, 0, 50),
        type: 'maxLength',
      },
    ],
  },
  {
    id: USER_LAST_NAME,
    name: 'last_name',
    type: 'text',
    label: 'lastName',
    required: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: validateName,
        type: 'invalid',
      },
      {
        validator: value => validateLength(value, 2, undefined, true),
        type: 'minLength',
      },
      {
        validator: value => validateLength(value, 0, 50),
        type: 'maxLength',
      },
    ],
  },
  {
    id: USER_EMAIL,
    name: 'email',
    type: 'email',
    label: 'email',
    required: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: validateEmail,
        type: 'invalid',
      },
      {
        validator: validateLength,
        type: 'exceededLength',
      },
    ],
  },
];

export const companyInfo = [
  {
    id: WORKSPACE_URL,
    name: 'domain_name',
    type: 'text',
    label: 'desiredUrl',
    required: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: validateOrganizationUrl,
        type: 'invalid',
      },
      {
        validator: value => validateLength(value, 0, 50),
        type: 'maxLength',
      },
    ],
  },
];
