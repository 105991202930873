import { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';
import RolesPermissionsTooltip from '../rolesPermissionsTooltip';
import Tooltip from '../tooltip';
import Control from './control';
import NoOptions from './noOptions';
import Input from './input';
import Menu from './menu';
import MenuList from './menuList';
import SingleValue from './singleValue';
import Option from './option';
import Placeholder from './placeholder';
import ValueContainer from './valueContainer';
import ClearIndicator from './clearIndicator';
import DropdownIndicator from './dropdownIndicator';
import GroupHeading from './groupHeading';
import SelectedItems from './selectedItems';
import { validateFieldSync } from '../../../utility/validation';
import {
  isObjectEmpty,
  isArrayEmpty,
  isItemInList,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_COMPONENTS } from '../../../constants/pages';
import { getParsedOptions, getParsedValue } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  main: { minWidth: 205 },
  root: { flexGrow: 1 },
  labelIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  multipleLabelHelp: {
    display: 'flex',
    marginBottom: spacing(2),
  },
}));

const SelectField = ({
  className,
  selectFieldClass,
  labelHelpClass,
  errorClass,
  labelClass,
  labelHelp,
  value,
  errorMessage,
  shouldRemoveLabel,
  parser,
  options,
  creatableOptionLabel,
  renderRolesTooltip,
  creatableOptionField,
  shouldReturnOption,
  shouldHideMultipleResults,
  shouldDisableSort,
  isAttributeDraggable,
  isScrollableResult,
  isCategorized,
  isSearchDisabled,
  isFullWidth,
  isCreatable,
  onCreateOption,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_COMPONENTS.SELECT_FIELD);
  const { id, label, hasHash, hasError, isMulti, isUser, isAttribute, isTag } =
    rest;

  // const [inputValue, setInputValue] = useState('');

  const parsedOptions = useMemo(
    () => getParsedOptions(options, parser, isCategorized, isUser),
    [options, parser, isCategorized, isUser]
  );

  const parsedValue = useMemo(
    () =>
      getParsedValue(
        parsedOptions,
        value,
        isMulti,
        isCategorized,
        parser,
        shouldReturnOption,
        shouldDisableSort
      ),
    [
      parsedOptions,
      value,
      isMulti,
      isCategorized,
      parser,
      shouldReturnOption,
      shouldDisableSort,
    ]
  );

  const components = {
    Control,
    Menu,
    NoOptionsMessage: NoOptions,
    MenuList,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    ClearIndicator,
    IndicatorSeparator: null,
    Input,
    GroupHeading,
  };

  const getCreatableOptionLabel = useCallback(
    optionValue =>
      optionValue &&
      `${creatableOptionLabel}: ${hasHash ? '#' : ''}${optionValue}`,
    [creatableOptionLabel, hasHash]
  );

  const getDisabledOption = useCallback(
    option =>
      option.isDisabled ||
      (!isMulti && option[parser.value] === parsedValue[parser.value]) ||
      (isMulti && isItemInList(parsedValue, option, parser.value)),
    [isMulti, parsedValue, parser]
  );

  // const onHandleInputChange = useCallback(
  //   (query, { action }) => {
  //     // Prevents resetting our input after option has been selected
  //     if (isMulti && action !== 'set-value') {
  //       return setInputValue(query);
  //     }

  //     return setInputValue(query);
  //   },
  //   [isMulti]
  // );

  const onValidateCreatableOption = useCallback(
    (optionValue, selectedValues, allOptions) =>
      validateFieldSync(creatableOptionField, optionValue, allOptions) === null,
    [creatableOptionField]
  );

  const handleChange = useCallback(
    selected => {
      if (isMulti) {
        const newSelected = shouldReturnOption
          ? selected
          : getObjectToNumberArray(selected, 'value');

        return onChange(newSelected || []);
      }

      if (selected?.action) {
        selected.action();
      }

      if (shouldReturnOption) {
        return onChange(selected || null);
      }

      return onChange(selected ? selected.value : null);
    },
    [isMulti, shouldReturnOption, onChange]
  );

  const handleDeselectItem = useCallback(
    selectedItem => {
      const updatedItems = parsedValue.filter(
        sItem => sItem[parser.value] !== selectedItem[parser.value]
      );

      return onChange(
        shouldReturnOption
          ? updatedItems
          : getObjectToNumberArray(updatedItems, parser.value)
      );
    },
    [onChange, parsedValue, shouldReturnOption, parser]
  );

  const handleCreateOption = useCallback(
    async optionName => {
      const option = await onCreateOption(optionName);

      onChange([
        ...value,
        ...(shouldReturnOption ? [option] : [option[parser.value]]),
      ]);
    },
    [onCreateOption, onChange, value, shouldReturnOption, parser]
  );

  return (
    <FormControl
      className={classNames(classes.main, className)}
      fullWidth={isFullWidth}
    >
      {!shouldRemoveLabel && (
        <FormHelperText classes={{ root: labelClass }}>
          <span>{label}</span>
          {!isObjectEmpty(labelHelp) && (
            <Tooltip
              customIconClass={classNames(classes.labelIcon, labelHelpClass)}
              text={labelHelp.tooltipText}
            />
          )}
        </FormHelperText>
      )}
      {!isArrayEmpty(renderRolesTooltip) ? (
        <div className={classes.multipleLabelHelp}>
          {renderRolesTooltip.map(({ role, data, title }, index) => (
            <RolesPermissionsTooltip
              key={role}
              role={role}
              roleData={data}
              title={title}
              order={index + 1}
            />
          ))}
        </div>
      ) : null}
      {isCreatable ? (
        <CreatableSelect
          className={selectFieldClass}
          translations={translations}
          instanceId={id}
          styles={{ option: () => ({}) }}
          value={parsedValue}
          // inputValue={inputValue}
          options={parsedOptions}
          components={components}
          isSearchable={!isSearchDisabled}
          controlShouldRenderValue={!isMulti}
          hideSelectedOptions={false}
          isValidNewOption={onValidateCreatableOption}
          isOptionDisabled={getDisabledOption}
          filterOption={createFilter({ ignoreAccents: false })}
          // closeMenuOnSelect={!isMulti}
          // blurInputOnSelect={!isMulti}
          formatCreateLabel={getCreatableOptionLabel}
          // onInputChange={onHandleInputChange}
          onCreateOption={handleCreateOption}
          onChange={handleChange}
          menuShouldScrollIntoView
          captureMenuScroll
          {...rest}
        />
      ) : (
        <Select
          className={selectFieldClass}
          translations={translations}
          instanceId={id}
          styles={{ option: () => ({}) }}
          value={parsedValue}
          // inputValue={inputValue}
          options={parsedOptions}
          components={components}
          isSearchable={!isSearchDisabled}
          controlShouldRenderValue={!isMulti}
          hideSelectedOptions={false}
          isOptionDisabled={getDisabledOption}
          filterOption={createFilter({ ignoreAccents: false })}
          // closeMenuOnSelect={!isMulti}
          // blurInputOnSelect={!isMulti}
          // onInputChange={onHandleInputChange}
          onChange={handleChange}
          menuShouldScrollIntoView
          captureMenuScroll
          {...rest}
        />
      )}
      {hasError && (
        <FormHelperText classes={{ error: errorClass }} error={hasError}>
          {errorMessage}
        </FormHelperText>
      )}
      {isMulti && !shouldHideMultipleResults && !isArrayEmpty(parsedValue) && (
        <SelectedItems
          items={parsedValue}
          isAttribute={isAttribute}
          isUser={isUser}
          isTag={isTag}
          isScrollableResult={isScrollableResult}
          isAttributeDraggable={isAttributeDraggable}
          onReorder={onChange}
          onDeselectItem={handleDeselectItem}
        />
      )}
    </FormControl>
  );
};

SelectField.defaultProps = {
  className: '',
  controlDisabledClass: '',
  errorClass: '',
  labelClass: '',
  labelHelpClass: '',
  selectFieldClass: '',
  controlClass: '',
  controlFocusClass: '',
  menuClass: '',
  menuListClass: '',
  id: undefined,
  label: '',
  placeholder: '',
  options: [],
  parser: {
    label: 'label',
    value: 'value',
    categoryLabel: 'categoryLabel',
    categoryValue: 'categoryValue',
  },
  renderRolesTooltip: [],
  value: '',
  creatableOptionLabel: '',
  isDisabled: false,
  hasError: false,
  errorMessage: '',
  isAttributeDraggable: false,
  isCreatable: false,
  isAttribute: false,
  isTag: false,
  isUser: false,
  isFullWidth: false,
  shouldHideMultipleResults: false,
  shouldDisableSort: false,
  isMulti: false,
  isSearchDisabled: false,
  isClearable: false,
  isOptionValueCentered: false,
  shouldReturnOption: false,
  labelHelp: {},
  creatableOptionField: {},
  isValueHighlighted: false,
  isCategorized: false,
  hasHash: false,
  shouldRemoveLabel: false,
  hasColorBox: false,
  hasCategoryColorBox: false,
  onCreateOption: () => {},
};

SelectField.propTypes = {
  className: PropTypes.string,
  menuClass: PropTypes.string,
  menuListClass: PropTypes.string,
  selectFieldClass: PropTypes.string,
  controlDisabledClass: PropTypes.string,
  controlClass: PropTypes.string,
  controlFocusClass: PropTypes.string,
  errorClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelHelpClass: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  parser: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hasHash: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
  ]),
  creatableOptionLabel: PropTypes.string,
  creatableOptionField: PropTypes.object,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isAttribute: PropTypes.bool,
  isTag: PropTypes.bool,
  isUser: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isAttributeDraggable: PropTypes.bool,
  shouldHideMultipleResults: PropTypes.bool,
  shouldDisableSort: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isValueHighlighted: PropTypes.bool,
  isOptionValueCentered: PropTypes.bool,
  isCategorized: PropTypes.bool,
  labelHelp: PropTypes.object,
  shouldRemoveLabel: PropTypes.bool,
  shouldReturnOption: PropTypes.bool,
  renderRolesTooltip: PropTypes.array,
  hasColorBox: PropTypes.bool,
  hasCategoryColorBox: PropTypes.bool,
  onCreateOption: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default memo(SelectField);
