import { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import http from '../../../utility/http';
import { API_REQUEST_RESET_PASSWORD } from '../../../constants/apiRoutes';
import AuthContainer from '../../shared/authContainer';
import CustomButton from '../../shared/customButton';
import BackLink from '../../shared/backLink';
import { RESET_PASSWORD_URL } from '../../../constants/appConfig';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    width: '394px',
    marginTop: spacing(4),
  },
  description: {
    margin: spacing(4, 0, 7),
  },
  descriptionText: {
    lineHeight: '24px',
    marginBottom: spacing(6),
    wordBreak: 'break-word',
  },
  returnLink: {
    marginTop: spacing(2.75),
  },
  inputWrapper: {
    marginBottom: spacing(1),
  },
  resendButton: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
}));

const ResetLinkSentPage = ({ history }) => {
  const { email, resend } = useParams();
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.RESET_LINK);
  const sentTo = translations.resetLink.replace('[EMAIL]', email);

  const resendPasswordLink = () => {
    http
      .post(API_REQUEST_RESET_PASSWORD, {
        email,
        activationUrl: RESET_PASSWORD_URL,
        clientDomain: window.document.location.origin,
      })
      .then(() => {
        history.push(`/reset-link-sent/${email}/resend`);
      });
  };

  return (
    <AuthContainer translations={translations.authContainer}>
      <Grid container spacing={0} className={classes.main}>
        <Grid item xs={12}>
          <Typography variant="h1">{translations.title}</Typography>
          <div className={classes.description}>
            <Typography className={classes.descriptionText}>
              {sentTo}
            </Typography>
            <Typography className={classes.descriptionText}>
              {translations.checkIfMissing}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={8}>
          <CustomButton
            className={classes.resendButton}
            type="withTextDarkRounded"
            disabled={resend !== undefined}
            onClick={resendPasswordLink}
          >
            {translations.submit}
          </CustomButton>
        </Grid>
        <Grid item xs={4}>
          <BackLink
            className={classes.returnLink}
            title={translations.backLink}
            history={history}
            to="/login"
          />
        </Grid>
      </Grid>
    </AuthContainer>
  );
};

ResetLinkSentPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default memo(ResetLinkSentPage);
