import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CustomButton from '../customButton';
import CustomCheckbox from '../customCheckbox';
import InputField from '../inputField';
import { gridColumn } from '../../../constants/gridColumn';
import { objectHasProperty } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    maxWidth: 392,
  },
  signUp: {
    width: 418,
  },
  description: {
    margin: spacing(5, 0, 10),
  },
  descriptionMultiLine: {
    margin: spacing(5, 0, 6),
  },
  inputErrorMultiLine: {
    marginBottom: spacing(10),
  },
  inputError: {
    color: primary.red2,
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: spacing(4),
  },
  inputErrorBorder: {
    border: `1px solid ${primary.red1}`,
  },
  signUpInput: {
    width: 392,
  },
  actionButtons: {
    marginTop: spacing(2),
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLink: {
    color: primary.bluish2,
  },
  checkboxDescription: {
    marginLeft: spacing(2),
  },
  border: {
    paddingBottom: spacing(6),
    borderBottom: '1px solid rgba(120, 144, 157, 0.2)',
  },
  submitButton: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
});

class AuthFormLayout extends PureComponent {
  state = {
    isChecked: false,
  };

  handleCheckboxChange = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }));
  };

  renderCustomCheckbox() {
    const { isChecked } = this.state;
    const { classes } = this.props;
    const { terms_of_service, privacy_policy, agree, and } =
      this.props.translations;
    return (
      <Grid item xs={12} className={classes.checkboxContainer}>
        <CustomCheckbox
          isChecked={isChecked}
          onChange={this.handleCheckboxChange}
        />
        <Typography className={classes.checkboxDescription}>
          {agree}{' '}
          <Link to={terms_of_service.url} className={classes.checkboxLink}>
            {terms_of_service.name}
          </Link>{' '}
          {and}{' '}
          <Link to={privacy_policy.url} className={classes.checkboxLink}>
            {privacy_policy.name}
          </Link>{' '}
        </Typography>
      </Grid>
    );
  }

  renderInfoOrError() {
    const { classes, errors, translations, touched, multiLine } = this.props;

    const errorKeys = Object.keys(errors);
    const error = errorKeys.length > 0 && touched[errorKeys[0]];
    const descriptionClasses = classNames(classes.description, {
      [classes.descriptionMultiLine]: multiLine,
      [classes.inputError]: error,
      [classes.inputErrorMultiLine]: multiLine && error,
    });

    if (error) {
      return (
        <Typography className={descriptionClasses}>
          {errors[errorKeys[0]]}
        </Typography>
      );
    }
    return (
      <Typography className={descriptionClasses}>
        {translations.description}
      </Typography>
    );
  }

  render() {
    const {
      values,
      classes,
      errors,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      translations,
      touched,
      fields,
      children,
      signUp,
      hideSubmitButton,
      privacyPolicyCheck,
      loginForm,
      subDomain,
      setPassword,
    } = this.props;
    const { isChecked } = this.state;
    const mainClasses = classNames(classes.main, { [classes.signUp]: signUp });

    const mainGridClasses = classNames({ [classes.border]: loginForm });

    return (
      <form onSubmit={handleSubmit} className={mainClasses}>
        <Grid container spacing={0} className={mainGridClasses}>
          <Grid item xs={12}>
            <Typography variant="h1">
              {loginForm
                ? `${translations.title} ${subDomain}`
                : translations.title}
            </Typography>
            {this.renderInfoOrError()}
          </Grid>
          <Grid item xs={12} className={classes.inputWrapper}>
            {fields.map(field => {
              const error =
                objectHasProperty(errors, field.name) && touched[field.name];
              const inputClasses = classNames(classes.input, {
                [classes.signUpInput]: signUp,
                [classes.inputErrorBorder]: error,
              });
              return (
                <InputField
                  id={field.id}
                  className={inputClasses}
                  key={field.name}
                  label={translations[field.label]}
                  type={field.type}
                  name={field.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[field.name] || ''}
                  fullWidth
                />
              );
            })}
          </Grid>
          {privacyPolicyCheck && this.renderCustomCheckbox()}
          <Grid
            item
            xs={signUp || setPassword ? gridColumn.twelve : gridColumn.six}
            className={classes.actionButtons}
          >
            {hideSubmitButton ? null : (
              <CustomButton
                id={AUTOMATION_ID.SUBMIT_FORM_BUTTON}
                className={classes.submitButton}
                submit
                type="withTextDarkRounded"
                disabled={isSubmitting || (privacyPolicyCheck && !isChecked)}
              >
                {translations.submit}
              </CustomButton>
            )}
          </Grid>
          <Grid item xs={signUp ? gridColumn.three : gridColumn.six}>
            {children}
          </Grid>
        </Grid>
      </form>
    );
  }
}

AuthFormLayout.defaultProps = {
  multiLine: false,
  children: null,
  signUp: false,
  hideSubmitButton: false,
  privacyPolicyCheck: false,
  loginForm: false,
  setPassword: false,
};

AuthFormLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  multiLine: PropTypes.bool,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element,
  signUp: PropTypes.bool,
  setPassword: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  privacyPolicyCheck: PropTypes.bool,
  loginForm: PropTypes.bool,
};

export default withStyles(styles)(AuthFormLayout);
