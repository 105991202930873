import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import CustomButton from '../customButton';
import UpgradePlan from '../upgradePlan';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  paper: {
    width: '400px',
    height: '135px',
    padding: spacing(5, 7, 6, 7),
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 16,
    boxShadow: 'none',
    marginTop: spacing(2),
  },
}));

const UpgradePopover = ({
  onGoToPricingPlan,
  organizationData,
  translations,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {!organizationData.hasSubscription ? (
        <CustomButton
          type="withTextLightRounded"
          aria-describedby={id}
          onClick={handleClick}
        >
          {translations.basic}
        </CustomButton>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UpgradePlan
          organizationData={organizationData}
          translations={translations}
          onGoToPricingPlan={onGoToPricingPlan}
        />
      </Popover>
    </div>
  );
};

export default UpgradePopover;
