import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import ArrowTooltip from '../arrowTooltip';
import CustomScrollBar from '../customScrollBar';
import { isArray, isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  tooltipLabel: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 220,
    marginBottom: spacing(3),
    padding: spacing(3),
  },
  tooltipTitle: {
    color: `${primary.bluish2}`,
    wordBreak: 'break-word',
    textAlign: 'left',
    padding: spacing(2, 3),
    marginTop: spacing(5),
  },
  popper: {
    pointerEvents: 'auto',
    minWidth: 308,
  },
  labelItemContainer: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    cursor: 'pointer',
    borderBottom: `1px solid ${primary.bluish7}`,
    '&:last-of-type': {
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
  labelItemContainerDisabled: {
    cursor: 'default',
  },
  customTooltipClass: {
    minWidth: 308,
  },
  childrenContainer: {
    cursor: 'pointer',
  },
  childrenContainerDisabled: {
    cursor: 'default',
  },
  scrollY: {
    right: -18,
  },
});

class AsyncListTooltip extends PureComponent {
  state = {
    isOpen: false,
    items: null,
  };

  closeTooltip = () => this.setState({ isOpen: false });

  openTooltip = async e => {
    const { getLabelData, labelData } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) {
      e.stopPropagation();
      let tooltipItems;
      if (!isArrayEmpty(labelData)) {
        tooltipItems = labelData;
      } else {
        tooltipItems = await getLabelData().then(({ data }) => data);
      }

      this.setState({
        isOpen: true,
        items: tooltipItems.results || tooltipItems,
      });
    } else {
      this.closeTooltip();
    }
  };

  renderContent = items => {
    const {
      classes,
      isDisabled,
      renderLabelItem,
      isItemClickable,
      onItemClickHandler,
      isCustomContent,
    } = this.props;

    if (isCustomContent) {
      return renderLabelItem(items);
    }

    return (
      <>
        {items.map((item, index) => {
          const isClickable = !isDisabled && isItemClickable(item);

          return (
            <div
              key={`list_tooltip_item_${index}`}
              className={classNames(classes.labelItemContainer, {
                [classes.labelItemContainerDisabled]: !isClickable,
              })}
              onClick={
                isClickable
                  ? () => onItemClickHandler(item.id)
                  : e => e.stopPropagation()
              }
            >
              {renderLabelItem(item, isClickable)}
            </div>
          );
        })}
      </>
    );
  };

  renderTooltipLabel = () => {
    const { classes, title, customTooltipLabelClass } = this.props;
    const { items } = this.state;

    const labelClasses = classNames(
      classes.tooltipLabel,
      customTooltipLabelClass
    );

    return (
      isArray(items) &&
      !isArrayEmpty(items) && (
        <>
          {title && (
            <Typography variant="h5" className={classes.tooltipTitle}>
              {`${title}:`}
            </Typography>
          )}
          <div className={labelClasses}>
            <CustomScrollBar
              verticalScroll
              removeScrollX
              passContentHeight
              passContentWidth
              customScrollBarYClass={classes.scrollY}
            >
              {this.renderContent(items)}
            </CustomScrollBar>
          </div>
        </>
      )
    );
  };

  render() {
    const {
      classes,
      children,
      renderTooltip,
      moreText,
      getLabelData,
      renderLabelItem,
      onItemClickHandler,
      isDisabled,
      isItemClickable,
      labelData,
      isArrowHidden,
      customTooltipClass,
      customPopperClass,
      customTooltipLabelClass,
      isCustomContent,
      shouldDisablePortal,
      isTooltipDisabled,
      ...rest
    } = this.props;
    const { isOpen } = this.state;

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={this.closeTooltip}
      >
        <div>
          <ArrowTooltip
            open={isOpen}
            tooltipPopperClassName={classNames(
              classes.popper,
              customPopperClass
            )}
            tooltipLabel={this.renderTooltipLabel()}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            shouldDisablePortal={shouldDisablePortal}
            interactive
            tooltipClassName={classNames(
              classes.customTooltipClass,
              customTooltipClass
            )}
            isArrowHidden={isArrowHidden}
            {...rest}
          >
            <div
              className={classNames(classes.childrenContainer, {
                [classes.childrenContainerDisabled]: isTooltipDisabled,
              })}
              onClick={!isTooltipDisabled ? this.openTooltip : () => {}}
            >
              {children}
            </div>
          </ArrowTooltip>
        </div>
      </ClickAwayListener>
    );
  }
}

AsyncListTooltip.defaultProps = {
  title: '',
  isDisabled: false,
  isItemClickable: () => true,
  onItemClickHandler: () => {},
  labelData: [],
  getLabelData: () => {},
  isArrowHidden: false,
  isCustomContent: false,
  customTooltipClass: '',
  customPopperClass: null,
  customTooltipLabelClass: '',
  shouldDisablePortal: true,
  isTooltipDisabled: false,
};

AsyncListTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  customTooltipClass: PropTypes.string,
  customPopperClass: PropTypes.string,
  customTooltipLabelClass: PropTypes.string,
  isCustomContent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isTooltipDisabled: PropTypes.bool,
  title: PropTypes.string,
  labelData: PropTypes.array,
  isArrowHidden: PropTypes.bool,
  onItemClickHandler: PropTypes.func,
  isItemClickable: PropTypes.func,
  getLabelData: PropTypes.func,
  renderLabelItem: PropTypes.func.isRequired,
  shouldDisablePortal: PropTypes.bool,
};

export default withStyles(styles)(AsyncListTooltip);
