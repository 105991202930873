import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  FREE_PLAN_USERS_LIMIT,
  STRIPE_BUSINESS_PRICE_ID,
} from 'constants/appConfig';
import { API_SUBSCRIPTION } from 'constants/apiRoutes';
import CustomButton from '../customButton';
import http from '../../../utility/http';

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    marginBottom: spacing(1),
  },
  counts: {
    display: 'flex',
    marginLeft: 'auto',
  },
  currentDivider: {
    margin: spacing(0, 0.5),
    lineHeight: '36px',
  },
  currentCount: {
    lineHeight: '36px',
  },
  employeeLimit: {
    lineHeight: '36px',
  },
  unlimited: {
    fontSize: 24,
  },
  currentText: {
    marginLeft: spacing(0.5),
    lineHeight: '36px',
  },
  content: {
    fontFamily: 'ProximaNova-Light',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: spacing(4),
  },
  actionButton: {
    marginLeft: spacing(3),
  },
});

class UpgradePlan extends PureComponent {
  render() {
    const { classes, translations, organizationData, onGoToPricingPlan } =
      this.props;
    const { userCount, hasSubscription } = organizationData;

    const onUpgrade = () => {
      http
        .post(API_SUBSCRIPTION, {
          price_id: STRIPE_BUSINESS_PRICE_ID,
          success_url: `${window.location.origin}/`,
          cancel_url: window.location.origin,
        })
        .then(({ data }) => {
          window.location.href = data.subscription_session_url;
        });
    };

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h4">Basic Plan</Typography>
          <div className={classes.counts}>
            <Typography variant="subtitle2" className={classes.currentCount}>
              {userCount}
            </Typography>
            <Typography variant="body2" className={classes.currentDivider}>
              /
            </Typography>
            <Typography
              variant="body2"
              className={classNames(classes.employeeLimit, {
                [classes.unlimited]: hasSubscription,
              })}
            >
              {hasSubscription ? '∞' : FREE_PLAN_USERS_LIMIT}
            </Typography>
            <Typography className={classes.currentText}>
              {translations.employees}
            </Typography>
          </div>
        </div>
        <Typography variant="body2" className={classes.content}>
          {translations.needMoreUsers}{' '}
          <Typography variant="subtitle2" component="span">
            {translations.businessPlan}
          </Typography>{' '}
          {translations.unlimitedUsers}
        </Typography>
        <div className={classes.footer}>
          <CustomButton
            className={classes.actionButton}
            type="withTextDarkRounded"
            onClick={onUpgrade}
          >
            {translations.upgradePlan}
          </CustomButton>
          <CustomButton
            onClick={onGoToPricingPlan}
            className={classes.actionButton}
            type="withTextLightRounded"
          >
            {translations.learnMore}
          </CustomButton>
        </div>
      </div>
    );
  }
}

UpgradePlan.defaultProps = {
  onGoToPricingPlan: () => {},
};

UpgradePlan.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  organizationData: PropTypes.object.isRequired,
  onGoToPricingPlan: PropTypes.func,
};
export default withStyles(styles)(UpgradePlan);
