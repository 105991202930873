import {
  isEmpty,
  validateEmail,
  validateLength,
} from '../../../utility/validation';
import { AUTOMATION_ID } from '../../../constants/automationId';

const { USER_EMAIL, USER_PASSWORD } = AUTOMATION_ID;

const fields = [
  {
    id: USER_EMAIL,
    name: 'email',
    type: 'email',
    label: 'email',
    required: true,
    validators: [
      {
        translationKey: 'invalidEmailAddress',
        validator: validateLength,
      },
      {
        translationKey: 'invalidEmailAddress',
        validator: validateEmail,
      },
    ],
  },
  {
    id: USER_PASSWORD,
    name: 'password',
    type: 'password',
    label: 'password',
    required: true,
    validators: [
      {
        translationKey: 'invalidPassword',
        validator: isEmpty,
      },
    ],
  },
];

export default fields;
