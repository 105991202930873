import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import OneOnOneReport from '../../shared/oneOnOneReport';
import PeoplePicker from '../../shared/peoplePicker';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import { sticky } from '../../../constants/helperCssRules';
import {
  checkUserRole,
  isUserProfileAccessible,
  getObjectToNumberArray,
  isArrayEmpty,
  isArray,
} from '../../../utility/helpers';
import { hasNextPage } from '../../../utility/uiUtils';
import http from '../../../utility/http';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import {
  API_ONE_ONE_ONE_REPORT_PARTIALS,
  API_USERS_BASIC,
} from '../../../constants/apiRoutes';
import { PARAMS } from '../../../constants/pages';
import {
  PEOPLE_PARAM_SCOPE_VALUES,
  PEOPLE_DEFAULT_ORDERING,
} from '../../../constants/people';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { DEFAULT_PARAMS } from '../../../constants/oneOnOne';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  filterWrapper: {
    padding: spacing(6, 0, 4, 0),
    ...sticky(primary.white, 173),
  },
  filter: {
    maxWidth: 300,
  },
  loadMoreWrapper: {
    paddingTop: spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
});

class OneOnOneReportPage extends PureComponent {
  state = {
    isLoading: true,
    currentPage: 1,
    selectedUsers: [],
  };

  async componentDidMount() {
    const { auth, getAllUsers } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    const { data } = await this.getDirectReporters();
    getAllUsers({
      [PARAMS.EXCLUDE]: [auth.id],
      ...(isAdmin
        ? { [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE }
        : { [PARAMS.SCOPE]: PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS }),
    });

    if (!isArrayEmpty(data)) {
      this.setState(
        { selectedUsers: data, isLoading: false },
        this.onGetOneOnOneReport
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    const { clearOneOnOneReport, clearAllUsers } = this.props;

    clearOneOnOneReport();
    clearAllUsers();
  }

  goToProfilePage = userId => {
    const { history } = this.props;

    history.push(`/people/${userId}`);
  };

  onGoToMeetingDetails = meetingId => {
    const { history } = this.props;

    history.push(`/1-1/${meetingId}/details`);
  };

  getDirectReporters = () => {
    const { SCOPE } = PARAMS;
    const { DIRECT_REPORTS } = PEOPLE_PARAM_SCOPE_VALUES;

    return http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [SCOPE]: DIRECT_REPORTS,
      },
    });
  };

  onGetOneOnOneReport = (isLoadMore = false) => {
    const { getOneOnOneReport, clearOneOnOneReport } = this.props;
    const { currentPage, selectedUsers } = this.state;

    if (isArrayEmpty(selectedUsers)) {
      return clearOneOnOneReport();
    }

    return getOneOnOneReport(isLoadMore, {
      page: isLoadMore ? currentPage : DEFAULT_PARAMS.page,
      user: getObjectToNumberArray(selectedUsers),
    });
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }),
      () => this.onGetOneOnOneReport(true)
    );
  };

  handleUsersSelect = users =>
    this.setState(
      { currentPage: DEFAULT_PARAMS.page, selectedUsers: users },
      this.onGetOneOnOneReport
    );

  getWeekReport = (userId, week) => async () => {
    const { USER } = PARAMS;

    const params = {
      [USER]: userId,
      ...week,
    };

    return http.get(API_ONE_ONE_ONE_REPORT_PARTIALS, { params });
  };

  render() {
    const {
      classes,
      translations,
      auth,
      organizationSettings,
      allUsers,
      oneOnOneReport,
      formattedUsers,
    } = this.props;
    const { isLoading, selectedUsers } = this.state;
    const hasResults =
      isArray(oneOnOneReport?.results) &&
      !isArrayEmpty(oneOnOneReport?.results);
    const hasLoadMore = hasNextPage(oneOnOneReport);

    return (
      <div>
        <div className={classes.description}>
          <Typography variant="body2">
            {translations.descriptionPartOne}
          </Typography>
          <Typography variant="body2">
            {translations.descriptionPartTwo}
          </Typography>
        </div>
        <div className={classes.filterWrapper}>
          <PeoplePicker
            className={classes.filter}
            translations={translations.peoplePicker}
            preselectedUsers={selectedUsers}
            options={allUsers}
            blacklistedUsers={[auth.id]}
            onSelect={this.handleUsersSelect}
            showDirectReports
            formattedUsers={formattedUsers}
            hasSelectAll
          />
        </div>
        <div>
          {!isLoading && hasResults && (
            <OneOnOneReport
              translations={translations.report}
              reports={oneOnOneReport.results}
              canSeeUserProfile={isUserProfileAccessible(
                auth,
                organizationSettings.global_see_himself
              )}
              onGoToUserProfile={this.goToProfilePage}
              onGoToMeetingDetails={this.onGoToMeetingDetails}
              onGetWeekReport={this.getWeekReport}
            />
          )}
          {!isLoading && isArrayEmpty(selectedUsers) && (
            <NotificationCard
              className={classes.noResults}
              content={translations.noResults}
            />
          )}
          {hasLoadMore && (
            <div className={classes.loadMoreWrapper}>
              <CustomButton
                id={AUTOMATION_ID.LOAD_MORE_BUTTON}
                type="addWithTextRounded"
                className={classes.loadMoreButton}
                onClick={this.onLoadMore}
              >
                {translations.loadMore}
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

OneOnOneReportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  oneOnOneReport: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOneOnOneReport: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  clearOneOnOneReport: PropTypes.func.isRequired,
  formattedUsers: PropTypes.object.isRequired,
};

export default withStyles(styles)(OneOnOneReportPage);
