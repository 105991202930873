import { createAction, handleActions } from 'redux-actions';
import { createCustomHttpClient } from '../../utility/http';

import {
  API_ORGANIZATION_CREATE,
  API_VALIDATE_ORGANIZATION_NAME,
} from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SIGN_UP = 'SET_SIGN_UP';

const initialState = {
  signUpResults: { expired: false, inProgress: false },
};

// ------------------------------------
// Actions
// ------------------------------------
export const setSignUp = createAction(SET_SIGN_UP);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SIGN_UP]: (state, { payload }) => {
    return { ...state, signUpResults: payload };
  },
};

export const createOrganizationUsingToken = (dispatch, token) => {
  return createCustomHttpClient().post(API_ORGANIZATION_CREATE, { token });
};

export const validateOrganizationName = name => {
  return createCustomHttpClient().get(
    `${API_VALIDATE_ORGANIZATION_NAME}?q=${name}`
  );
};

export default handleActions(reducers, initialState);
