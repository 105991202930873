import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import ReportsBarChart from '../../shared/reportsBarChart';
import CustomDateRange from '../../shared/customDateRange';
import AttributesList from '../../shared/attributesList';
import DetailItem from '../../shared/detailItem';
import SelectField from '../../shared/selectField';
import InsightsDetailsHeader from '../../shared/insightsDetailsHeader';
import NotificationCard from '../../shared/notificationCard';
import ReportDetailsTable from '../../shared/reportDetailsTable';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { sticky } from '../../../constants/helperCssRules';
import { GROUPED_SURVEY_REPORTING_TYPES } from '../../../constants/survey';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import {
  checkUserRole,
  isArrayEmpty,
  asyncDebounce,
  isItemInList,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { getLast12Months } from '../../shared/customDateRange/config';
import { getGroupedSurveyOptions } from '../../../utility/reportUtils';
import {
  getTimelineTableColumns,
  getFeedbackTableColumns,
} from '../../../utility/attribute';
import { PARAMS } from '../../../constants/pages';
import { COMPETENCE_MAP_ATTRIBUTE_CONFIG } from '../../../constants/attributes';
import { getSurveyStatuses } from '../surveysPage/config';
import { GROUPING_PERIODS, formatChartData, generateLabelsMap } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    ...sticky(primary.white, 169),
    paddingBottom: spacing(6),
  },
  description: {
    marginBottom: spacing(4),
  },
  dateRange: {
    width: 220,
  },
  filters: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
    gridColumnGap: 20,
  },
  dropdown: {
    marginRight: spacing(5),
  },
  feedbackAndTimeline: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gridColumnGap: 16,
    paddingTop: spacing(8),
  },
  noData: {
    marginTop: spacing(4),
    padding: '40px 56px 40px',
  },
  attributesWrapper: {
    position: 'relative',
  },
  attributesLabel: {
    marginBottom: spacing(2),
  },
  notification: {
    color: primary.red1,
    position: 'absolute',
    left: 0,
    bottom: -16,
  },
  noResults: {
    marginTop: spacing(5),
  },
});

class PeopleInsightsPage extends PureComponent {
  state = {
    isLoading: true,
    ranges: getLast12Months(),
    groupingPeriod: GROUPING_PERIODS[0],
    selectedAttributes: [],
    surveyType: GROUPED_SURVEY_REPORTING_TYPES[0],
    chartData: {},
    barCountMap: {},
    hasNotification: false,
    notificationMessage: '',
  };

  componentDidMount() {
    this.handleGetAvailableAttributes();
  }

  componentWillUnmount() {
    const {
      clearReviewedAttributes,
      clearUserReports,
      clearReportComments,
      clearSurveysTimeline,
    } = this.props;

    clearReviewedAttributes();
    clearUserReports();
    clearReportComments();
    clearSurveysTimeline();
  }

  canSeeTimelineAndFeedback = () => {
    const { auth, user } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isSuperior = !!auth.accessibleProfiles[user.id];
    const isModeratorAndSuperior =
      checkUserRole(auth.role, ROLES.MODERATOR) && isSuperior;

    return isAdmin || isModeratorAndSuperior;
  };

  handleGetSurveyPath = (survey, isAccessible) => {
    const { auth } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    if (isAccessible) {
      if (isAdmin || survey?.creator === auth.id) {
        return `/surveys/${survey.id}/settings`;
      }

      return `/surveys/${survey.id}/report`;
    }
  };

  hasRedirectLinkCheck = isAdmin => survey => {
    const { auth } = this.props;

    return isAdmin || survey?.creator === auth.id || survey?.report_shared_to;
  };

  handleGetTimeline = () => {
    const { user, getSurveysTimeline } = this.props;
    const { surveyType, ranges } = this.state;
    const { TYPE, END, START, SENT_AT_AFTER, SENT_AT_BEFORE } = PARAMS;
    const isVisible = this.canSeeTimelineAndFeedback();

    if (isVisible) {
      const params = {
        [TYPE]: surveyType?.values,
        [SENT_AT_AFTER]: getStartEndOfDayUtc(ranges[START]),
        [SENT_AT_BEFORE]: getStartEndOfDayUtc(ranges[END], true),
      };

      return getSurveysTimeline(user.id, params);
    }
  };

  handleGetFeedback = () => {
    const { user, reviewedAttributes, getReportComments, clearReportComments } =
      this.props;
    const { surveyType, ranges } = this.state;
    const { ATTRIBUTE, SURVEY_TYPE, END, START, START_AT, END_AT } = PARAMS;
    const isVisible = this.canSeeTimelineAndFeedback();

    if (isVisible) {
      const params = {
        [ATTRIBUTE]: getObjectToNumberArray(reviewedAttributes),
        [SURVEY_TYPE]: surveyType?.values,
        [START_AT]: getStartEndOfDayUtc(ranges[START]),
        [END_AT]: getStartEndOfDayUtc(ranges[END], true),
      };

      return !isArrayEmpty(reviewedAttributes)
        ? getReportComments(user.id, params)
        : clearReportComments();
    }
  };

  handleGetAvailableAttributes = () => {
    const { user, getReviewedAttributes } = this.props;
    const { surveyType, ranges } = this.state;
    const { SURVEY_TYPE, START_AT, END_AT, START, END } = PARAMS;

    const attributeParams = {
      [SURVEY_TYPE]: surveyType.values,
      [START_AT]: getStartEndOfDayUtc(ranges[START]),
      [END_AT]: getStartEndOfDayUtc(ranges[END], true),
    };

    return getReviewedAttributes(user.id, attributeParams).then(data => {
      const selectedAttributes = data
        .filter(attribute => !attribute.text_reply_questions)
        .slice(0, COMPETENCE_MAP_ATTRIBUTE_CONFIG.DEFAULT);
      this.setState({ selectedAttributes }, () => {
        return Promise.all([
          this.handleGetChartData(),
          this.handleGetTimeline(),
          this.handleGetFeedback(),
        ]).then(() => this.setState({ isLoading: false }));
      });
    });
  };

  handleGetChartData = () => {
    const { user, getBarChartData } = this.props;
    const { surveyType, ranges, selectedAttributes, groupingPeriod } =
      this.state;
    const { ATTRIBUTE, SURVEY_TYPE, START_AT, END_AT, START, END } = PARAMS;

    const params = {
      [SURVEY_TYPE]: surveyType.values,
      [START_AT]: getStartEndOfDayUtc(ranges[START]),
      [END_AT]: getStartEndOfDayUtc(ranges[END], true),
      [ATTRIBUTE]: getObjectToNumberArray(selectedAttributes),
    };

    if (!isArrayEmpty(selectedAttributes)) {
      return getBarChartData(user.id, groupingPeriod.id, params).then(() =>
        this.setState({
          chartData: formatChartData(
            selectedAttributes,
            this.props.userReports
          ),
          barCountMap: generateLabelsMap(
            selectedAttributes,
            this.props.userReports
          ),
        })
      );
    }

    this.setState({
      chartData: {},
      barCountMap: {},
    });
  };

  handleAttributeSelect = attribute => {
    const { selectedAttributes } = this.state;
    const { translations } = this.props;
    const { MIN, MAX } = COMPETENCE_MAP_ATTRIBUTE_CONFIG;
    const isSelected = isItemInList(selectedAttributes, attribute);
    const attributesCount = selectedAttributes.length;
    let updatedAttributes = [];

    if (isSelected) {
      if (attributesCount > MIN) {
        updatedAttributes = selectedAttributes.filter(
          selectedAttribute => selectedAttribute.id !== attribute.id
        );

        this.setState(
          { selectedAttributes: updatedAttributes },
          this.handleGetChartData
        );
      } else {
        this.handleShowNotification(`${translations.minAttribute} ${MIN}`);
      }
    } else if (attributesCount < MAX) {
      updatedAttributes = [...selectedAttributes, attribute];
      this.setState(
        {
          selectedAttributes: updatedAttributes,
        },
        this.handleGetChartData
      );
    } else {
      this.handleShowNotification(`${translations.maxAttribute} ${MAX}`);
    }
  };

  handleShowNotification = (notificationMessage, hideAfter = 2000) =>
    this.setState(
      { hasNotification: true, notificationMessage },
      asyncDebounce(
        () =>
          this.setState({ hasNotification: false, notificationMessage: '' }),
        hideAfter
      )
    );

  handleSurveyTypeChange = selectedType => {
    this.setState(
      { surveyType: selectedType },
      this.handleGetAvailableAttributes
    );
  };

  handleGroupBy = groupBy => {
    this.setState({ groupingPeriod: groupBy }, this.handleGetChartData);
  };

  handlePeriodChange = newRange => {
    this.setState({ ranges: newRange }, this.handleGetAvailableAttributes);
  };

  renderNoData = () => {
    const { translations, classes } = this.props;

    return (
      <NotificationCard
        className={classes.noData}
        content={translations.noData}
      />
    );
  };

  renderTimeline = statuses => {
    const { auth, surveysTimeline, translations } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    const columns = getTimelineTableColumns(translations.timelineColumns);

    return (
      <div>
        <ReportDetailsTable
          translations={translations.timeline}
          columns={columns}
          details={surveysTimeline}
          statuses={statuses}
          hasRedirectLinkCheck={this.hasRedirectLinkCheck(isAdmin)}
          getRedirectLinkPath={this.handleGetSurveyPath}
          hasExpansionPanel
          isTimeline
        />
      </div>
    );
  };

  renderFeedback = () => {
    const { translations, reportComments } = this.props;

    const columns = getFeedbackTableColumns(translations.feedbackColumns);

    return (
      <div>
        <InsightsDetailsHeader
          title={translations.feedback.title}
          description={translations.feedback.description}
          columns={columns}
        />
        {reportComments && reportComments.length > 0 ? (
          <>
            {reportComments.map(feedback => {
              return (
                <DetailItem
                  key={feedback.submitted_at}
                  feedbackData={feedback}
                />
              );
            })}
          </>
        ) : (
          this.renderNoData()
        )}
      </div>
    );
  };

  renderNoResultMessage = () => {
    const { classes, translations, reviewedAttributes } = this.props;
    const { isLoading, selectedAttributes } = this.state;
    const hasAttributes = !isArrayEmpty(reviewedAttributes);

    const noResults =
      isArrayEmpty(selectedAttributes) && hasAttributes
        ? translations.noQuantitativeChartDataMessage
        : translations.noDataForChartMessage;

    return (
      <NotificationCard
        className={classNames({ [classes.noResults]: hasAttributes })}
        title={noResults.title}
        content={noResults.content}
        shouldFade={!isLoading}
      />
    );
  };

  render() {
    const { classes, translations, reviewedAttributes } = this.props;
    const {
      isLoading,
      ranges,
      groupingPeriod,
      selectedAttributes,
      surveyType,
      chartData,
      barCountMap,
      hasNotification,
      notificationMessage,
    } = this.state;
    const hasAttributes = !isArrayEmpty(reviewedAttributes);
    const hasChartData =
      !isArrayEmpty(selectedAttributes) &&
      chartData &&
      chartData?.labels?.length > 0;
    const timelineAndFeedbackPermission = this.canSeeTimelineAndFeedback();
    const surveyStatuses = getSurveyStatuses(translations.statuses);

    return (
      !isLoading && (
        <div>
          <div className={classes.header}>
            <Typography variant="body2" className={classes.description}>
              {translations.description}
            </Typography>
            <div className={classes.filters}>
              <SelectField
                value={surveyType}
                label={translations.surveyTypeDropdown}
                options={getGroupedSurveyOptions(translations.reportingTypes)}
                parser={{ value: 'key', label: 'name' }}
                onChange={this.handleSurveyTypeChange}
                shouldReturnOption
                isSearchDisabled
              />
              <SelectField
                label={translations.groupByDropdown}
                value={groupingPeriod}
                options={GROUPING_PERIODS}
                parser={{ value: 'id', label: 'name' }}
                onChange={this.handleGroupBy}
                shouldReturnOption
                isSearchDisabled
              />
              <CustomDateRange
                initialRange={{
                  startDate: ranges[PARAMS.START],
                  endDate: ranges[PARAMS.END],
                }}
                startAtKey={PARAMS.START}
                endAtKey={PARAMS.END}
                label={translations.periodLabel}
                onChange={this.handlePeriodChange}
              />
            </div>
          </div>
          {hasAttributes && (
            <div className={classes.attributesWrapper}>
              <Typography className={classes.attributesLabel} variant="h5">
                {translations.attributes}
              </Typography>
              <AttributesList
                attributes={reviewedAttributes}
                selectedAttributes={selectedAttributes}
                checkIsSelectable={attribute => !attribute.text_reply_questions}
                onSelect={this.handleAttributeSelect}
                isHorizontalList
                isScrollable
              />
              {hasNotification && (
                <Typography className={classes.notification} variant="caption">
                  {notificationMessage}
                </Typography>
              )}
            </div>
          )}
          {hasChartData ? (
            <ReportsBarChart
              attributesToDisplay={selectedAttributes}
              chartData={chartData}
              barCountMap={barCountMap}
              groupingPeriod={groupingPeriod.id}
            />
          ) : (
            this.renderNoResultMessage()
          )}
          <div className={classes.feedbackAndTimeline}>
            {timelineAndFeedbackPermission && this.renderFeedback()}
            {timelineAndFeedbackPermission &&
              this.renderTimeline(surveyStatuses)}
          </div>
        </div>
      )
    );
  }
}

PeopleInsightsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  auth: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  reviewedAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveysTimeline: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearReviewedAttributes: PropTypes.func.isRequired,
  clearReportComments: PropTypes.func.isRequired,
  clearUserReports: PropTypes.func.isRequired,
  clearSurveysTimeline: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeopleInsightsPage);
