import { memo } from 'react';
import PropTypes from 'prop-types';
import UserLabel from '../userLabel';
import AsyncListTooltip from '../asyncListTooltip';
import UserCountBadge from '../userCountBadge';

const UserCount = ({
  className,
  tooltipTitle,
  userCount,
  canSeeUserProfile,
  shouldDisablePortal,
  isReadOnly,
  goToUserProfile,
  getUsers,
}) => {
  const renderUser = user => (
    <UserLabel
      user={user}
      isDisabled={isReadOnly || !canSeeUserProfile(user)}
    />
  );

  return (
    <div className={className}>
      {userCount === 0 ? (
        <UserCountBadge userCount={userCount || 0} />
      ) : (
        <AsyncListTooltip
          title={tooltipTitle}
          getLabelData={getUsers}
          renderLabelItem={renderUser}
          shouldDisablePortal={shouldDisablePortal}
          isItemClickable={canSeeUserProfile}
          onItemClickHandler={goToUserProfile}
        >
          <UserCountBadge userCount={userCount || 0} />
        </AsyncListTooltip>
      )}
    </div>
  );
};

UserCount.defaultProps = {
  className: null,
  userCount: 0,
  isReadOnly: false,
  shouldDisablePortal: true,
  canSeeUserProfile: () => true,
  goToUserProfile: () => {},
};

UserCount.propTypes = {
  className: PropTypes.string,
  userCount: PropTypes.number,
  isReadOnly: PropTypes.bool,
  canSeeUserProfile: PropTypes.func,
  tooltipTitle: PropTypes.string.isRequired,
  shouldDisablePortal: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  goToUserProfile: PropTypes.func,
};

export default memo(UserCount);
