import { parseJSON, isFuture } from 'date-fns';
import { organizationSettingsSelector } from '../store/selectors/organizationSettingsSelector';

export const hasActiveSubscription = (subscriptionId, subscriptionEndAt) => {
  if (subscriptionId === null || subscriptionEndAt === null) {
    return false;
  }

  return isFuture(parseJSON(subscriptionEndAt));
};

export const initSubscriptionValidation = (store, getOrganizationSettings) => {
  document.addEventListener('visibilitychange', () => {
    const { organizationSettings } = organizationSettingsSelector(
      store?.getState()
    );
    const isActiveSubscription = hasActiveSubscription(
      organizationSettings?.subscription,
      organizationSettings?.subscription_end_at
    );
    if (!document.hidden && !isActiveSubscription) {
      getOrganizationSettings(store.dispatch);
    }
  });
};
