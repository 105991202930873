import { isEmpty } from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { BULK_ACTION_OPTIONS_VALUES } from '../../../constants/bulkActionOptions';

const JOB_TITLE_FIELDS = [
  {
    name: 'jobTitle',
    translationKey: 'jobTitle',
    type: FIELD_TYPES.SELECT,
    required: true,
    labelHelp: true,
    options: 'jobTitles',
    parser: {
      value: 'id',
      label: 'name',
    },
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
];

const reportFields = [
  {
    name: 'reportTo',
    type: FIELD_TYPES.SELECT,
    translationKey: 'reportTo',
    options: 'managers',
    required: true,
    isReportTo: true,
    label: 'reportTo',
    labelHelp: true,
    shouldReturnOption: true,
    parser: {
      value: 'id',
      label: 'first_name',
    },
    isUser: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
];

const LEVEL_TRACK_FIELDS = [
  {
    name: 'track',
    translationKey: 'track',
    type: 'select',
    labelHelp: true,
    options: 'tracks',
    parser: {
      value: 'id',
      label: 'name',
    },
    required: true,
    dependants: [
      {
        name: 'level',
        value: null,
      },
    ],
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
  {
    name: 'level',
    translationKey: 'level',
    type: 'select',
    labelHelp: true,
    required: true,
    options: 'track_levels',
    parser: {
      value: 'id',
      label: 'name',
    },
    parent: {
      name: 'track',
      options: 'tracks',
    },
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
];

const ROLE_FIELDS = [
  {
    name: 'role',
    translationKey: 'role',
    type: 'select',
    required: true,
    labelHelp: true,
    options: 'roles',
    parser: {
      value: 'id',
      label: 'name',
    },
    isSearchDisabled: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
];

const APPLY_TAGS_FIELDS = [
  {
    name: 'tags',
    translationKey: 'tags',
    options: 'categories',
  },
];

export const fieldsToShow = {
  [BULK_ACTION_OPTIONS_VALUES.changeReportingTo]: reportFields,
  [BULK_ACTION_OPTIONS_VALUES.changeJobTitle]: JOB_TITLE_FIELDS,
  [BULK_ACTION_OPTIONS_VALUES.inviteUsers]: [],
  [BULK_ACTION_OPTIONS_VALUES.changeLevelTrack]: LEVEL_TRACK_FIELDS,
  [BULK_ACTION_OPTIONS_VALUES.changeRole]: ROLE_FIELDS,
  [BULK_ACTION_OPTIONS_VALUES.applyTags]: APPLY_TAGS_FIELDS,
};
