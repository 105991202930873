import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import CustomButton from '../../shared/customButton';
import ActionButton from '../../shared/actionButton';
import { ReactComponent as StatusIcon } from '../../../assets/icons/status.svg';
import { ReactComponent as ThankYouPlaceholder } from '../../../assets/images/thank-you.svg';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo-dark.svg';
import { fillSurveySelector } from '../../../store/selectors/fillSurveySelector';
import { setIsInlineSurvey } from '../../../store/modules/fillSurvey';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { useCustomEffect } from '../../../utility/hooks';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const mapStateToProps = state => ({
  ...fillSurveySelector(state),
});
const mapDispatchToProps = dispatch => ({
  setIsInlineSurvey: isInline => dispatch(setIsInlineSurvey(isInline)),
});

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    main: {
      boxSizing: 'border-box',
      position: 'relative',
      padding: spacing(0, 6),
      overflowX: 'hidden',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100vh',
      width: '100%',
    },
    contentMessageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    statusIcon: {
      marginBottom: spacing(3),
    },
    title: {
      color: primary.green1,
      marginBottom: spacing(3),
      textAlign: 'center',
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '28px',
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    goBackButton: {
      boxSizing: 'border-box',
      backgroundColor: primary.blue1,
      border: 'none',
      borderRadius: 44,
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '24px',
      maxHeight: 40,
      color: primary.white,
      marginTop: spacing(3),
      padding: spacing(2, 4),
      '&:hover': {
        backgroundColor: primary.blue1,
      },
    },
    closeButton: {
      position: 'fixed',
      top: 20,
      right: 20,
    },
    placeholderIcon: {
      maxHeight: 'calc(100vh - 368px)',
      width: '100%',
    },
    footer: {
      backgroundColor: primary.bluish9,
      boxSizing: 'border-box',
      borderTop: `1px solid ${primary.green1}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: spacing(0, 6),
      height: 72,
      width: '100%',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
        padding: spacing(0, 18, 0, 6),
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: spacing(2),
      textDecoration: 'none',
    },
    logoIcon: {
      width: 24,
      height: 24,
    },
    description: {
      fontSize: 14,
      lineHeight: '18px',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
    company: {
      fontSize: 14,
      lineHeight: '18px',
      marginLeft: spacing(1),
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
  })
);

const KADAR_LANDING = 'https://www.kadar.app/';

const ThankYouPage = ({
  history,
  isInlineSurvey,
  setIsInlineSurvey: setInline,
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.THANK_YOU);

  useCustomEffect(() => {
    return () => {
      setInline(false);
    };
  });

  const goBackToApp = () => {
    history.go(-2);
  };

  return (
    <div className={classes.main}>
      {isInlineSurvey && (
        <ActionButton
          className={classes.closeButton}
          type={ACTION_BUTTON_TYPES.CLOSE}
          onClickHandler={goBackToApp}
        />
      )}

      <div className={classes.content}>
        <div className={classes.contentMessageWrapper}>
          <StatusIcon className={classes.statusIcon} />
          <Typography variant="h1" className={classes.title}>
            {translations.title}
          </Typography>
          <Typography className={classes.message}>
            <span>{translations.messagePart1}</span>
            <span>{translations.messagePart2}</span>
          </Typography>
          {isInlineSurvey && (
            <CustomButton
              className={classes.goBackButton}
              type="withTextDarkRounded"
              onClick={goBackToApp}
            >
              {translations.returnToApp}
            </CustomButton>
          )}
        </div>
        <ThankYouPlaceholder className={classes.placeholderIcon} />
      </div>
      <div className={classes.footer}>
        <Typography className={classes.description}>
          {translations.craftYourSurvey}
        </Typography>
        <a
          className={classes.logo}
          href={KADAR_LANDING}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoIcon className={classes.logoIcon} />
          <Typography className={classes.company} variant="h4">
            {translations.kadar}
          </Typography>
        </a>
      </div>
    </div>
  );
};

ThankYouPage.propTypes = {
  isInlineSurvey: PropTypes.bool.isRequired,
  setIsInlineSurvey: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ThankYouPage
);
