import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { setAuthInfo } from '../../store/modules/auth';
import { authSelector } from '../../store/selectors/authSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { setDialogVisibility } from '../../store/modules/dialogs';
import UpgradeRequiredDialog from '../../components/shared/upgradeRequiredDialog';
import { isObjectEmpty } from '../../utility/helpers';
import StorageService from '../../utility/storageService';
import http, { getHeaders } from '../../utility/http';

import { STRIPE_BUSINESS_PRICE_ID } from '../../constants/appConfig';

const mapStateToProps = state => ({
  ...authSelector(state),
  ...dialogSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthInfo: data => dispatch(setAuthInfo(data)),
  setDialogVisibilityAction: data => dispatch(setDialogVisibility(data)),
});

const whiteListedURLs = [
  '/terms-of-service',
  `/pricing-plans`,
  '/privacy-policy',
];

const UpgradeRequiredPageView = props => {
  const location = useLocation();

  const onLogout = () => {
    StorageService.clear();
    http.defaults.headers = getHeaders(StorageService);
    props.setAuthInfo({});
    props.history.push('/login');
  };

  return whiteListedURLs.every(route => !location.pathname.includes(route)) &&
    !isObjectEmpty(props.auth) ? (
    <UpgradeRequiredDialog
      auth={props.auth}
      isOpened={props.dialogs.upgradeRequiredDialogOpened}
      onClickHandler={() => {
        props.setDialogVisibilityAction({
          dialogName: 'upgradeRequiredDialog',
          opened: false,
        });
        props.history.push(`/pricing-plans/${STRIPE_BUSINESS_PRICE_ID}`);
      }}
      hasLogout
      onLogout={onLogout}
    />
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeRequiredPageView);
