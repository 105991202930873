import { memo } from 'react';
import { useParams } from 'react-router';
import AuthContainer from '../../shared/authContainer';
import ResetPasswordForm from '../../shared/resetPasswordForm';
import {
  API_JOIN_ON_INVITE,
  API_RESET_PASSWORD,
} from '../../../constants/apiRoutes';
import http from '../../../utility/http';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const ResetPasswordPage = props => {
  const { name, uid, token } = useParams();
  const translations = useTranslations(APP_PAGES.RESET_PASSWORD);

  const handleSubmitHandler = (values, _, setSubmitting) => {
    const resolveUrl =
      name && name.length > 0 ? API_JOIN_ON_INVITE : API_RESET_PASSWORD;

    http
      .post(resolveUrl, {
        new_password: values.password,
        uid,
        token,
      })
      .then(() => {
        props.history.push('/');
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <AuthContainer translations={translations.authContainer}>
      <ResetPasswordForm
        {...props}
        translations={translations}
        name={name}
        handleSubmitHandler={handleSubmitHandler}
      />
    </AuthContainer>
  );
};

export default memo(ResetPasswordPage);
