import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = ({ palette: { primary } }) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    background: 'transparent',
    zIndex: 10000,
  },
  loader: {
    backgroundColor: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '3px',
    zIndex: 10000,
    '& div': {
      backgroundColor: `${primary.blue2} !important`,
    },
  },
});

class Loader extends PureComponent {
  render() {
    const { classes, loading } = this.props;
    return (
      loading > 0 && (
        <div className={classes.root}>
          <LinearProgress className={classes.loader} />
        </div>
      )
    );
  }
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.number.isRequired,
};

export default withStyles(styles)(Loader);
