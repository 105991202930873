import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import AttributesList from '../../shared/attributesList';
import NotificationCard from '../../shared/notificationCard';
import CustomDateRange from '../../shared/customDateRange';
import CompetenceMapChart from '../../shared/competenceMapChart';
import UserCompetenceScores from '../../shared/userCompetenceScores';
import { sticky } from '../../../constants/helperCssRules';
import {
  isArrayEmpty,
  isUserProfileAccessible,
  asyncDebounce,
  isItemInList,
} from '../../../utility/helpers';
import { PARAMS } from '../../../constants/pages';
import { COMPETENCE_MAP_ATTRIBUTE_CONFIG } from '../../../constants/attributes';
import { getLast12Months } from '../../shared/customDateRange/config';
import {
  NOTIFICATION_DELAY,
  NOTIFICATION_CLEAR_DELAY,
  MAX_SELECTED_SCORES,
  getInitiallySelectedAttributes,
  getChartData,
  updateSelectedScores,
} from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    padding: spacing(8, 0, 6, 0),
    ...sticky(primary.white, 105),
  },
  attributesWrapper: {
    position: 'relative',
    marginBottom: spacing(6),
  },
  attributesLabel: {
    marginBottom: spacing(2),
  },
  notification: {
    color: primary.red1,
    position: 'absolute',
    left: 0,
    bottom: -16,
  },
  filter: {
    maxWidth: 350,
    marginBottom: spacing(6),
  },
  chart: {
    marginTop: spacing(6),
  },
  scoresTitle: {
    margin: spacing(6, 0),
  },
  score: {
    marginBottom: spacing(4),
    'last-of-type': {
      marginBottom: spacing(0),
    },
  },
});

class CompetenceMapPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isScoreLoading: false,
      selectedAttributes: [],
      selectedScores: [],
      period: getLast12Months(),
      hasNotification: false,
      notificationMessage: '',
    };
    this.handleShowMinMaxNotification = asyncDebounce(
      this.handleShowMinMaxNotification,
      NOTIFICATION_DELAY,
      true
    );
  }

  componentDidMount() {
    this.onGetMeasuredAttributes();
  }

  componentWillUnmount() {
    const {
      clearMeasuredAttributes,
      clearAttributesScoreDistribution,
      clearAttributesUsersScores,
    } = this.props;

    clearMeasuredAttributes();
    clearAttributesScoreDistribution();
    clearAttributesUsersScores();
  }

  onGoToProfilePage = userId => () => {
    const { history } = this.props;
    history.push(`/people/${userId}`);
  };

  onGetMeasuredAttributes = () => {
    const {
      getMeasuredAttributes,
      clearAttributesScoreDistribution,
      scoreDistribution,
      clearAttributesUsersScores,
    } = this.props;
    const { period, selectedScores } = this.state;

    const params = {
      [PARAMS.USED_AFTER]: period.start,
      [PARAMS.USED_BEFORE]: period.end,
    };

    return getMeasuredAttributes(params).then(results => {
      const selectedAttributes = getInitiallySelectedAttributes(results);

      if (!isArrayEmpty(selectedAttributes)) {
        return this.onGetAttributesScoreDistribution(selectedAttributes).then(
          () =>
            this.setState(
              {
                isLoading: false,
                selectedAttributes,
                selectedScores: updateSelectedScores(
                  selectedScores,
                  this.props.scoreDistribution
                ),
              },
              this.onGetAttributesUsersScores
            )
        );
      }

      if (!isArrayEmpty(scoreDistribution)) {
        clearAttributesScoreDistribution();
      }

      clearAttributesUsersScores();

      this.setState({
        isLoading: false,
        selectedAttributes,
        selectedScores: [],
      });
    });
  };

  onGetAttributesScoreDistribution = attributes => {
    const { getAttributesScoreDistribution } = this.props;
    const { period } = this.state;

    const params = {
      attribute: attributes.map(attr => attr.id),
      [PARAMS.USED_AFTER]: period.start,
      [PARAMS.USED_BEFORE]: period.end,
    };

    return getAttributesScoreDistribution(params);
  };

  onGetAttributesUsersScores = () => {
    const { getAttributesUsersScores, clearAttributesUsersScores } = this.props;
    const { period, selectedScores, selectedAttributes } = this.state;
    const hasSelectedScores = !isArrayEmpty(selectedScores);

    const params = {
      attribute: selectedAttributes.map(attr => attr.id),
      score: selectedScores,
      [PARAMS.USED_AFTER]: period.start,
      [PARAMS.USED_BEFORE]: period.end,
    };

    if (hasSelectedScores) {
      return getAttributesUsersScores(params).then(() =>
        this.setState({ isScoreLoading: false })
      );
    }

    this.setState({ isScoreLoading: false }, clearAttributesUsersScores);
  };

  handleAttributeSelect = attribute => {
    const { selectedAttributes, selectedScores } = this.state;
    const { translations } = this.props;
    const { MIN, MAX } = COMPETENCE_MAP_ATTRIBUTE_CONFIG;
    const isSelected = isItemInList(selectedAttributes, attribute);
    const attributesCount = selectedAttributes.length;
    let updatedAttributes = [];

    if (isSelected) {
      if (attributesCount > MIN) {
        updatedAttributes = selectedAttributes.filter(
          selectedAttribute => selectedAttribute.id !== attribute.id
        );
        this.setState(
          {
            selectedAttributes: updatedAttributes,
          },
          () =>
            this.onGetAttributesScoreDistribution(updatedAttributes).then(() =>
              this.setState(
                {
                  selectedScores: updateSelectedScores(
                    selectedScores,
                    this.props.scoreDistribution
                  ),
                },
                this.onGetAttributesUsersScores
              )
            )
        );
      } else {
        this.handleShowMinMaxNotification(
          `${translations.minAttribute} ${MIN}`
        );
      }
    } else if (attributesCount < MAX) {
      updatedAttributes = [...selectedAttributes, attribute];
      this.setState({ selectedAttributes: updatedAttributes }, () =>
        this.onGetAttributesScoreDistribution(updatedAttributes).then(() =>
          this.setState(
            {
              selectedScores: updateSelectedScores(
                selectedScores,
                this.props.scoreDistribution
              ),
            },
            this.onGetAttributesUsersScores
          )
        )
      );
    } else {
      this.handleShowMinMaxNotification(`${translations.maxAttribute} ${MAX}`);
    }
  };

  handlePeriodChange = newRange =>
    this.setState({ period: newRange }, this.onGetMeasuredAttributes);

  handleScoreSelect = scoreValue => {
    const { translations } = this.props;
    const { selectedScores } = this.state;
    const isSelected = selectedScores.includes(scoreValue);
    const selectedCount = selectedScores.length;

    if (!isSelected && selectedCount === MAX_SELECTED_SCORES) {
      return this.handleShowMinMaxNotification(
        `${translations.maxSelectedScores} ${MAX_SELECTED_SCORES}`
      );
    }

    this.setState(
      {
        isScoreLoading: true,
        selectedScores: isSelected
          ? selectedScores.filter(score => score !== scoreValue)
          : [...selectedScores, scoreValue],
      },
      this.onGetAttributesUsersScores
    );
  };

  handleShowMinMaxNotification = notificationMessage =>
    this.setState(
      { hasNotification: true, notificationMessage },
      asyncDebounce(
        () =>
          this.setState({ hasNotification: false, notificationMessage: '' }),
        NOTIFICATION_CLEAR_DELAY
      )
    );

  render() {
    const {
      classes,
      translations,
      organizationSettings,
      attributes,
      scoreDistribution,
      usersScores,
      auth,
    } = this.props;
    const {
      isLoading,
      isScoreLoading,
      selectedAttributes,
      period,
      selectedScores,
      hasNotification,
      notificationMessage,
    } = this.state;
    const hasAttributes = !isArrayEmpty(attributes);
    const hasScore = !isArrayEmpty(scoreDistribution);
    const hasSelectedScores = !isArrayEmpty(selectedScores);
    const hasUsersScores = !isArrayEmpty(usersScores);

    return (
      <div>
        <div className={classes.description}>
          <Typography variant="body2">{translations.description}</Typography>
        </div>
        <div className={classes.filter}>
          <CustomDateRange
            label={translations.range}
            initialRange={{
              startDate: period[PARAMS.START],
              endDate: period[PARAMS.END],
            }}
            startAtKey={PARAMS.START}
            endAtKey={PARAMS.END}
            onChange={this.handlePeriodChange}
          />
        </div>
        {!isLoading && (
          <>
            <div className={classes.attributesWrapper}>
              <Typography className={classes.attributesLabel} variant="h5">
                {translations.attributes}
              </Typography>
              {hasAttributes ? (
                <AttributesList
                  attributes={attributes}
                  selectedAttributes={selectedAttributes}
                  onSelect={this.handleAttributeSelect}
                  isHorizontalList
                  isScrollable
                />
              ) : (
                <NotificationCard content={translations.noRecords} />
              )}
              {hasNotification && (
                <Typography className={classes.notification} variant="caption">
                  {notificationMessage}
                </Typography>
              )}
            </div>
            {hasAttributes && hasScore && (
              <CompetenceMapChart
                className={classes.chart}
                yAxisLabel={translations.yAxisLabel}
                data={getChartData(scoreDistribution)}
                selectedScores={selectedScores}
                onScoreSelect={this.handleScoreSelect}
              />
            )}
            <Typography className={classes.scoresTitle} variant="h4">
              {`${translations.selectedScore} ${
                hasSelectedScores ? `(${selectedScores.join(', ')})` : ''
              }`}
            </Typography>
            {!isScoreLoading && !hasSelectedScores && !hasUsersScores && (
              <NotificationCard content={translations.noScores} />
            )}
            {hasSelectedScores && hasUsersScores && (
              <div className={classes.scores}>
                {usersScores.map(
                  score =>
                    !isArrayEmpty(score.users) && (
                      <UserCompetenceScores
                        key={`attributes_score_${score.id}`}
                        className={classes.score}
                        attribute={score}
                        isUserProfileAccessible={isUserProfileAccessible(
                          auth,
                          organizationSettings.global_see_himself
                        )}
                        onGoToProfilePage={this.onGoToProfilePage}
                      />
                    )
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

CompetenceMapPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scoreDistribution: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usersScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getMeasuredAttributes: PropTypes.func.isRequired,
  getAttributesScoreDistribution: PropTypes.func.isRequired,
  getAttributesUsersScores: PropTypes.func.isRequired,
  clearMeasuredAttributes: PropTypes.func.isRequired,
  clearAttributesScoreDistribution: PropTypes.func.isRequired,
  clearAttributesUsersScores: PropTypes.func.isRequired,
};

export default withStyles(styles)(CompetenceMapPage);
