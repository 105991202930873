import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import NotificationCard from '../notificationCard';
import SectionTitle from '../sectionTitle';
import CustomButton from '../customButton';
import SharedUser from './sharedUser';
import EmployeeShareDialog from '../employeeShareDialog';
import AlertDialog from '../alertDialog';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    marginBottom: spacing(5),
  },
  title: {
    marginBottom: spacing(2),
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    maxWidth: 500,
  },
}));

const SurveyReportShare = ({
  className,
  translations,
  users,
  currentUser,
  canManageUsers,
  isUserProfileAccesible,
  onGoToUserProfile,
  onSharedUsersSave,
}) => {
  const classes = useStyles();

  const [isShareUsersOpened, setIsShareUsersOpened] = useState(false);
  const [isUnshareUserOpened, setIsUnshareUserOpened] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const toggleShareDialog = () =>
    setIsShareUsersOpened(prevOpened => !prevOpened);

  const toggleUnshareDialog = () =>
    setIsUnshareUserOpened(prevOpened => !prevOpened);

  const handleUserUnshare = user => {
    toggleUnshareDialog();
    setSelectedUser(user);
  };

  const onSaveUnshared = async () => {
    const sharedUsers = users
      .filter(user => user.id !== selectedUser.id)
      .reduce((acc, cUser) => [...acc, { user: cUser.id }], []);

    try {
      await onSharedUsersSave(sharedUsers, true);
    } finally {
      setSelectedUser(null);
      toggleUnshareDialog();
    }
  };

  return (
    <div className={className}>
      <div className={classes.header}>
        <SectionTitle
          className={classes.title}
          variant="h5"
          title={translations.title}
        />
        <div className={classes.descriptionWrapper}>
          <Typography className={classes.description} variant="body2">
            {translations.description}
          </Typography>
          {canManageUsers && (
            <CustomButton
              type="shareDarkRoundedOutlined"
              onClick={toggleShareDialog}
            >
              {translations.shareUsersButton}
            </CustomButton>
          )}
        </div>
      </div>
      {isArrayEmpty(users) ? (
        <NotificationCard title={translations.noData.title} />
      ) : (
        <TransitionGroup>
          {users.map(user => (
            <Fade key={`shared_user_${user.id}`} appear>
              <div>
                <SharedUser
                  user={user}
                  isUserProfileAccesible={isUserProfileAccesible}
                  onGoToProfile={onGoToUserProfile}
                  onUnshare={handleUserUnshare}
                />
              </div>
            </Fade>
          ))}
        </TransitionGroup>
      )}
      <EmployeeShareDialog
        translations={translations.shareEmployeeDialog}
        isOpened={isShareUsersOpened}
        sharedWith={users}
        currentUser={currentUser}
        onClose={toggleShareDialog}
        onSave={onSharedUsersSave}
        isReportShare
      />
      <AlertDialog
        translations={translations.unshareReport}
        isOpened={isUnshareUserOpened}
        onClose={toggleUnshareDialog}
        onConfirm={onSaveUnshared}
        isWarning
      />
    </div>
  );
};

SurveyReportShare.defaultProps = {
  className: undefined,
  users: [],
  canManageUsers: false,
};

SurveyReportShare.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object.isRequired,
  canManageUsers: PropTypes.bool,
  isUserProfileAccesible: PropTypes.func.isRequired,
  onGoToUserProfile: PropTypes.func.isRequired,
  onSharedUsersSave: PropTypes.func.isRequired,
};

export default memo(SurveyReportShare);
