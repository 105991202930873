import { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import UserAvatar from '../../userAvatar';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(4, 0),
    width: '100%',
  },
  userAvatar: {
    width: 'fit-content',
    maxWidth: 'calc(100% - 32px)',
  },
  unshareButton: {
    marginRight: spacing(2),
  },
}));

const SharedUser = ({
  user,
  isUserProfileAccesible,
  onUnshare,
  onGoToProfile,
}) => {
  const classes = useStyles();

  const isClickable = useMemo(
    () => isUserProfileAccesible(user.id),
    [user, isUserProfileAccesible]
  );

  const handleGoToUserProfile = useCallback(
    () => onGoToProfile(user.id),
    [user, onGoToProfile]
  );

  const handleUnshare = useCallback(() => onUnshare(user), [user, onUnshare]);

  return (
    <div className={classes.root}>
      <UserAvatar
        className={classes.userAvatar}
        variant="subtitle2"
        user={user}
        clickableCaption={isClickable}
        onClickHandler={handleGoToUserProfile}
        small
        caption
      />
      <ActionButton
        type={ACTION_BUTTON_TYPES.DELETE}
        onClickHandler={handleUnshare}
      />
    </div>
  );
};

SharedUser.propTypes = {
  user: PropTypes.object.isRequired,
  isUserProfileAccesible: PropTypes.func.isRequired,
  onGoToProfile: PropTypes.func.isRequired,
  onUnshare: PropTypes.func.isRequired,
};

export default memo(SharedUser);
