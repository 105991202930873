import ColorBox from 'components/shared/colorBox';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import { ReactComponent as TrackIcon } from '../../../assets/icons/track-icon.svg';
import { ReactComponent as TrackLevelIcon } from '../../../assets/icons/track-level-icon.svg';
import { ReactComponent as EmployeeStatusIcon } from '../../../assets/icons/employee-status.svg';
import { ReactComponent as ActionPlansIcon } from '../../../assets/icons/assignment_dark.svg';
import { ReactComponent as JobTitleIcon } from '../../../assets/icons/job-title-icon.svg';
import { ReactComponent as ReportToIcon } from '../../../assets/icons/reviewer-icon.svg';
import { ReactComponent as RoleIcon } from '../../../assets/icons/role.svg';
import {
  isObject,
  checkUserRole,
  isArrayEmpty,
  isArraySubset,
  isObjectEmpty,
  trimString,
} from '../../../utility/helpers';
import {
  getPersonFullName,
  isEmployeeInReporters,
} from '../../../utility/uiUtils';
import {
  validateField,
  validateFields,
  isEmpty,
} from '../../../utility/validation';
import { filterTags } from '../../../utility/tagUtils';
import {
  ACCOUNT_TYPES,
  PERMISSIONS,
  ROLES,
} from '../../../constants/rolesAndPermissionList';
import { UserStatuses } from '../../../constants/statuses';
import { BULK_ACTION_OPTIONS } from '../../../constants/bulkActionOptions';
import {
  ACTION_PLANS_FILTER,
  REPORT_TO_FILTER,
} from '../../../constants/filters';
import { PARAMS } from '../../../constants/pages';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { RESET_PASSWORD_URL } from '../../../constants/appConfig';
import {
  USER_INFO,
  USER_FIELDS,
  ADD_USER_OPTION_KEYS,
  ADD_USER_OPTIONS,
  USER_VALIDATIONS,
} from '../../../constants/people';

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  REPORT_TO,
  JOB_TITLE,
  ROLE,
  TRACK,
  TRACK_LEVEL,
  STATUS,
  EMPLOYMENT_DATE,
} = USER_INFO;
const { MANUAL, ASSOCIATE, IMPORT } = ADD_USER_OPTION_KEYS;
const [IDLE, , , SUSPENDED] = UserStatuses;
const { ADD_USERS_TABLE, SUBTITLE } = FIELD_TYPES;

const CSV_FILE_USER_FIRST_ROW = 6;

const getStatusFilters = labels =>
  UserStatuses.map(status => {
    return {
      ...status,
      name: labels[status.id],
    };
  });

const getReportToFilters = labels => {
  return REPORT_TO_FILTER.map(report => {
    return {
      ...report,
      name: labels[report.translationKey],
    };
  });
};

const getActionPlansFilters = labels => {
  return ACTION_PLANS_FILTER.map(actionPlanFilter => {
    return {
      ...actionPlanFilter,
      name: labels[actionPlanFilter.translationKey],
    };
  });
};

const getTrackLevelFilters = tracks =>
  tracks.reduce((acc, track) => {
    const trackLevels = track.track_levels.filter(trLvl => isObject(trLvl));

    return [...acc, ...trackLevels];
  }, []);

export const getPageFilters = (
  translations,
  jobTitles,
  tracks,
  tagCategories,
  userRoles,
  isUser,
  currentUser,
  hasActionPlansFilter = false,
  quickFilters = []
) => {
  const {
    ROLE: ROLE_FILTER,
    STATUS: STATUS_FILTER,
    SCOPE,
    JOB_TITLE: JOB_TITLE_FILTER,
    TAGS,
    TRACK: TRACK_FILTER,
    TRACK_LEVEL: TRACK_LEVEL_FILTER,
    WITHOUT_ACTION_PLANS,
  } = PARAMS;

  return [
    {
      id: ROLE_FILTER,
      name: translations.filters.labels[ROLE_FILTER],
      icon: RoleIcon,
      items: userRoles,
    },
    {
      id: STATUS_FILTER,
      name: translations.filters.labels[STATUS_FILTER],
      icon: EmployeeStatusIcon,
      items: getStatusFilters(translations.statuses),
    },
    ...(!isUser && !isObjectEmpty(currentUser.reporters)
      ? [
          {
            id: SCOPE,
            name: translations.filters.labels[SCOPE],
            icon: ReportToIcon,
            isSingleSelect: true,
            items: getReportToFilters(translations.reportToFilter),
          },
        ]
      : []),
    {
      id: JOB_TITLE_FILTER,
      name: translations.filters.labels[JOB_TITLE_FILTER],
      icon: JobTitleIcon,
      items: jobTitles,
    },
    {
      id: TRACK_FILTER,
      name: translations.filters.labels.track,
      icon: TrackIcon,
      items: tracks,
    },
    {
      id: TRACK_LEVEL_FILTER,
      name: translations.filters.labels.trackLevel,
      icon: TrackLevelIcon,
      items: getTrackLevelFilters(tracks),
    },
    ...(hasActionPlansFilter
      ? [
          {
            id: WITHOUT_ACTION_PLANS,
            name: translations.filters.labels.actionPlans,
            icon: ActionPlansIcon,
            isSingleSelect: true,
            items: getActionPlansFilters(translations.actionPlansFilters),
          },
        ]
      : []),
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getPreHeaderActions = (checkbox, permissions) => {
  return [
    ...(permissions.includes(PERMISSIONS.canUseBulkEdit)
      ? [
          {
            id: 'action-select-all-users',
            title: checkbox,
            rowKey: 'selectUser',
            isHeaderAction: true,
          },
        ]
      : []),
  ];
};

export const getHeaderActions = (labels, isUser) =>
  isUser
    ? []
    : [
        {
          id: 'actions-column',
          title: labels.actions,
          rowKey: 'actions',
          align: 'center',
          isHeaderAction: true,
          rowCellClass: 'actions-dots-menu',
          minWidth: 60,
          maxWidth: 80,
        },
      ];

export const getTableHeaders = (labels, isUser) => [
  {
    id: 1,
    title: labels.name,
    rowKey: 'name',
    sortAs: `${FIRST_NAME},id`,
    minWidth: 160,
    maxWidth: '1.5fr',
    headerCellClass: 'name-header-cell',
  },
  {
    id: 2,
    title: labels.email,
    rowKey: 'email',
    sortAs: 'email,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 3,
    title: labels.jobTitle,
    rowKey: 'position',
    sortAs: 'position__name,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 4,
    title: labels.levelAndTrack,
    rowKey: 'levelAndTrack',
    sortAs: 'track_level__name,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 5,
    title: labels.role,
    rowKey: 'role',
    sortAs: 'role,id',
    maxWidth: '1fr',
  },
  ...(!isUser
    ? [
        {
          id: 6,
          title: labels.status,
          rowKey: 'status',
          sortAs: 'status,id',
          minWidth: 115,
          maxWidth: '1fr',
        },
      ]
    : []),
];

export const prepareTableData = (
  isAdmin,
  auth,
  grantedPermissions,
  users,
  renderUser,
  renderSelectUser,
  renderDotsMenu,
  translations,
  seeHimself
) => {
  return users.map(user => {
    const isCurrentUser = user.id === auth.id;
    const canSeeUserProfile =
      (isCurrentUser && seeHimself) ||
      isEmployeeInReporters(user.id, auth.accessibleProfiles) ||
      (isAdmin && user[ROLE] !== ROLES.ASSOCIATE);

    const canUseBulkEdit = grantedPermissions.includes(
      PERMISSIONS.canUseBulkEdit
    );

    return {
      ...(canUseBulkEdit ? { selectUser: renderSelectUser(user) } : {}),
      name: renderUser(user, canSeeUserProfile),
      email: user[EMAIL],
      position: user?.[JOB_TITLE]?.name,
      levelAndTrack:
        user[TRACK] && user[TRACK_LEVEL]
          ? `${user.track_level.name} - ${user[TRACK].name}`
          : '',
      role: translations.role[user[ROLE].toLowerCase()],
      id: user.id,
      status:
        user[ROLE] === ROLES.ASSOCIATE
          ? null
          : translations.statuses[user[STATUS]],
      rowLink: canSeeUserProfile && `/people/${user.id}`,
      actions: renderDotsMenu(user),
    };
  });
};

export const getVisibleEmployees = (employees, currentUser) => {
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  return employees.filter(employee =>
    isAdmin
      ? employee.id !== currentUser.id &&
        employee[STATUS] !== SUSPENDED.id &&
        employee[ROLE] !== ROLES.ASSOCIATE
      : isEmployeeInReporters(employee.id, currentUser.reporters)
  );
};

export const areAllUsersChecked = (checkedUsers, employees, currentUser) => {
  const visibleEmployees = getVisibleEmployees(employees, currentUser);

  return (
    !isArrayEmpty(checkedUsers) &&
    !isArrayEmpty(visibleEmployees) &&
    isArraySubset(checkedUsers, visibleEmployees)
  );
};

export const isCheckboxDisabled = (users, currentUser, isAdmin, user = {}) => {
  const employees = isAdmin
    ? users.reduce((acc, currentEmployee) => {
        if (
          currentEmployee.id !== currentUser.id &&
          currentEmployee[STATUS] !== SUSPENDED.id &&
          currentEmployee[ROLE] !== ROLES.ASSOCIATE
        ) {
          return { ...acc, [currentEmployee.id]: true };
        }
        return acc;
      }, {})
    : {};

  const whitelistEmployees = isAdmin ? employees : currentUser.reporters;

  return !isObjectEmpty(user)
    ? !isEmployeeInReporters(user.id, whitelistEmployees)
    : !users.some(employee =>
        isEmployeeInReporters(employee.id, whitelistEmployees)
      );
};

export const isMenuDisabled = (userId, currentUser, isAdmin) => {
  if (isAdmin) return false;

  return (
    currentUser.id === userId ||
    !isEmployeeInReporters(userId, currentUser.reporters)
  );
};

export const getBulkActionOptions = labels => {
  return BULK_ACTION_OPTIONS.map(option => {
    return {
      value: option.value,
      label: labels[option.key],
    };
  });
};

export const getCheckboxTooltip = (labels, user, currentUser) => {
  if (user.id === currentUser.id) {
    return labels.currentUserTooltip;
  } else if (user[STATUS] === SUSPENDED.id) {
    return labels.inactiveUserTooltip;
  } else if (user[ROLE] === ROLES.ASSOCIATE) {
    return labels.externalUser;
  }

  return labels.userIsNotReporter;
};

export const getDisabledMenuTooltip = (labels, user, currentUser) => {
  if (user.id === currentUser.id) {
    return labels.currentUserActionTooltip;
  }

  return labels.userIsNotReporterAction;
};

export const isCheckboxChecked = (userId, checkedUsers) => {
  return checkedUsers.some(checked => checked.id === userId);
};

export const getUserRoles = (
  labels,
  isAdmin,
  shouldIncludeExternal,
  selectedUser = {}
) => {
  const isSelectedUserAdmin = checkUserRole(selectedUser?.[ROLE], ROLES.ADMIN);
  const isSelectedUserAssociate = checkUserRole(
    selectedUser?.[ROLE],
    ROLES.ASSOCIATE
  );
  const roles = ACCOUNT_TYPES.map(atu => {
    return {
      ...atu,
      id: atu.value,
      name: labels.role[atu.label],
      label: labels.role[atu.label],
      description: labels.roleDescriptions[atu.label],
    };
  });

  if (isSelectedUserAssociate) return roles;

  if (isAdmin || isSelectedUserAdmin) {
    if (shouldIncludeExternal) return roles;

    return roles.filter(role => role.value !== ROLES.ASSOCIATE);
  }

  return roles.filter(
    role => role.value !== ROLES.ADMIN && role.value !== ROLES.ASSOCIATE
  );
};

export const getInitialUserData = (user, reportTo = null) => {
  return {
    [FIRST_NAME]: user[FIRST_NAME],
    [LAST_NAME]: user[LAST_NAME],
    [EMAIL]: user[EMAIL],
    [ROLE]: user[ROLE],
    [STATUS]: user[STATUS],
    [JOB_TITLE]: user[JOB_TITLE]?.id,
    [EMPLOYMENT_DATE]: user[EMPLOYMENT_DATE],
    [TRACK]: user[TRACK]?.id || user[TRACK],
    [TRACK_LEVEL]: user[TRACK_LEVEL]?.id || user[TRACK_LEVEL],
    [REPORT_TO]: reportTo?.id || user[REPORT_TO]?.id,
  };
};

export const getEditUserFields = (user, currentUser, isReportToDisabled) => {
  const isOwnProfile = currentUser.id === user.id;
  const isUserSuspended = user[STATUS] === SUSPENDED.id;
  const isAssociate = checkUserRole(user[ROLE], ROLES.ASSOCIATE);

  return [
    ...(!isAssociate
      ? [
          {
            name: 'personalInformation',
            type: SUBTITLE,
          },
        ]
      : []),
    {
      ...USER_FIELDS.FIRST_NAME,
      isDisabled: isUserSuspended,
    },
    {
      ...USER_FIELDS.LAST_NAME,
      isDisabled: isUserSuspended,
    },
    {
      ...USER_FIELDS.EMAIL,
      isDisabled: isUserSuspended,
    },
    ...(!isAssociate
      ? [
          {
            name: 'employmentInformation',
            type: SUBTITLE,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.EMPLOYMENT_DATE,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.JOB_TITLE,
            isDisabled: isUserSuspended,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.TRACK,
            isDisabled: isUserSuspended,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.TRACK_LEVEL,
            isDisabled: isUserSuspended,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.STATUS,
            isDisabled: isOwnProfile,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.REPORT,
            isDisabled: isReportToDisabled,
            validators: [
              {
                validator: value => isReportToDisabled || isEmpty(value),
                type: 'required',
              },
            ],
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            name: 'permissions',
            type: SUBTITLE,
          },
        ]
      : []),
    {
      ...USER_FIELDS.ROLE,
      isDisabled: isUserSuspended || isOwnProfile,
      ...(isAssociate
        ? {
            dependants: [
              {
                name: REPORT_TO,
                resetFnc: (prevValue, currentValue) =>
                  currentValue !== ROLES.ASSOCIATE ? null : undefined,
              },
            ],
          }
        : {}),
    },
    ...(isAssociate
      ? [
          {
            ...USER_FIELDS.REPORT,
            isDisabled: isReportToDisabled,
            isDisabledCheck: values => values[ROLE] === ROLES.ASSOCIATE,
            validators: [
              {
                validator: value => isReportToDisabled || isEmpty(value),
                type: 'required',
              },
            ],
          },
        ]
      : []),
  ];
};

export const getAddUsersInfo = (labels, selectedOption) => {
  if (selectedOption === MANUAL) {
    return labels.manual;
  }
  if (selectedOption === IMPORT) {
    return labels.import;
  }

  return labels.associate;
};

const getNewUser = (currentUser, isAdmin, selectedOption) => ({
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [EMAIL]: '',
  [ROLE]: selectedOption === ASSOCIATE ? ROLES.ASSOCIATE : ROLES.USER,
  ...(selectedOption === MANUAL
    ? {
        [REPORT_TO]: isAdmin ? [] : [currentUser],
        [STATUS]: IDLE.id,
        activation_url: RESET_PASSWORD_URL,
        client_domain: window.document.location.origin,
      }
    : {}),
});

export const usersValidator = async (
  users,
  columns,
  initialUsers = [],
  rowIndex = -1
) => {
  const errors = {};

  for (let i = 0; i < users.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const rowErrors = await validateFields(
      columns,
      users[i],
      initialUsers[i],
      rowIndex !== -1
    );

    if (!rowErrors[EMAIL]) {
      const isEmailDuplicate = users.some(
        (u, index) =>
          index !== i &&
          trimString(u[EMAIL]) &&
          trimString(u[EMAIL]) === users[i][EMAIL]
      );

      if (isEmailDuplicate) {
        rowErrors[EMAIL] = 'duplicatedEmail';
      }
    }

    if (!isObjectEmpty(rowErrors)) {
      errors[i] = rowErrors;
    }
  }

  return !isObjectEmpty(errors) ? errors : null;
};

export const getAddUsersFields = (
  descriptionLabels,
  currentUser,
  organizationMentor,
  selectedOption,
  isAdmin,
  userLimit,
  roles,
  reportToOptions,
  importedUsers,
  isUserImport,
  onChangeReportTo,
  onOpenUpgradeDialog,
  onMentorChange
) => {
  let columns = [];
  const reportToInitialValue =
    selectedOption === IMPORT ? getPersonFullName(organizationMentor) : '';

  if (selectedOption === ASSOCIATE) {
    columns = [
      { ...USER_FIELDS.FIRST_NAME },
      { ...USER_FIELDS.LAST_NAME },
      { ...USER_FIELDS.EMAIL },
      { ...USER_FIELDS.ROLE, isDisabled: true },
    ];
  } else {
    columns = [
      { ...USER_FIELDS.FIRST_NAME },
      { ...USER_FIELDS.LAST_NAME },
      { ...USER_FIELDS.EMAIL },
      {
        ...USER_FIELDS.REPORT_TO,
        isDisabled: !isAdmin,
        initialValue: !isAdmin
          ? getPersonFullName(currentUser)
          : reportToInitialValue,
      },
      { ...USER_FIELDS.ROLE },
      { ...USER_FIELDS.INVITED, ...(isUserImport ? { key: 'invite' } : {}) },
    ];
  }

  return [
    {
      name: 'users',
      type: ADD_USERS_TABLE,
      description: getAddUsersInfo(descriptionLabels, selectedOption),
      currentUser,
      isAdmin,
      userAddType: selectedOption,
      columns,
      roles,
      reportToOptions,
      hasInvite: selectedOption !== ASSOCIATE,
      hasAddButton: selectedOption !== IMPORT,
      hasDelete: selectedOption !== IMPORT,
      organizationUserKey: 'organizationUser',
      userLimit,
      getNewUser,
      onChangeReportTo,
      onOpenUpgradeDialog,
      onMentorChange,
      usersValidator,
      validators: [
        {
          validator: users => usersValidator(users, columns, importedUsers, -1),
          isMultiple: true,
        },
      ],
    },
  ];
};

export const getAddUsersInitialValues = (
  currentUser,
  isAdmin,
  selectedOption,
  organizationMentor,
  importedUsers = []
) => {
  return {
    users: [
      ...(isArrayEmpty(importedUsers)
        ? [getNewUser(currentUser, isAdmin, selectedOption)]
        : []),
      ...importedUsers.map(user => ({
        ...user,
        [REPORT_TO]: [organizationMentor],
      })),
    ],
  };
};

export const prepareUsersForSave = (users, initialUsers = []) => {
  if (!isArrayEmpty(initialUsers)) {
    return users.reduce((acc, user, index) => {
      const preparedUser = {
        id: user.id,
        [FIRST_NAME]: user[FIRST_NAME],
        [LAST_NAME]: user[LAST_NAME],
        [REPORT_TO]: user[REPORT_TO][0].id,
        [STATUS]: user[STATUS],
        [ROLE]: user[ROLE],
      };

      if (initialUsers[index][EMAIL] !== trimString(user[EMAIL])) {
        preparedUser[EMAIL] = user[EMAIL];
      }

      return [...acc, preparedUser];
    }, []);
  }

  return users.map(user => ({
    ...user,
    ...(user[REPORT_TO] ? { [REPORT_TO]: user[REPORT_TO][0].id } : {}),
  }));
};

export const getAddUserOptions = (labels, hasSubscription) =>
  ADD_USER_OPTIONS.map(option => ({
    ...option,
    name: labels[option.key].title,
    description: labels[option.key].description,
    disabledMessage: labels[option.key].disabledMessage,
    isDisabled: hasSubscription ? false : option.id === ASSOCIATE,
  }));

export const getValidImportedUsers = async (users, organizationUser) => {
  const uniqueEmails = new Set();
  const validUsers = [];
  const rowsWithErrors = new Set();

  for (let i = 0; i < users.length; i += 1) {
    const { firstName, lastName, email } = users[i];
    const sanitizedFirstName = trimString(firstName);
    const sanitizedLastName = trimString(lastName);
    const sanitizedEmail = trimString(email);
    if (sanitizedFirstName || sanitizedLastName || sanitizedEmail) {
      // eslint-disable-next-line no-await-in-loop
      const firstNameErrors = await validateField(
        USER_FIELDS.FIRST_NAME,
        sanitizedFirstName
      );
      // eslint-disable-next-line no-await-in-loop
      const lastNameErrors = await validateField(
        USER_FIELDS.LAST_NAME,
        sanitizedLastName
      );
      // eslint-disable-next-line no-await-in-loop
      const emailErrors = await validateField(
        {
          ...USER_FIELDS.EMAIL,
          validators: USER_VALIDATIONS[EMAIL].slice(
            0,
            USER_VALIDATIONS[EMAIL].length - 1
          ),
        },
        sanitizedEmail
      );

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && firstNameErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && lastNameErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && emailErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!firstNameErrors && !lastNameErrors && !emailErrors) {
        if (!uniqueEmails.has(sanitizedEmail)) {
          uniqueEmails.add(sanitizedEmail);
          validUsers.push({
            [FIRST_NAME]: sanitizedFirstName,
            [LAST_NAME]: sanitizedLastName,
            [EMAIL]: sanitizedEmail,
            [ROLE]: ROLES.USER,
            [REPORT_TO]: [organizationUser],
            [STATUS]: IDLE.id,
            activation_url: RESET_PASSWORD_URL,
            client_domain: window.document.location.origin,
          });
        }
      }
    }
  }

  return {
    rowsWithErrors: [...rowsWithErrors],
    validEmails: [...uniqueEmails],
    validUsers,
  };
};
