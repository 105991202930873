import { useState, useCallback, useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import CompareFilters from './compareFilters';
import CompareScores from './compareScores';
import AssignUsersDialog from '../assignUsersDialog';
import { getGroupedSurveyOptions } from '../../../utility/reportUtils';
import {
  getObjectToNumberArray,
  isArrayEmpty,
  removeObjectFromArray,
} from '../../../utility/helpers';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import { getLast12Months } from '../customDateRange/config';
import {
  GROUPED_SURVEY_REPORTING_TYPES,
  GROUPED_SURVEY_TYPES_KEYS,
} from '../../../constants/survey';
import { PARAMS } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  contentRoot: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr)',
    padding: spacing(4),
    width: '50%',
  },
  contentRootFullWidth: {
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gridColumnGap: 32,
    width: '100%',
  },
  createdForWrapper: {
    position: 'relative',
    padding: spacing(4),
  },
  createdForWrapperDivider: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: '100%',
      top: 0,
      right: -1,
      borderRight: `1px dotted ${primary.bluish7}`,
    },
  },
  compareUserWrapper: {
    padding: spacing(4),
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    maxWidth: 'calc(100% - 34px)',
  },
  userWrapper: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
    color: primary.blue1,
  },
  userCaption: {
    maxWidth: 'calc(100% - 56px)',
  },
  field: {
    marginTop: spacing(6),
    width: '100%',
    maxWidth: 300,
  },
  peoplePicker: {
    width: '100%',
    maxWidth: '100%',
  },
  selectedReviewerWrapper: {
    maxWidth: 380,
  },
  topSpacing: {
    marginTop: spacing(4),
  },
  syncFiltersButton: {
    position: 'absolute',
    right: 16,
    top: 25,
  },
  compareWithButtonWrapper: {
    marginTop: spacing(6),
  },
  noAttributes: {
    marginTop: spacing(6),
  },
}));

const UserCompare = ({
  translations,
  createdForUser,
  allUsers,
  allEmployees,
  initialReviewers,
  initialSurveyType,
  userFilters,
  onGetAttributeScores,
  onGetReviewers,
  onGoToProfilePage,
}) => {
  const [PeerTeamAndDirect] = GROUPED_SURVEY_REPORTING_TYPES;
  const surveyTypes = getGroupedSurveyOptions(translations.surveyOptions);

  const classes = useStyles();

  const [isCreatedForAttributesLoading, setIsCreatedForAttributesLoading] =
    useState(!isArrayEmpty(initialReviewers));
  const [createdForAttributes, setCreatedForAttributes] = useState([]);
  const [compareWithAttributes, setCompareWithAttributes] = useState([]);
  const [createdForSurveyType, setCreatedForSurveyType] = useState(
    initialSurveyType || PeerTeamAndDirect
  );
  const [createdForPeriod, setCreatedForPeriod] = useState(getLast12Months());
  const [createdForReviewers, setCreatedForReviewers] =
    useState(initialReviewers);
  const [compareWithSurveyType, setCompareWithSurveyType] =
    useState(PeerTeamAndDirect);
  const [compareWithPeriod, setCompareWithPeriod] = useState(getLast12Months());
  const [compareWithReviewers, setCompareWithReviewers] = useState([]);
  const [compareWithUser, setCompareWithUser] = useState(null);
  const [isAddCompareOpened, setIsAddCompareOpened] = useState(false);
  const [hasCreatedForRestore, setCreatedForRestore] = useState(false);
  const [hasCompareWithRestore, setCompareWithRestore] = useState(false);

  const isCreatedForSelf =
    createdForSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
  const isCompareWithSelf =
    compareWithSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
  const createdForParams = useMemo(() => {
    const { SURVEY_TYPE, START, END, START_AT, END_AT, SUBJECT } = PARAMS;
    const reviewer = getObjectToNumberArray(createdForReviewers);

    return {
      [SURVEY_TYPE]: createdForSurveyType?.values,
      [START_AT]: getStartEndOfDayUtc(createdForPeriod[START]),
      [END_AT]: getStartEndOfDayUtc(createdForPeriod[END], true),
      [SUBJECT]: createdForUser?.id,
      ...(isCreatedForSelf ? {} : { reviewer }),
    };
  }, [
    createdForUser,
    createdForReviewers,
    createdForSurveyType,
    createdForPeriod,
    isCreatedForSelf,
  ]);

  const compareWithParams = useMemo(() => {
    const { SURVEY_TYPE, START, END, START_AT, END_AT, SUBJECT } = PARAMS;
    const reviewer = getObjectToNumberArray(compareWithReviewers);

    return {
      [SURVEY_TYPE]: compareWithSurveyType?.values,
      [START_AT]: getStartEndOfDayUtc(compareWithPeriod[START]),
      [END_AT]: getStartEndOfDayUtc(compareWithPeriod[END], true),
      [SUBJECT]: compareWithUser?.id,
      ...(isCompareWithSelf ? {} : { reviewer }),
    };
  }, [
    compareWithUser,
    compareWithReviewers,
    compareWithSurveyType,
    compareWithPeriod,
    isCompareWithSelf,
  ]);

  const handleCreatedForSurveyTypeChange = selectedSurveyType => {
    setCreatedForSurveyType(selectedSurveyType);
  };

  const handleCreatedForPeriodChange = newRange =>
    setCreatedForPeriod(newRange);

  const handleCreatedForReviewersChange = useCallback(
    selectedUsers => setCreatedForReviewers(selectedUsers),
    []
  );

  const handleCompareWithSurveyTypeChange = selectedSurveyType => {
    setCompareWithSurveyType(selectedSurveyType);
  };

  const handleCompareWithPeriodChange = newRange =>
    setCompareWithPeriod(newRange);

  const handleCompareWithReviewersChange = useCallback(
    selectedUsers => setCompareWithReviewers(selectedUsers),
    []
  );

  const toggleAddCompareDialog = () =>
    setIsAddCompareOpened(prevOpened => !prevOpened);

  const handleSelectCompareWith = selectedUser => {
    setCompareWithUser(selectedUser);
    toggleAddCompareDialog();
  };

  const handleSyncFilters = () => {
    setCompareWithSurveyType(createdForSurveyType);
    setCompareWithPeriod(createdForPeriod);
    setCompareWithReviewers(createdForReviewers);
  };

  const handleRemoveCompareWithUser = () => {
    setCompareWithUser(null);
    setCompareWithSurveyType(PeerTeamAndDirect);
    setCompareWithPeriod(getLast12Months());
    setCompareWithReviewers([]);
    setCompareWithAttributes([]);
  };

  const handleRemoveAttribute = useCallback(
    (isCreatedFor = false) =>
      attribute => {
        if (isCreatedFor) {
          setCreatedForRestore(true);
          return setCreatedForAttributes(
            removeObjectFromArray(createdForAttributes, attribute)
          );
        }

        setCompareWithRestore(true);
        return setCompareWithAttributes(
          removeObjectFromArray(compareWithAttributes, attribute)
        );
      },
    [compareWithAttributes, createdForAttributes]
  );

  const handleGetCreatedForAttributes = useCallback(async () => {
    const attributes = await onGetAttributeScores(
      createdForParams,
      isCreatedForSelf
    );

    setCreatedForAttributes(attributes);
    setCreatedForRestore(false);
    setIsCreatedForAttributesLoading(false);
  }, [createdForParams, isCreatedForSelf, onGetAttributeScores]);

  const handleGetCompareWithAttributes = useCallback(async () => {
    const attributes = await onGetAttributeScores(
      compareWithParams,
      isCompareWithSelf
    );

    setCompareWithAttributes(attributes);
    setCompareWithRestore(false);
  }, [compareWithParams, isCompareWithSelf, onGetAttributeScores]);

  const handleCreatedForRestore = () => handleGetCreatedForAttributes();

  const handleCompareWithRestore = () => handleGetCompareWithAttributes();

  const handleReorderCreatedForAttributes = attributes =>
    setCreatedForAttributes(attributes);

  const handleReorderCompareWithAttributes = attributes =>
    setCompareWithAttributes(attributes);

  useEffect(() => {
    handleGetCreatedForAttributes();
  }, [handleGetCreatedForAttributes]);

  useEffect(() => {
    handleGetCompareWithAttributes();
  }, [handleGetCompareWithAttributes]);

  return (
    <div>
      <div
        className={classNames(classes.contentRoot, {
          [classes.contentRootFullWidth]: !!compareWithUser,
        })}
      >
        <CompareFilters
          translations={translations}
          user={createdForUser}
          reviewersFilters={userFilters}
          surveyTypes={surveyTypes}
          allUsers={allUsers}
          surveyType={createdForSurveyType}
          period={createdForPeriod}
          reviewers={createdForReviewers}
          compareWithUser={compareWithUser}
          isSelfSurvey={isCreatedForSelf}
          onOpenAddCompare={toggleAddCompareDialog}
          onSurveyTypeChange={handleCreatedForSurveyTypeChange}
          onPeriodChange={handleCreatedForPeriodChange}
          onChangeReviewers={handleCreatedForReviewersChange}
          onGoToProfilePage={onGoToProfilePage}
          onGetReviewers={onGetReviewers}
          hasCompareWith
        />
        {compareWithUser && (
          <Fade in={!!compareWithUser} unmountOnExit>
            <div>
              <CompareFilters
                translations={translations}
                user={compareWithUser}
                reviewersFilters={userFilters}
                surveyTypes={surveyTypes}
                allUsers={allUsers}
                surveyType={compareWithSurveyType}
                period={compareWithPeriod}
                reviewers={compareWithReviewers}
                attributes={compareWithAttributes}
                isSelfSurvey={isCompareWithSelf}
                onRemoveUser={handleRemoveCompareWithUser}
                onSurveyTypeChange={handleCompareWithSurveyTypeChange}
                onPeriodChange={handleCompareWithPeriodChange}
                onChangeReviewers={handleCompareWithReviewersChange}
                onSyncFilters={handleSyncFilters}
                onGoToProfilePage={onGoToProfilePage}
                onGetReviewers={onGetReviewers}
              />
            </div>
          </Fade>
        )}
        {!isCreatedForAttributesLoading && (
          <CompareScores
            translations={translations}
            attributes={createdForAttributes}
            hasDivider={!!compareWithUser}
            hasRestore={hasCreatedForRestore}
            onRemoveAttribute={handleRemoveAttribute(true)}
            onRestore={handleCreatedForRestore}
            onReorder={handleReorderCreatedForAttributes}
            isRemovable
            isDraggable
          />
        )}
        {compareWithUser && (
          <Fade in={!!compareWithUser} unmountOnExit>
            <div>
              <CompareScores
                translations={translations}
                attributes={compareWithAttributes}
                hasRestore={hasCompareWithRestore}
                onRemoveAttribute={handleRemoveAttribute()}
                onRestore={handleCompareWithRestore}
                onReorder={handleReorderCompareWithAttributes}
                isRemovable
                isDraggable
              />
            </div>
          </Fade>
        )}
      </div>
      <AssignUsersDialog
        translations={translations.addCompareWithDialog}
        isOpened={isAddCompareOpened}
        initialValue={compareWithUser}
        allUsers={allEmployees}
        onCancel={toggleAddCompareDialog}
        onSave={handleSelectCompareWith}
        shouldReturnOption
      />
    </div>
  );
};

UserCompare.defaultProps = {
  initialReviewers: [],
  initialSurveyType: null,
};

UserCompare.propTypes = {
  translations: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  allEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialReviewers: PropTypes.arrayOf(PropTypes.object),
  initialSurveyType: PropTypes.object,
  createdForUser: PropTypes.object.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGetAttributeScores: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
  onGetReviewers: PropTypes.func.isRequired,
};

export default memo(UserCompare);
