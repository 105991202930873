import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import fields from './fields';
import BackLink from '../backLink';
import AuthFormLayout from '../authFormLayout';
import { AUTOMATION_ID } from '../../../constants/automationId';

class LoginFormLayout extends PureComponent {
  render() {
    const { translations, history } = this.props;

    return (
      <AuthFormLayout {...this.props} fields={fields} loginForm>
        <BackLink
          id={AUTOMATION_ID.FORGOTTEN_PASSWORD_BUTTON}
          title={translations.backLink}
          history={history}
          to="/forgotten-password"
        />
      </AuthFormLayout>
    );
  }
}

LoginFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default LoginFormLayout;
