import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import CustomButton from '../customButton';
import LoginBackground from '../../../assets/images/login-background.png';
import { ReactComponent as Logo } from '../../../assets/icons/logo-dark.svg';
import { ReactComponent as Illustration } from '../../../assets/icons/illustration.svg';
import { API_DOMAIN, API_PROTOCOL } from '../../../utility/http';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionLeft: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingRight: 0,
    background: `${primary.white} url(${LoginBackground})  center / cover no-repeat`,
  },
  logo: {
    margin: spacing(10, 0, 0, 10),
    textDecoration: 'none',
    alignItems: 'center',
  },
  logoIcon: {
    width: 48,
    height: 48,
  },
  company: {
    marginLeft: spacing(3),
    fontSize: 30,
    lineHeight: '32px',
  },
  illustrationWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  mobileExplanation: {
    position: 'absolute',
    top: '14%',
    [breakpoints.up('sm')]: {
      visibility: 'hidden',
    },
  },
  illustration: {
    width: '66%',
    paddingBottom: spacing(22),
  },
  sectionRight: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    paddingTop: '19vh',
  },
  signUpFormContainer: {
    paddingTop: spacing(6),
  },
  signUpLink: {
    textDecoration: 'none',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginTop: spacing(9),
    marginRight: spacing(10),
  },
  removeTopButton: {
    minHeight: '60px',
  },
  signUpButton: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
  signUpText: {
    marginRight: spacing(6),
  },
  changeOrganizationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(6),
    background: 'rgba(207, 216, 220, 0.5)',
    borderRadius: '4px',
    minWidth: 392,
    height: 56,
  },
  changeOrganizationLink: {
    textDecoration: 'none',
  },
  notIn: {
    color: primary.bluish2,
    marginRight: spacing(2),
  },
  changeOrganizationText: {
    color: primary.blue1,
  },
  privacyLinksContainer: {
    position: 'absolute',
    left: 37,
    bottom: 32,
  },
  separator: {
    width: 80,
    height: 6,
    background: 'rgba(120, 144, 156, 0.3)',
    marginBottom: spacing(4),
  },
  privacyLinks: {
    textDecoration: 'none',
  },
  privacyLinksText: {
    lineHeight: '24px',
    paddingRight: spacing(5),
  },
});

const { APP_LOGO, SIGN_IN_BUTTON, SIGN_UP_BUTTON, CHANGE_ORGANIZATION_BUTTON } =
  AUTOMATION_ID;

class AuthContainer extends PureComponent {
  renderLogo = () => {
    const { classes, translations } = this.props;
    const landingPageLink = `${process.env.REACT_APP_API_PROTOCOL}://www.${process.env.REACT_APP_API_DOMAIN}`;

    return (
      <a
        id={APP_LOGO}
        href={landingPageLink}
        className={classNames(classes.main, classes.logo)}
      >
        <Logo className={classes.logoIcon} />
        <Typography className={classes.company} variant="subtitle1">
          {translations.title}
        </Typography>
      </a>
    );
  };

  renderSignUp = () => {
    const { classes, translations, removeTopButton, signUpPage } = this.props;
    const changeOrganizationUrl = `${API_PROTOCOL}://${API_DOMAIN}/login`;
    const signUpUrl = `${API_PROTOCOL}://${API_DOMAIN}/sign-up`;

    return (
      !removeTopButton && (
        <div
          className={classNames(classes.signUpContainer, {
            [classes.removeTopButton]: removeTopButton,
          })}
        >
          <Typography variant="subtitle1" className={classes.signUpText}>
            {signUpPage ? translations.singInText : translations.singUpText}
          </Typography>
          <a
            href={signUpPage ? changeOrganizationUrl : signUpUrl}
            className={classes.signUpLink}
          >
            <CustomButton
              id={signUpPage ? SIGN_IN_BUTTON : SIGN_UP_BUTTON}
              className={classes.signUpButton}
              type="withTextDarkRounded"
            >
              {signUpPage
                ? translations.signInButton
                : translations.signUpButton}
            </CustomButton>
          </a>
        </div>
      )
    );
  };

  render() {
    const {
      translations,
      classes,
      switchWorkspace,
      children,
      subDomain,
      signUpPage,
      secondStep,
    } = this.props;
    const changeOrganizationUrl = `${API_PROTOCOL}://${API_DOMAIN}/login`;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} className={classes.sectionLeft}>
          {this.renderLogo()}
          <div className={classes.illustrationWrap}>
            <Typography className={classes.mobileExplanation}>
              {translations.desktopOnly}
            </Typography>
            <Illustration className={classes.illustration} />
          </div>
          <div className={classes.privacyLinksContainer}>
            <Typography className={classes.privacyLinksText} variant="body2">
              {translations.copyrightText}
            </Typography>
            <Link
              to={translations.privacy_policy.url}
              className={classes.privacyLinks}
            >
              <Typography
                variant="body2"
                component="span"
                className={classes.privacyLinksText}
              >
                {translations.privacy_policy.name}
              </Typography>
            </Link>
            <Link
              to={translations.terms_of_service.url}
              className={classes.privacyLinks}
            >
              <Typography
                variant="body2"
                component="span"
                className={classes.privacyLinksText}
              >
                {translations.terms_of_service.name}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.sectionRight}>
          {this.renderSignUp()}
          <div
            className={classNames(classes.formContainer, {
              [classes.signUpFormContainer]: signUpPage && !secondStep,
            })}
          >
            {children}
          </div>
          {switchWorkspace && (
            <div className={classes.changeOrganizationContainer}>
              <Typography className={classes.notIn}>
                {`${translations.notIn} ${subDomain}?`}
              </Typography>
              <a
                id={CHANGE_ORGANIZATION_BUTTON}
                href={changeOrganizationUrl}
                className={classes.changeOrganizationLink}
              >
                <Typography className={classes.changeOrganizationText}>
                  {translations.changeOrganization}
                </Typography>
              </a>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

AuthContainer.defaultProps = {
  switchWorkspace: false,
  signUpPage: false,
  secondStep: false,
  subDomain: '',
  removeTopButton: false,
};

AuthContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  switchWorkspace: PropTypes.bool,
  children: PropTypes.element.isRequired,
  subDomain: PropTypes.string,
  signUpPage: PropTypes.bool,
  secondStep: PropTypes.bool,
  removeTopButton: PropTypes.bool,
};

export default withStyles(styles)(AuthContainer);
