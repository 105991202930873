import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import CustomLink from '../customLink';

const styles = ({ spacing, palette: { primary } }) => ({
  resetLink: {
    textAlign: 'right',
    marginTop: spacing(5.75),
    padding: spacing(0, 1),
    width: '100%',
    transition: 'text-shadow .3s',
    '&:focus': {
      outline: 'none',
    },
    '&:hover p': {
      textShadow: `.05em 0 0 ${primary.blue4}`,
      fontFamily: 'ProximaNova-Regular',
    },
  },
});

class BackLink extends PureComponent {
  render() {
    const { classes, title, history, to, className, id } = this.props;

    return (
      <CustomLink
        id={id}
        className={classNames(classes.resetLink, className)}
        text={title}
        handleClick={() => history.push(to)}
      />
    );
  }
}
BackLink.defaultProps = {
  className: '',
  id: undefined,
};

BackLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default withStyles(styles)(BackLink);
