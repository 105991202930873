import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';
import { RECURRING_TYPES } from 'constants/oneOnOne';
import { isUserAppAccessForbidden } from 'utility/helpers';
import { isDateInPast } from 'utility/dateUtils';
import classNames from 'classnames';
import CustomModal from '../customModal';
import Impressions from '../impressions';
import CustomDatePicker from '../customDatePicker';
import CustomTimePicker from '../customTimePicker';
import CustomCheckbox from '../customCheckbox';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
  },
  recurringGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridRowGap: spacing(4),
    gridColumnGap: 24,
    marginTop: spacing(4),
    gridTemplateAreas: `
      "stoppedRecurring stoppedRecurring stoppedRecurring"
      "recurringDate recurringDate recurringTime"
      "notifyAll notifyAll notifyAll"
    `,
    alignItems: 'end',
  },
  recurringDate: {
    gridArea: 'recurringDate',
  },
  recurringTime: {
    gridArea: 'recurringTime',
  },
  stoppedRecurring: {
    gridArea: 'stoppedRecurring',
    display: 'grid',
  },
  notifyAll: {
    gridArea: 'notifyAll',
    display: 'grid',
    alignItems: 'center',
  },
  dialog: {
    width: 465,
  },
  impressions: {
    marginTop: spacing(3),
  },
  error: {
    position: 'absolute',
    left: 0,
  },
  dateError: {
    alignItems: 'center',
    '& > div:nth-last-child(2)': {
      marginTop: 5,
    },
  },
}));

const AddImpressionDialog = ({
  translations,
  isOpened,
  recordId,
  currentRecord,
  onCancel,
  onConfirm,
  onSaveAndCreate,
}) => {
  const classes = useStyles();
  const [impression, setImpression] = useState(0);
  const [selected, setSelected] = useState({
    nextRecurringDateTime: null,
    notifyAll: true,
  });
  const [stoppedRecurring, setStoppedRecurring] = useState(false);
  const [hasError, setHasError] = useState({
    impression: true,
    date: true,
  });
  const isRecurring = currentRecord?.recurring !== RECURRING_TYPES[0].id;
  const show = isRecurring ? stoppedRecurring : true;
  const { validationMessages } = translations;
  const nextRecurring =
    selected.nextRecurringDateTime || currentRecord?.nextRecurring;

  useEffect(() => {
    return () => {
      if (!recordId) {
        setImpression(0);
        setStoppedRecurring(false);
        setSelected({ nextRecurringDateTime: null, notifyAll: true });
        setHasError({
          impression: false,
          date: false,
        });
      }
    };
  }, [recordId]);

  const handleSelectImpression = value => {
    setImpression(value);

    if (hasError.impression) {
      setHasError({ ...hasError, impression: false });
    }
  };

  const handleNextRecurringDateTime = value => {
    const dateIsInPast = isDateInPast(value);

    if (dateIsInPast) {
      setHasError({ ...hasError, date: true });
    } else if (hasError.date && !dateIsInPast) {
      setHasError({ ...hasError, date: false });
    }
    setSelected({ ...selected, nextRecurringDateTime: value });
  };

  const handleValidation = () => {
    const isValid = !!impression;
    const dateIsInPast = isDateInPast(nextRecurring);
    const checkDate = isRecurring ? !stoppedRecurring : false;

    if (!isValid || (checkDate && dateIsInPast)) {
      setHasError({
        date: !!(checkDate && dateIsInPast),
        impression: !isValid,
      });
    }

    if (!isValid || hasError.impression || hasError.date) return;
    return true;
  };

  const isDisabledNotification = () => {
    return (
      (currentRecord?.user && isUserAppAccessForbidden(currentRecord?.user)) ||
      isDateInPast(nextRecurring)
    );
  };

  const handleSave = () => {
    if (!handleValidation()) {
      return;
    }
    const data = {
      ...currentRecord,
      impression,
      stoppedRecurring: show,
      nextRecurring,
      notify: isDisabledNotification() ? null : selected.notifyAll,
    };
    onConfirm(data);
  };

  const handleSaveAndCreate = () => {
    if (!handleValidation()) {
      return;
    }
    onSaveAndCreate(impression, currentRecord.tasks);
  };

  const handleRecurringCheckbox = () => {
    setSelected({
      nextRecurring: currentRecord?.nextRecurring,
      ...(stoppedRecurring ? { notifyAll: true } : { notifyAll: false }),
    });
    setHasError({ ...hasError, date: false });
    setStoppedRecurring(!stoppedRecurring);
  };

  const renderNotifyAll = () => {
    return !show ? (
      <div className={classes.notifyAll}>
        <CustomCheckbox
          name="notify"
          isChecked={isDisabledNotification() ? false : selected.notifyAll}
          labelText={translations.notifyAll}
          onChange={() =>
            setSelected(prevState => ({
              ...prevState,
              notifyAll: !prevState.notifyAll,
            }))
          }
          disabled={isDisabledNotification()}
          smallText
          isControlled
        />
      </div>
    ) : null;
  };

  return (
    <CustomModal
      paperClass={classes.dialog}
      title={translations.title}
      isOpened={isOpened}
      confirmButtonLabel={show ? translations.save : translations.saveAndCreate}
      closeButtonLabel={translations.cancel}
      saveAndCreateButtonLabel={
        !isRecurring ? translations.saveAndCreate : null
      }
      onClose={onCancel}
      onConfirm={handleSave}
      onSaveAndCreate={handleSaveAndCreate}
      isMedium
    >
      <div className={classes.root}>
        <Impressions
          className={classes.impressions}
          translations={translations.meetingImpression}
          value={impression}
          onSelectHandler={handleSelectImpression}
          isMedium
        />
        {hasError.impression && (
          <FormHelperText className={classes.error} error={hasError.impression}>
            {validationMessages.errorMessage}
          </FormHelperText>
        )}
        {isRecurring ? (
          <div
            className={classNames(classes.recurringGroup, {
              [classes.dateError]: hasError.date,
            })}
          >
            <div className={classes.stoppedRecurring}>
              <CustomCheckbox
                isChecked={stoppedRecurring}
                labelText={translations.stopRecurringLabel}
                onChange={handleRecurringCheckbox}
                smallText
                isControlled
              />
            </div>
            {!stoppedRecurring ? (
              <>
                <div className={classes.recurringDate}>
                  <CustomDatePicker
                    label={translations.recurring.label}
                    value={nextRecurring}
                    disableToolbar
                    onChange={handleNextRecurringDateTime}
                    isIconOnStart
                    error={hasError.date}
                    errorMessage={validationMessages.isPast}
                    disablePast
                    fullWidth
                  />
                </div>
                <div className={classes.recurringTime}>
                  <CustomTimePicker
                    selectedTime={nextRecurring}
                    error={hasError.date}
                    onChange={handleNextRecurringDateTime}
                  />
                </div>
              </>
            ) : null}
            {renderNotifyAll()}
          </div>
        ) : null}
      </div>
    </CustomModal>
  );
};

AddImpressionDialog.defaultProps = {
  recordId: null,
  onSaveAndCreate: () => {},
};

AddImpressionDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  recordId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSaveAndCreate: PropTypes.func,
};

export default memo(AddImpressionDialog);
