import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import Menu from './menu';
import SelectedFilters from './selectedFilters';
import { isArray, isArrayEmpty, trimString } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { resetFilters, isDirty } from './config';

const styles = () => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  filterButton: {
    flexShrink: 0,
  },
});

class Filters extends PureComponent {
  state = {
    anchorEl: null,
    selectedSubMenu: null,
    tempFilters: this.props.selectedFilters,
    search: '',
  };

  handleFilterSearch = value => this.setState({ search: trimString(value) });

  handleClose = e => {
    const { selectedFilters } = this.props;

    e.stopPropagation();
    this.setState({
      anchorEl: null,
      selectedSubMenu: null,
      tempFilters: selectedFilters,
      search: '',
    });
  };

  handleCloseSubMenu = () => {
    this.setState({
      selectedSubMenu: null,
    });
  };

  handleOpenPrimaryMenu = e => {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };

  toggleSubMenu = newSelected => () => {
    const { selectedFilters } = this.props;
    const { selectedSubMenu } = this.state;

    this.setState({
      selectedSubMenu:
        newSelected.id === selectedSubMenu?.id ? null : newSelected,
      tempFilters: selectedFilters,
      search: '',
    });
  };

  handleSelectFilter = selectedFilter => () => {
    const { selectedSubMenu, tempFilters } = this.state;
    const { id, parent } = selectedSubMenu;
    const currentSubMenuId = parent?.id || id;
    const selectedFilterIndex = tempFilters[currentSubMenuId].findIndex(
      filter => filter === selectedFilter
    );

    if (selectedSubMenu.isSingleSelect) {
      return this.setState(prevState => ({
        tempFilters: {
          ...prevState.tempFilters,
          [id]: [...(selectedFilter !== null ? [selectedFilter] : [])],
        },
      }));
    }

    if (selectedFilterIndex === -1) {
      return this.setState(prevState => ({
        tempFilters: {
          ...prevState.tempFilters,
          [currentSubMenuId]: [
            ...prevState.tempFilters[currentSubMenuId],
            selectedFilter,
          ],
        },
      }));
    }

    this.setState(prevState => ({
      tempFilters: {
        ...prevState.tempFilters,
        [currentSubMenuId]: [
          ...prevState.tempFilters[currentSubMenuId].slice(
            0,
            selectedFilterIndex
          ),
          ...prevState.tempFilters[currentSubMenuId].slice(
            selectedFilterIndex + 1,
            prevState.tempFilters[currentSubMenuId].length
          ),
        ],
      },
    }));
  };

  handleApplyFilters = () => {
    const { selectedFilters, onApplyFilters } = this.props;
    const { tempFilters, selectedSubMenu } = this.state;
    const { id, parent } = selectedSubMenu;
    const currentSubMenuId = parent?.id || id;

    // Check if submenu filters are changed
    if (
      isDirty(selectedFilters[currentSubMenuId], tempFilters[currentSubMenuId])
    ) {
      onApplyFilters(tempFilters);
    }
    this.handleCloseSubMenu();
  };

  handleClearFilterCategory = category => {
    const { onApplyFilters } = this.props;

    this.setState(
      prevState => ({
        ...prevState,
        tempFilters: { ...prevState.tempFilters, [category]: [] },
      }),
      () => onApplyFilters(this.state.tempFilters)
    );
  };

  handleClearAll = () => {
    const { onApplyFilters } = this.props;
    const { tempFilters } = this.state;

    const filters = resetFilters(tempFilters);

    this.setState({ tempFilters: filters });
    return onApplyFilters(filters);
  };

  render() {
    const { className, classes, translations, filters, selectedFilters } =
      this.props;
    const { anchorEl, tempFilters, selectedSubMenu, search } = this.state;

    return (
      <div className={classNames(classes.main, className)}>
        <CustomButton
          id={AUTOMATION_ID.FILTER_BUTTON}
          className={classes.filterButton}
          type="filters"
          onClick={this.handleOpenPrimaryMenu}
        >
          {translations.filters}
        </CustomButton>
        <SelectedFilters
          translations={translations}
          filters={filters}
          selectedFilters={selectedFilters}
          onClearCategory={this.handleClearFilterCategory}
          onClear={this.handleClearAll}
        />
        {isArray(filters) && !isArrayEmpty(filters) && (
          <Menu
            translations={translations}
            anchorEl={anchorEl}
            menuItems={filters}
            selectedSubMenu={selectedSubMenu}
            selectedFilters={tempFilters}
            searchTerm={search}
            onSelectFilter={this.handleSelectFilter}
            onFilterSearch={this.handleFilterSearch}
            onToggleSubMenu={this.toggleSubMenu}
            onClose={this.handleClose}
            onApply={this.handleApplyFilters}
          />
        )}
      </div>
    );
  }
}

Filters.defaultProps = {
  className: null,
};

Filters.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(Filters);
