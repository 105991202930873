import { cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import {
  checkUserRole,
  replacePropsInObject,
  objectHasProperty,
} from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { hasActiveSubscription } from '../../../utility/subscriptionHelper';
import { FREE_PLAN_USERS_LIMIT } from '../../../constants/appConfig';

const LOGIN = '/login';
const UNAUTHORIZED = '/unauthorized-access';

const ProtectedRoute = ({
  children,
  requiredRoles,
  auth,
  organizationSettings,
  isPersonInfoRoute,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isUserAuthenticated = auth && auth.access;
  const allowUserToAccessRoute =
    isUserAuthenticated && requiredRoles.includes(auth.role);
  const isAdmin = isUserAuthenticated && checkUserRole(auth.role, ROLES.ADMIN);

  const forbidAccessToPersonInfoRoutes = () => {
    const whitelistEmployees = replacePropsInObject(
      auth.accessibleProfiles,
      organizationSettings.global_see_himself
        ? {
            [auth.id]: true,
          }
        : {}
    );

    return !objectHasProperty(whitelistEmployees, id);
  };

  const handleRedirect = path => {
    return (
      <Navigate
        to={{
          pathname: path,
          state: { from: location },
        }}
      />
    );
  };

  if (isUserAuthenticated) {
    if (!allowUserToAccessRoute) {
      return handleRedirect(UNAUTHORIZED);
    }

    if (
      organizationSettings?.user_count > FREE_PLAN_USERS_LIMIT &&
      !hasActiveSubscription(
        organizationSettings?.subscription,
        organizationSettings?.subscription_end_at
      )
    ) {
      if (!rest?.dialogs?.upgradeRequiredDialogOpened) {
        rest.setDialogVisibility({
          dialogName: 'upgradeRequiredDialog',
          opened: true,
        });
      }
    }

    if (
      allowUserToAccessRoute &&
      !isAdmin &&
      isPersonInfoRoute &&
      forbidAccessToPersonInfoRoutes()
    ) {
      return handleRedirect(UNAUTHORIZED);
    }

    return cloneElement(children, {
      location,
      navigate,
      auth,
      organizationSettings,
      ...rest,
    });
  }

  return handleRedirect(LOGIN);
};

ProtectedRoute.defaultProps = {
  requiredRoles: [],
  organizationSettings: {},
  isPersonInfoRoute: false,
};

ProtectedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  isPersonInfoRoute: PropTypes.bool,
};

export default memo(ProtectedRoute);
