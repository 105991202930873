import { createAction, handleActions } from 'redux-actions';
import {
  API_QUESTIONS,
  api_attribute_reorder_question,
  api_question,
  api_attribute,
  api_set_questions,
} from '../../constants/apiRoutes';
import http from '../../utility/http';
import { replaceObjectInList } from '../../utility/helpers';
// ------------------------------------
// Constants
// ------------------------------------

const SET_ATTRIBUTE = 'SET_ATTRIBUTE';
const SET_QUESTIONS = 'SET_QUESTIONS';
const ADD_QUESTION = 'ADD_QUESTION';
const UPDATE_QUESTION = 'UPDATE_QUESTION';
const DELETE_ALL_QUESTIONS = 'DELETE_ALL_QUESTIONS';

const initialState = {
  attribute: {
    questions: [],
  },
};

// ------------------------------------
// Actions
// ------------------------------------params
export const setAttribute = createAction(SET_ATTRIBUTE);
const setQuestions = createAction(SET_QUESTIONS);
const addQuestion = createAction(ADD_QUESTION);
const updateQuestion = createAction(UPDATE_QUESTION);
const deleteAllQuestions = createAction(DELETE_ALL_QUESTIONS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ATTRIBUTE]: (state, { payload }) => {
    return {
      attribute: { ...state.attribute, ...payload },
    };
  },
  [SET_QUESTIONS]: (state, { payload }) => {
    return {
      attribute: {
        ...state.attribute,
        questions: payload,
      },
    };
  },
  [ADD_QUESTION]: (state, { payload }) => {
    return {
      attribute: {
        ...state.attribute,
        questions: [...state.attribute.questions, payload],
      },
    };
  },
  [UPDATE_QUESTION]: (state, { payload }) => {
    const questionIndex = state.attribute.questions.findIndex(
      question => question.id === payload.id
    );

    return {
      attribute: {
        ...state.attribute,
        questions: replaceObjectInList(
          state.attribute.questions,
          questionIndex,
          payload
        ),
      },
    };
  },
  [DELETE_ALL_QUESTIONS]: (state, { payload }) => {
    return {
      attribute: { ...state.attribute, questions: payload },
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const updateAttribute = (dispatch, id, updatedData) => {
  return http.patch(api_attribute(id), updatedData).then(({ data }) => {
    dispatch(setAttribute(data));
  });
};

export const getAttribute = (dispatch, id) => {
  return http.get(api_attribute(id)).then(({ data }) => {
    dispatch(setAttribute(data));
  });
};

export const setAttributeQuestions = (dispatch, id, updatedQuestion) => {
  return http.post(api_set_questions(id), updatedQuestion).then(({ data }) => {
    dispatch(setQuestions(data));
  });
};

export const updateAttributeQuestions = (dispatch, id) => {
  return http.patch(api_set_questions(id)).then(({ data }) => {
    dispatch(setQuestions(data));
  });
};

export const createQuestion = (dispatch, questionData) => {
  return http
    .post(API_QUESTIONS, questionData)
    .then(({ data }) => dispatch(addQuestion(data)));
};

export const updateAttributeQuestion = (dispatch, updatedQuestion) => {
  return http
    .put(api_question(updatedQuestion.id), updatedQuestion)
    .then(({ data }) => {
      dispatch(updateQuestion(data));
    });
};

export const deleteAttributeQuestion = (dispatch, id) => {
  return http.delete(api_question(id));
};

export const deleteAllAttributeQuestions = (dispatch, attributeId) => {
  return http.delete(api_set_questions(attributeId)).then(() => {
    dispatch(deleteAllQuestions(initialState.attribute.questions));
  });
};

export const reorderQuestions = (dispatch, reorderedData) => {
  const { attributeId, orderedIds } = reorderedData;
  http
    .post(api_attribute_reorder_question(attributeId), orderedIds)
    .then(({ data }) => {
      dispatch(setAttribute(data));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearAttribute = () => setAttribute(initialState.attribute);
