import { isFuture } from 'date-fns';
import { ReactComponent as SelfAssessmentIcon } from '../assets/icons/self-assesment-small.svg';
import { ReactComponent as DirectReviewIcon } from '../assets/icons/direct-review-small.svg';
import { ReactComponent as TeamReviewIcon } from '../assets/icons/team-review-small.svg';
import { ReactComponent as PeerReviewIcon } from '../assets/icons/peer-review-small.svg';
import { ReactComponent as CompanyPulseIcon } from '../assets/icons/company-pulse-small.svg';
import { ReactComponent as AnonymousIcon } from '../assets/icons/anonymous-user.svg';
import { ReactComponent as OneTimeSurveyIcon } from '../assets/icons/excluded.svg';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../utility/validation';
import { getObjectToNumberArray, isArrayEmpty } from '../utility/helpers';
import {
  addNumberOfDays,
  substractNumberOfDays,
  checkIsSameDay,
  addHoursToCurrentStartHour,
} from '../utility/dateUtils';
import { getSurveyTitle } from '../components/pages/surveysPage/config';
import { FIELD_TYPES } from './fieldTypes';

const {
  TEXT,
  SELECT,
  AUTOCOMPLETE,
  SURVEY_PREVIEW,
  CHECKBOX,
  DATE_PICKER,
  TIME_PICKER,
  READ_ONLY,
  PEOPLE_PICKER,
  SWITCH,
  SUBTITLE,
} = FIELD_TYPES;

export const CREATE_SURVEY_FIELD_NAMES = {
  CREATED_FOR: 'created_for',
  IS_ANONYMOUS: 'is_anonymous',
  ATTRIBUTES: 'attributes',
  TITLE: 'title',
  REVIEWERS: 'surveyee',
  IS_SCHEDULE: 'isSchedule',
  SCHEDULED_FOR: 'schedule_for',
  FILL_INLINE: 'fill_inline',
  SURVEY_TYPE: 'survey_type',
  CLIENT_URL: 'client_url',
  IS_STATISTICAL: 'is_statistical',
  SHARE_TO: 'share_to',
  EXPIRATION_DATE: 'expire_at',
  HAS_EMAIL_MESSAGE: 'hasEmailMessage',
  EMAIL_MESSAGE: 'message',
  GROUP: 'group',
  STATUS: 'status',
  SEND: 'send',
};

export const SURVEY_TYPES = {
  SELF: 'SELF',
  TEAM: 'TEAM',
  PEER: 'PEER',
  DIRECT: 'DIRECT',
  PULSE: 'PULSE',
};

export const GROUPED_SURVEY_TYPES_KEYS = {
  TEAM_AND_PEER_AND_DIRECT_REVIEW: 'TEAM_AND_PEER_AND_DIRECT_REVIEW',
  SELF_ASSESSMENT: 'SELF_ASSESSMENT',
  PULSE: 'PULSE',
};

const MIN_REVIWERS_FOR_ANONYMOUS_SURVEY = 6;

export const getExpiryDialogFields = minDate => [
  {
    name: 'expiryDate',
    type: FIELD_TYPES.DATE_PICKER,
    translationKey: 'expiry',
    hasDivider: true,
    isToolbarDisabled: true,
    getMinDate: () => addNumberOfDays(new Date(minDate), 2),
  },
  {
    name: 'expiryTime',
    type: FIELD_TYPES.TIME_PICKER,
    hasDivider: true,
    isPastDisabled: true,
  },
];

const getMutualColumn = (hasAnonymousField = false) => ({
  id: 1,
  title: 'Survey',
  translationKey: 'survey',
  className: 'mutualColumn',
  hasScroll: true,
  fields: [
    {
      name: CREATE_SURVEY_FIELD_NAMES.SURVEY_TYPE,
      type: SELECT,
      translationKey: 'surveyType',
      fieldWrapperClass: 'type',
      optionsKey: 'surveyTypes',
      required: true,
      isSearchDisabled: true,
      shouldResetOnChange: true,
      parser: {
        value: 'id',
        label: 'name',
      },
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.TITLE,
      type: TEXT,
      required: true,
      fieldWrapperClass: 'title',
      validators: [
        { type: 'required', validator: isEmpty },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'maxLength',
          validator: value => validateLength(value, 0, 150),
        },
      ],
    },
    ...(hasAnonymousField
      ? [
          {
            name: CREATE_SURVEY_FIELD_NAMES.IS_ANONYMOUS,
            type: CHECKBOX,
            endLabelIcon: AnonymousIcon,
            translationKey: 'isAnonymous',
            fieldWrapperClass: 'anonymous',
            isControlled: true,
            smallText: true,
            isDisabledCheck: values =>
              values[CREATE_SURVEY_FIELD_NAMES.REVIEWERS].length <
              MIN_REVIWERS_FOR_ANONYMOUS_SURVEY,
          },
        ]
      : []),
    {
      name: CREATE_SURVEY_FIELD_NAMES.IS_STATISTICAL,
      type: CHECKBOX,
      endLabelIcon: OneTimeSurveyIcon,
      translationKey: 'oneTime',
      fieldWrapperClass: 'statistical',
      isControlled: true,
      hasDivider: true,
      isReverse: true,
      smallText: true,
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE,
      type: DATE_PICKER,
      translationKey: 'expiry',
      fieldWrapperClass: 'expiryDate',
      hasDivider: true,
      isToolbarDisabled: true,
      getMinDate: (value, values, initialValues) => {
        const isSchedule = values[CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE];
        const scheduledFor = values[CREATE_SURVEY_FIELD_NAMES.SCHEDULED_FOR];
        const initialScheduledFor =
          initialValues[CREATE_SURVEY_FIELD_NAMES.SCHEDULED_FOR];
        const isSameDay = checkIsSameDay(initialScheduledFor, scheduledFor);

        return isSchedule && !isSameDay
          ? addNumberOfDays(new Date(scheduledFor), 2)
          : addNumberOfDays(new Date(), 2);
      },
      validators: [{ type: 'isPast', validator: value => isFuture(value) }],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE,
      type: TIME_PICKER,
      fieldWrapperClass: 'expiryTime',
      customRootClass: 'expiryTimeRoot',
      hasDivider: true,
      validators: [{ type: 'isPast', validator: value => isFuture(value) }],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.HAS_EMAIL_MESSAGE,
      type: SWITCH,
      translationKey: 'email',
      fieldWrapperClass: 'email',
      isHighlightedLabel: true,
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.EMAIL_MESSAGE,
      type: TEXT,
      translationKey: 'emailContent',
      fieldWrapperClass: 'emailDescription',
      multiline: true,
      rows: 4,
      isToggleable: true,
      hasDivider: true,
      shouldRender: values =>
        values[CREATE_SURVEY_FIELD_NAMES.HAS_EMAIL_MESSAGE],
      validators: [
        { type: 'required', validator: isEmpty },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'emailContentMaxLength',
          validator: value => validateLength(value, 0, 500),
        },
      ],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE,
      type: SWITCH,
      translationKey: 'schedule',
      fieldWrapperClass: 'schedule',
      isHighlightedLabel: true,
      dependants: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.SCHEDULED_FOR,
          resetFnc: () => addHoursToCurrentStartHour(),
        },
      ],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.SCHEDULED_FOR,
      type: DATE_PICKER,
      fieldWrapperClass: 'scheduleDate',
      isToggleable: true,
      hasDivider: true,
      isToolbarDisabled: true,
      isPastDisabled: true,
      getMaxDate: (value, values) =>
        substractNumberOfDays(
          new Date(values[CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE]),
          2
        ),
      shouldRender: values => values[CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE],
      validators: [
        {
          type: 'isPast',
          validator: (value, values) =>
            isFuture(value) || !values[CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE],
          shouldPassFormValues: true,
        },
      ],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.SCHEDULED_FOR,
      type: TIME_PICKER,
      translationKey: 'scheduledFor',
      fieldWrapperClass: 'scheduleTime',
      customRootClass: 'scheduleTimeRoot',
      isToggleable: true,
      hasDivider: true,
      shouldRender: values => values[CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE],
      validators: [
        {
          type: 'isPast',
          validator: (value, values) =>
            isFuture(value) || !values[CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE],
          shouldPassFormValues: true,
        },
      ],
    },
    {
      name: CREATE_SURVEY_FIELD_NAMES.SHARE_TO,
      type: SELECT,
      translationKey: 'sharedTo',
      fieldWrapperClass: 'shared',
      optionsKey: 'moderators',
      isUser: true,
      isMulti: true,
      parser: {
        value: 'id',
        label: 'first_name',
      },
    },
  ],
});

const PREVIEW_COLUMN = {
  id: 3,
  title: 'Preview',
  translationKey: 'preview',
  className: 'column',
  columnWrapperClass: 'preview-wrapper',
  hasScroll: false,
  fields: [
    {
      name: 'survey_preview',
      translationKey: 'surveyPreview',
      type: SURVEY_PREVIEW,
      reviewKey: 'attribute',
      getReviewKey: 'getAttribute',
      clearReviewKey: 'clearAttribute',
    },
  ],
};

const SELF = {
  id: SURVEY_TYPES.SELF,
  surveyIcon: SelfAssessmentIcon,
  columns: [
    { ...getMutualColumn() },
    {
      id: 2,
      title: 'People',
      translationKey: 'people',
      className: 'peopleColumn',
      hasScroll: true,
      fields: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES,
          type: SELECT,
          optionsKey: 'attributesWithQuestions',
          required: true,
          isAttribute: true,
          shouldReturnOption: true,
          shouldDisableSort: true,
          isAttributeDraggable: true,
          isMulti: true,
          hasColorBox: true,
          parser: {
            value: 'id',
            label: 'name',
          },
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.TITLE,
              resetFnc: (
                prevValue,
                currentValue,
                dependantValue,
                initialValues
              ) => {
                return getSurveyTitle(
                  initialValues[CREATE_SURVEY_FIELD_NAMES.TITLE],
                  currentValue,
                  prevValue,
                  dependantValue
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'people',
          type: SUBTITLE,
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          type: PEOPLE_PICKER,
          translationKey: 'selfReview',
          optionsKey: 'employees',
          hasSelectAll: true,
          required: true,
          showDirectReports: true,
          showSelection: true,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
      ],
    },
    { ...PREVIEW_COLUMN },
  ],
};

const DIRECT = {
  id: SURVEY_TYPES.DIRECT,
  surveyIcon: DirectReviewIcon,
  columns: [
    { ...getMutualColumn() },
    {
      id: 2,
      title: 'People',
      translationKey: 'people',
      className: 'peopleColumn',
      hasScroll: true,
      fields: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES,
          type: SELECT,
          optionsKey: 'attributesWithQuestions',
          required: true,
          isAttribute: true,
          shouldReturnOption: true,
          shouldDisableSort: true,
          isMulti: true,
          isAttributeDraggable: true,
          hasColorBox: true,
          parser: {
            value: 'id',
            label: 'name',
          },
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.TITLE,
              resetFnc: (
                prevValue,
                currentValue,
                dependantValue,
                initialValues
              ) => {
                return getSurveyTitle(
                  initialValues[CREATE_SURVEY_FIELD_NAMES.TITLE],
                  currentValue,
                  prevValue,
                  dependantValue
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'people',
          type: SUBTITLE,
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          type: AUTOCOMPLETE,
          optionsKey: 'allUsers',
          isSingleSelect: true,
          shouldRenderSelectedOutside: true,
          hasAvatar: true,
          translationKey: 'reviewer',
          hasDivider: true,
          required: true,
          parent: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          type: AUTOCOMPLETE,
          optionsKey: 'employees',
          isSingleSelect: true,
          shouldRenderSelectedOutside: true,
          translationKey: 'whoWillBeReviewed',
          hasAvatar: true,
          required: true,
          parent: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.SHARE_TO,
              resetFnc: (prevValue, value, dependantValue) => {
                const currentValue = getObjectToNumberArray(value);

                return dependantValue.filter(
                  dValue => !currentValue.includes(dValue)
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
      ],
    },
    { ...PREVIEW_COLUMN },
  ],
};

const TEAM = {
  id: SURVEY_TYPES.TEAM,
  surveyIcon: TeamReviewIcon,
  columns: [
    { ...getMutualColumn() },
    {
      id: 2,
      title: 'People',
      translationKey: 'people',
      className: 'peopleColumn',
      hasScroll: true,
      fields: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES,
          type: SELECT,
          optionsKey: 'attributesWithQuestions',
          required: true,
          isAttribute: true,
          shouldReturnOption: true,
          shouldDisableSort: true,
          isMulti: true,
          isAttributeDraggable: true,
          hasColorBox: true,
          parser: {
            value: 'id',
            label: 'name',
          },
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.TITLE,
              resetFnc: (
                prevValue,
                currentValue,
                dependantValue,
                initialValues
              ) => {
                return getSurveyTitle(
                  initialValues[CREATE_SURVEY_FIELD_NAMES.TITLE],
                  currentValue,
                  prevValue,
                  dependantValue
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'people',
          type: SUBTITLE,
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          type: AUTOCOMPLETE,
          optionsKey: 'allUsers',
          isSingleSelect: true,
          shouldRenderSelectedOutside: true,
          parent: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          required: true,
          hasAvatar: true,
          translationKey: 'reviewer',
          hasDivider: true,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          type: PEOPLE_PICKER,
          translationKey: 'whoWillBeReviewed',
          optionsKey: 'employees',
          showSelection: true,
          hasSelectAll: true,
          showDirectReports: true,
          parent: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          required: true,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
      ],
    },
    { ...PREVIEW_COLUMN },
  ],
};

const PEER = {
  id: SURVEY_TYPES.PEER,
  surveyIcon: PeerReviewIcon,
  columns: [
    { ...getMutualColumn(true) },
    {
      id: 2,
      title: 'People',
      translationKey: 'people',
      className: 'peopleColumn',
      hasScroll: true,
      fields: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES,
          type: SELECT,
          optionsKey: 'attributesWithQuestions',
          required: true,
          isAttribute: true,
          shouldReturnOption: true,
          shouldDisableSort: true,
          isMulti: true,
          isAttributeDraggable: true,
          hasColorBox: true,
          parser: {
            value: 'id',
            label: 'name',
          },
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.TITLE,
              resetFnc: (
                prevValue,
                currentValue,
                dependantValue,
                initialValues
              ) => {
                return getSurveyTitle(
                  initialValues[CREATE_SURVEY_FIELD_NAMES.TITLE],
                  currentValue,
                  prevValue,
                  dependantValue
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'people',
          type: SUBTITLE,
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          type: AUTOCOMPLETE,
          maxItems: 1,
          optionsKey: 'employees',
          isSingleSelect: true,
          shouldRenderSelectedOutside: true,
          required: true,
          hasAvatar: true,
          translationKey: 'whoWillBeReviewed',
          parent: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          hasDivider: true,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          type: PEOPLE_PICKER,
          translationKey: 'reviewers',
          optionsKey: 'allUsers',
          showDirectReports: true,
          hasSelectAll: true,
          showSelection: true,
          parent: CREATE_SURVEY_FIELD_NAMES.CREATED_FOR,
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.IS_ANONYMOUS,
              resetFnc: (prevValue, currentValue, dependantValue) => {
                if (currentValue.length < MIN_REVIWERS_FOR_ANONYMOUS_SURVEY) {
                  return false;
                }

                return dependantValue;
              },
            },
          ],
          required: true,
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
      ],
    },
    { ...PREVIEW_COLUMN },
  ],
};

const PULSE = {
  id: SURVEY_TYPES.PULSE,
  surveyIcon: CompanyPulseIcon,
  columns: [
    { ...getMutualColumn(true) },
    {
      id: 2,
      title: 'People',
      translationKey: 'people',
      className: 'peopleColumn',
      hasScroll: true,
      fields: [
        {
          name: CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES,
          type: SELECT,
          optionsKey: 'attributesWithQuestions',
          required: true,
          isAttribute: true,
          shouldReturnOption: true,
          shouldDisableSort: true,
          isMulti: true,
          isAttributeDraggable: true,
          hasColorBox: true,
          parser: {
            value: 'id',
            label: 'name',
          },
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.TITLE,
              resetFnc: (
                prevValue,
                currentValue,
                dependantValue,
                initialValues
              ) => {
                return getSurveyTitle(
                  initialValues[CREATE_SURVEY_FIELD_NAMES.TITLE],
                  currentValue,
                  prevValue,
                  dependantValue
                );
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'people',
          type: SUBTITLE,
        },
        {
          name: CREATE_SURVEY_FIELD_NAMES.REVIEWERS,
          type: PEOPLE_PICKER,
          translationKey: 'reviewers',
          optionsKey: 'allUsers',
          showDirectReports: true,
          hasSelectAll: true,
          required: true,
          showSelection: true,
          hasDivider: true,
          dependants: [
            {
              name: CREATE_SURVEY_FIELD_NAMES.IS_ANONYMOUS,
              resetFnc: (prevValue, currentValue, dependantValue) => {
                if (currentValue.length < MIN_REVIWERS_FOR_ANONYMOUS_SURVEY) {
                  return false;
                }

                return dependantValue;
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isArrayEmpty(value) },
          ],
        },
        {
          name: 'company',
          type: READ_ONLY,
          translationKey: 'whoWillBeReviewed',
        },
      ],
    },
    { ...PREVIEW_COLUMN },
  ],
};

export const SURVEY_TYPES_MAP = {
  [SURVEY_TYPES.SELF]: SELF,
  [SURVEY_TYPES.DIRECT]: DIRECT,
  [SURVEY_TYPES.TEAM]: TEAM,
  [SURVEY_TYPES.PEER]: PEER,
  [SURVEY_TYPES.PULSE]: PULSE,
};

export const GROUPED_SURVEY_REPORTING_TYPES = [
  {
    name: 'Peer & team',
    values: [SURVEY_TYPES.TEAM, SURVEY_TYPES.PEER, SURVEY_TYPES.DIRECT],
    key: GROUPED_SURVEY_TYPES_KEYS.TEAM_AND_PEER_AND_DIRECT_REVIEW,
    translationKey: 'peerTeamAndDirect',
  },
  {
    name: 'Self',
    values: [SURVEY_TYPES.SELF],
    key: GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT,
    translationKey: 'self',
  },
  {
    name: 'PULSE',
    values: [SURVEY_TYPES.PULSE],
    key: GROUPED_SURVEY_TYPES_KEYS.PULSE,
    translationKey: 'pulse',
  },
];

export const SURVEY_VIEW_OPTIONS = [
  {
    value: false,
    name: 'List',
    key: 'list',
  },
  {
    value: true,
    name: 'Group',
    key: 'group',
  },
];

export const SURVEY_GROUP_FIELD = {
  name: 'Group',
  type: FIELD_TYPES.SELECT,
  translationKey: 'group',
  required: true,
  parser: {
    label: 'title',
    value: 'id',
  },
  validators: [{ type: 'required', validator: isEmpty }],
};
