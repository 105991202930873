import { isEmpty, validatePassword } from '../../../utility/validation';

const fields = [
  {
    name: 'password',
    type: 'password',
    label: 'password',
    required: true,
    validators: [
      {
        translationKey: 'invalidPassword',
        validator: isEmpty,
      },
      {
        translationKey: 'passwordRequirements',
        validator: validatePassword,
      },
    ],
  },
  {
    name: 'repeatPassword',
    type: 'password',
    label: 'repeatPassword',
    required: true,
    validators: [
      {
        translationKey: 'invalidRepeatPassword',
        validator: isEmpty,
      },
      {
        translationKey: 'passwordRequirements',
        validator: validatePassword,
      },
    ],
  },
];

export default fields;
