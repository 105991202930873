import { PureComponent } from 'react';

import AuthContainer from '../../shared/authContainer';
import LoginForm from '../../shared/loginForm';
import EnterOrganizationUrlForm from '../../shared/enterOrganizationLoginForm';

class LoginPage extends PureComponent {
  render() {
    const { subDomain } = this.props;

    if (subDomain && subDomain.length > 0) {
      return (
        <AuthContainer
          translations={this.props.translations.authContainer}
          switchWorkspace
          subDomain={subDomain}
        >
          <LoginForm {...this.props} />
        </AuthContainer>
      );
    }
    return (
      <AuthContainer translations={this.props.translations.authContainer}>
        <EnterOrganizationUrlForm {...this.props} />
      </AuthContainer>
    );
  }
}

export default LoginPage;
