import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Popover,
  MenuList,
  MenuItem,
  Typography,
  makeStyles,
  alpha,
} from '@material-ui/core';
import UserAvatar from '../userAvatar';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import { getPersonFullName } from '../../../utility/uiUtils';
import { checkUserRole } from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { AUTOMATION_ID } from '../../../constants/automationId';
import UpgradePlan from '../upgradePlan';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  userInfo: {
    cursor: ({ canSeeProfile }) => (canSeeProfile ? 'pointer' : 'default'),
    margin: spacing(0, 4),
    width: 'auto',
    maxWidth: 180,
  },
  userFullName: {
    marginBottom: spacing(1),
    transition: 'color .25s ease-in',
    '&:hover': {
      color: ({ canSeeProfile }) =>
        canSeeProfile ? primary.blue1 : primary.bluish1,
    },
  },
  popover: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginTop: spacing(3),
    paddingRight: spacing(1.5),
    overflowX: 'visible',
    overflowY: 'visible',
  },
  menu: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    width: 'fit-content',
    minWidth: 185,
    padding: 0,
  },
  menuItem: {
    padding: spacing(4),
    transition: 'background-color .25s ease-in',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  disabled: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: primary.white,
    },
    '& span': {
      color: primary.bluish7,
    },
  },
  paper: {
    width: '400px',
    height: '135px',
    padding: spacing(5, 7, 6, 7),
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 16,
    boxShadow: 'none',
    marginTop: spacing(2),
  },
}));

const UserMenu = ({
  className,
  translations,
  user,
  organizationData,
  menuItems,
  onGoToProfile,
  onGoToPricingPlan,
  handleClose,
  anchorSubEl,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isAdmin = checkUserRole(user.role, ROLES.ADMIN);
  const canSeeProfile = isAdmin || organizationData.globalSeeHimself;
  const classes = useStyles({ canSeeProfile });

  const handleGoToProfile = () => {
    if (canSeeProfile) {
      onGoToProfile(user.id);
    }
  };

  const handleOpenMenu = event => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const open = Boolean(anchorSubEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.userInfo} onClick={handleGoToProfile}>
        <TextBoxWithTooltip
          className={classes.userFullName}
          variant="subtitle1"
          text={getPersonFullName(user)}
        />
        <TextBoxWithTooltip
          variant="body2"
          text={user?.position?.name || translations.jobTitlePlaceholder}
        />
      </div>
      <UserAvatar
        automationId={AUTOMATION_ID.USER_MENU_BUTTON}
        user={user}
        onClickHandler={handleOpenMenu}
        clickableCaption
        medium
      />
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        PaperProps={{
          classes: { root: classes.popover },
        }}
        onClose={handleCloseMenu}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <MenuList classes={{ padding: classes.menu }}>
          {menuItems.map(item => {
            return (
              <MenuItem
                key={item.id}
                id={`user-menu-item-${item.id}`}
                classes={{
                  root: classNames(classes.menuItem, {
                    [classes.disabled]: item.isDisabled,
                  }),
                }}
                onClick={e => !item.isDisabled && item.action(e)}
              >
                <Typography component="span">{item.name}</Typography>
              </MenuItem>
            );
          })}
        </MenuList>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorSubEl}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <UpgradePlan
            organizationData={organizationData}
            translations={translations}
            onGoToPricingPlan={onGoToPricingPlan}
          />
        </Popover>
      </Popover>
    </div>
  );
};

UserMenu.defaultProps = {
  className: null,
  onGoToPricingPlan: () => {},
  handleClose: () => {},
};

UserMenu.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  organizationData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onGoToProfile: PropTypes.func.isRequired,
  onGoToPricingPlan: PropTypes.func,
  handleClose: PropTypes.func,
};

export default memo(UserMenu);
