import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import CareerPerformance from '../../shared/careerPerformance';
import CareerProgress from '../../shared/careerProgress';
import CustomFormDrawer from '../../shared/customFormDrawer';
import http from '../../../utility/http';
import {
  isArrayEmpty,
  checkUserRole,
  isUserSuspended,
} from '../../../utility/helpers';
import { hasQuantitativeAttributeScores } from '../../../utility/attribute';
import {
  reformatUserForSurvey,
  reformatAttributeForSurvey,
  showSuccessMessage,
  prepareTracksWithLevels,
  isEmployeeInReporters,
} from '../../../utility/uiUtils';
import { API_ATTRIBUTES } from '../../../constants/apiRoutes';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { sticky } from '../../../constants/helperCssRules';
import {
  SURVEY_TYPES,
  CREATE_SURVEY_FIELD_NAMES,
  GROUPED_SURVEY_REPORTING_TYPES,
} from '../../../constants/survey';
import { getTrackLevels, getCareerInfoFields } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    paddingBottom: spacing(4),
    ...sticky(primary.white, 169),
  },
  progress: {
    marginTop: spacing(9),
  },
});

class PeopleCareerPage extends PureComponent {
  state = {
    isLoading: true,
    isDrawerOpened: false,
  };

  componentDidMount() {
    const {
      user,
      getJobTitles,
      getLevelsAndTracks,
      getPersonJobTitle,
      getMeasuredAttributes,
    } = this.props;

    if (user.id !== -1) {
      Promise.all([
        ...(user?.position?.id ? [getPersonJobTitle(user.position?.id)] : []),
        getJobTitles(),
        getLevelsAndTracks(),
      ]).then(() => {
        getMeasuredAttributes(user.id).then(() =>
          this.setState({ isLoading: false })
        );
      });
    }
  }

  componentWillUnmount() {
    const { clearJobTitles, clearLevelsAndTracks, clearPersonCareer } =
      this.props;

    clearJobTitles();
    clearLevelsAndTracks();
    clearPersonCareer();
  }

  handleSendSurvey = (attributeId, isSelf = false) => {
    const { history, user, auth } = this.props;
    const { SELF, PEER } = SURVEY_TYPES;
    const { SURVEY_TYPE, CREATED_FOR, REVIEWERS, ATTRIBUTES } =
      CREATE_SURVEY_FIELD_NAMES;

    return http.get(`${API_ATTRIBUTES}${attributeId}/`).then(({ data }) => {
      history.push('/surveys', {
        surveyData: {
          [SURVEY_TYPE]: isSelf ? SELF : PEER,
          [CREATED_FOR]: Array.of(reformatUserForSurvey(user)) || [],
          [REVIEWERS]:
            auth.id === user.id ? [] : Array.of(reformatUserForSurvey(auth)),
          [ATTRIBUTES]: Array.of(reformatAttributeForSurvey(data)) || [],
        },
      });
    });
  };

  handleAddJobTitle = () => {
    const { history } = this.props;

    history.push('/job-titles');
  };

  handleAddLevel = () => {
    const { history } = this.props;

    history.push('/levels-and-tracks');
  };

  onRedirectToCompareReport = (isSelfCompare = false) => {
    const { history, user } = this.props;
    const [, Self] = GROUPED_SURVEY_REPORTING_TYPES;

    history.push('/reports/compare', {
      user,
      ...(isSelfCompare ? { surveyType: Self } : {}),
    });
  };

  hasCompareReportButtonCheck = isAdmin => attributes =>
    isAdmin && hasQuantitativeAttributeScores(attributes);

  handleOpenCareerInfoForm = () => this.setState({ isDrawerOpened: true });

  handleCloseCareerInfoForm = () =>
    this.setState({
      isDrawerOpened: false,
    });

  handleSaveCareerInfo = values => {
    const {
      translations,
      user,
      updateEmploymentInfo,
      getPersonJobTitle,
      getMeasuredAttributes,
    } = this.props;
    const data = {
      position: values.jobTitle,
      track: values.track,
      track_level: values.level,
    };

    return updateEmploymentInfo(user.id, data).then(async () => {
      if (values.jobTitle) {
        await getPersonJobTitle(values.jobTitle);
        getMeasuredAttributes(user.id);
      }
      showSuccessMessage(translations.careerInfoSuccess);
    }, this.handleCloseCareerInfoForm);
  };

  render() {
    const {
      classes,
      translations,
      jobTitle,
      measuredAttributes,
      jobTitles,
      auth,
      user,
      tracks,
    } = this.props;
    const { isLoading, isDrawerOpened } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isModerator = checkUserRole(auth.role, ROLES.MODERATOR);
    const canEdit = isAdmin || Boolean(auth.reporters[user.id]);
    const tracksWithLevels = prepareTracksWithLevels(tracks);
    const currentUserTrack = tracksWithLevels.find(
      track => track.id === user.track
    );

    const hasAddJobTitleButton = isAdmin && isArrayEmpty(jobTitles);
    const hasAssignJobTitleButton =
      (canEdit || (user.id !== auth.id && isAdmin)) && !isArrayEmpty(jobTitles);
    const hasSendSurveyButton =
      isAdmin ||
      (isModerator && isEmployeeInReporters(user.id, auth.reporters));
    const hasAddLevelButton = isAdmin && isArrayEmpty(tracksWithLevels);
    const hasAssignLevelButton =
      (canEdit || (isAdmin && user.id !== auth.id)) &&
      !isArrayEmpty(tracksWithLevels);

    return (
      <div>
        {!isLoading && (
          <>
            <Typography className={classes.description} variant="body2">
              {translations.description}
            </Typography>
            <CareerPerformance
              translations={translations.careerPerformance}
              jobTitle={jobTitle}
              hasAddJobTitleButton={hasAddJobTitleButton}
              hasAssignJobTitleButton={hasAssignJobTitleButton}
              hasCompareReportButtonCheck={this.hasCompareReportButtonCheck(
                isAdmin
              )}
              hasSendSurveyButton={hasSendSurveyButton}
              isUserSuspended={isUserSuspended(user)}
              onRedirectToCompareReport={this.onRedirectToCompareReport}
              onAddJobTitle={this.handleAddJobTitle}
              onAssignJobTitle={this.handleOpenCareerInfoForm}
              onSendSurvey={this.handleSendSurvey}
              {...measuredAttributes}
            />
            <CareerProgress
              className={classes.progress}
              translations={translations.careerProgress}
              currentLevelId={user.track_level}
              levels={getTrackLevels(user.track_level, currentUserTrack)}
              hasAddLevelButton={hasAddLevelButton}
              hasAssignLevelButton={hasAssignLevelButton}
              isUserSuspended={isUserSuspended(user)}
              onAssignLevel={this.handleOpenCareerInfoForm}
              onAddLevel={this.handleAddLevel}
            />
          </>
        )}
        <CustomFormDrawer
          isOpened={isDrawerOpened}
          translations={translations.addUserCareerForm}
          fields={getCareerInfoFields(!user.position, !user.track_level)}
          jobTitles={jobTitles}
          tracks={tracksWithLevels}
          onClose={this.handleCloseCareerInfoForm}
          onSave={this.handleSaveCareerInfo}
        />
      </div>
    );
  }
}

PeopleCareerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  auth: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  history: PropTypes.object.isRequired,
  jobTitle: PropTypes.shape({}).isRequired,
  measuredAttributes: PropTypes.shape({
    core: PropTypes.arrayOf(PropTypes.object),
    niche: PropTypes.arrayOf(PropTypes.object),
    selfCore: PropTypes.arrayOf(PropTypes.object),
    selfNiche: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getJobTitles: PropTypes.func.isRequired,
  getLevelsAndTracks: PropTypes.func.isRequired,
  getPersonJobTitle: PropTypes.func.isRequired,
  getMeasuredAttributes: PropTypes.func.isRequired,
  updateEmploymentInfo: PropTypes.func.isRequired,
  clearJobTitles: PropTypes.func.isRequired,
  clearLevelsAndTracks: PropTypes.func.isRequired,
  clearPersonCareer: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeopleCareerPage);
