import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import OrganizationSignUpFormLayout from './layout';

import { personalInformation, companyInfo } from './fields';
import { API_DOMAIN, createCustomHttpClient } from '../../../utility/http';
import { API_CREATE_ORGANIZATION } from '../../../constants/apiRoutes';
import { trimString, handleCustomHttpError } from '../../../utility/helpers';

const TEXT = 'text';

const OrganizationSignUp = withFormik({
  mapPropsToValues: props => {
    const price = props.priceId ? { price_id: props.priceId } : {};
    return {
      first_name: '',
      last_name: '',
      email: '',
      domain_name: '',
      ...price,
    };
  },
  enableReinitialize: true,
  validate: (values, props) => {
    const errors = {};
    const fields = Array.of(...personalInformation, ...companyInfo);

    // go through all fields
    fields.forEach(field => {
      if (field.validators && field.validators.length) {
        // go through all validators for a field
        for (let i = 0; i < field.validators.length; i += 1) {
          const isValid =
            field.type === TEXT && values[field.name] !== null
              ? field.validators[i].validator(trimString(values[field.name]))
              : field.validators[i].validator(values[field.name]);
          if (!isValid && (field.required || values[field.name])) {
            // only store if it's invalid or required
            errors[field.name] =
              props.translations.fieldValidations[field.label][
                field.validators[i].type
              ];
            // the first one that's not passing the check is being returned
            break;
          }
        }
      }
    });
    return errors;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { translations } = props;
    const { confirmPassword, ...other } = values;
    const port =
      window.location.port.length > 0 ? `:${window.location.port}` : '';
    const { protocol } = window.location;
    const organizationUrl = `${protocol}//${values.domain_name}.${
      API_DOMAIN.split(':')[0]
    }${port}`;

    createCustomHttpClient()
      .post(API_CREATE_ORGANIZATION, {
        ...other,
        client_url: `${window.location.origin}/sign-up/second-step`,
        success_url: `${organizationUrl}/login`,
        cancel_url: `${organizationUrl}/login`,
      })
      .then(() => {
        setSubmitting(false);
        props.history.push(
          '/sign-up/check-your-email/registration-link-has-been-sent'
        );
      })
      .catch(error => {
        setSubmitting(false);
        handleCustomHttpError(error, translations.somethingWentWrong);
      });
  },
})(OrganizationSignUpFormLayout);

OrganizationSignUp.propTypes = {
  translations: PropTypes.object.isRequired,
};

export default OrganizationSignUp;
