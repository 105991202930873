import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  transformSurveyData,
  prepareReviewParticipants,
  prepareSurveyAttributes,
} from '../../utility/reportUtils';
import {
  api_review_feedback,
  api_survey,
  api_survey_reviewers,
  api_review_results,
  api_text_reply_review_results,
  api_review_participants_info,
  api_survey_attributes,
  api_survey_shared_to,
} from '../../constants/apiRoutes';
import { DEFAULT_PARAMS } from '../../components/pages/surveySettingsPage/config';
// ------------------------------------
// Constants
// ------------------------------------
const SET_SURVEY = 'SET_SURVEY';
const SET_REVIEWERS = 'SET_REVIEWERS';
const SET_REVIEW_RESULTS = 'SET_REVIEW_RESULTS';
const SET_REVIEW_FEEDBACK = 'SET_REVIEW_FEEDBACK';
const SET_REVIEW_PARTICIPANTS = 'SET_REVIEW_PARTICIPANTS';
const SET_SURVEY_ATTRIBUTES = 'SET_SURVEY_ATTRIBUTES';
const SET_SURVEY_SHARED_WITH = 'SET_SURVEY_SHARED_WITH';

const initialState = {
  survey: {},
  reviewers: {},
  reviewResults: {},
  reviewFeedback: [],
  participants: [],
  attributes: [],
  sharedWith: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setSurvey = createAction(SET_SURVEY);
const setReviewers = createAction(SET_REVIEWERS);
const setReviewResults = createAction(SET_REVIEW_RESULTS);
const setReviewFeedback = createAction(SET_REVIEW_FEEDBACK);
const setReviewParticipants = createAction(SET_REVIEW_PARTICIPANTS);
const setSurveyAttributes = createAction(SET_SURVEY_ATTRIBUTES);
const setSurveySharedWith = createAction(SET_SURVEY_SHARED_WITH);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEY]: (state, { payload }) => {
    return { ...state, survey: transformSurveyData(payload) };
  },
  [SET_REVIEWERS]: (state, { payload }) => {
    if (payload.isLoadMore) {
      return {
        ...state,
        reviewers: {
          ...payload.data,
          results: [...state.reviewers.results, ...payload.data.results],
        },
      };
    }
    return { ...state, reviewers: payload.data };
  },
  [SET_REVIEW_RESULTS]: (state, { payload }) => {
    return {
      ...state,
      reviewResults: payload,
    };
  },
  [SET_REVIEW_PARTICIPANTS]: (state, { payload }) => {
    return {
      ...state,
      participants: prepareReviewParticipants(state.survey.type, payload),
    };
  },
  [SET_REVIEW_FEEDBACK]: (state, { payload }) => {
    return {
      ...state,
      reviewFeedback: payload,
    };
  },
  [SET_SURVEY_ATTRIBUTES]: (state, { payload }) => {
    return { ...state, attributes: prepareSurveyAttributes(payload) };
  },
  [SET_SURVEY_SHARED_WITH]: (state, { payload }) => {
    return {
      ...state,
      sharedWith: payload,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getSurvey = (dispatch, surveyId) => {
  return http.get(api_survey(surveyId)).then(({ data }) => {
    dispatch(setSurvey(data));
    return data;
  });
};

export const editSurvey = (dispatch, surveyId, updatedSurvey) => {
  return http.patch(api_survey(surveyId), updatedSurvey).then(({ data }) => {
    dispatch(setSurvey(data));
  });
};

export const getSurveyAttributes = (dispatch, surveyId) => {
  return http.get(api_survey_attributes(surveyId)).then(({ data }) => {
    dispatch(setSurveyAttributes(data));
  });
};

export const getReviewResults = (dispatch, surveyId, attributeId) => {
  return http
    .get(api_review_results(surveyId, attributeId))
    .then(({ data }) => {
      dispatch(setReviewResults(data));
    });
};

export const getReviewParticipants = (dispatch, surveyId, attributeId) => {
  return http
    .get(api_review_participants_info(surveyId, attributeId))
    .then(({ data }) => {
      dispatch(setReviewParticipants(data));
    });
};

export const getTextReplyReviewResults = (dispatch, surveyId, attributeId) => {
  return http
    .get(api_text_reply_review_results(surveyId, attributeId))
    .then(({ data }) => {
      dispatch(setReviewResults(data));
    });
};

export const getReviewers = (
  dispatch,
  surveyId,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(api_survey_reviewers(surveyId), {
      params: { ...DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setReviewers({ data, isLoadMore }));
    });
};

export const getReviewFeedback = (dispatch, surveyId, attributeId) => {
  return http
    .get(api_review_feedback(surveyId, attributeId))
    .then(({ data }) => {
      dispatch(setReviewFeedback(data));
    });
};

export const getSharedWith = (dispatch, surveyId) => {
  return http.get(api_survey_shared_to(surveyId)).then(({ data }) => {
    dispatch(setSurveySharedWith(data));
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearSurvey = () => setSurvey(initialState.survey);
export const clearReviewers = () =>
  setReviewers({ data: initialState.reviewers });
export const clearReviewFeedback = () =>
  setReviewFeedback(initialState.reviewFeedback);
export const clearReviewResults = () =>
  setReviewResults(initialState.reviewResults);
export const clearReviewParticipants = () =>
  setReviewParticipants(initialState.participants);
export const clearSurveyAttributes = () => setSurvey(initialState.attributes);
export const clearSharedWith = () =>
  setSurveySharedWith(initialState.sharedWith);
