import { connect } from 'react-redux';
import OneOnOneReportPage from '../../components/pages/oneOnOneReportPage';
import PageContainer from '../../components/shared/pageContainer';
import { oneOnOneReportSelector } from '../../store/selectors/oneOnOneReportSelector';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import {
  getOneOnOneReport,
  clearOneOnOneReport,
} from '../../store/modules/oneOnOneReport';
import { clearAllUsers, getAllUsers } from '../../store/modules/surveys';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  allUsers: [...surveysSelector(state).allUsers],
  formattedUsers: { ...surveysSelector(state).formattedUsers },
  ...oneOnOneReportSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getOneOnOneReport: (isLoadMore, data) =>
    getOneOnOneReport(dispatch, isLoadMore, data),
  getAllUsers: data => getAllUsers(dispatch, data),
  clearAllUsers: () => dispatch(clearAllUsers()),
  clearOneOnOneReport: () => dispatch(clearOneOnOneReport()),
});

const OneOnOneReportPageView = props => {
  const translations = useTranslations(APP_PAGES.ONE_ON_ONE_REPORT);

  return (
    <PageContainer {...props} translations={translations}>
      <OneOnOneReportPage />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneOnOneReportPageView);
