import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import TransferEmployeesDialog from '../../shared/transferEmployeesDialog';
import EmployeeTags from '../../shared/employeeTags';
import CustomButton from '../../shared/customButton';
import AlertDialog from '../../shared/alertDialog';
import ManageUserTagsDialog from '../../shared/manageUserTagsDialog';
import EmployeeShare from '../../shared/employeeShare';
import EmployeeShareDialog from '../../shared/employeeShareDialog';
import VisibleForPermission from '../../shared/visibleForPermission';
import SharedIcon from '../../shared/sharedIcon';
import UserAvatar from '../../shared/userAvatar';
import CustomFormDrawer from '../../shared/customFormDrawer';
import http from '../../../utility/http';
import {
  isEmployeeInReporters,
  parseDuplicateParameters,
  showSuccessMessage,
  getPersonFullName,
  prepareTracksWithLevels,
  capitalize,
  sortAlphabetically,
} from '../../../utility/uiUtils';
import { formatDate } from '../../../utility/dateUtils';
import {
  updateAuthData,
  checkUserRole,
  objectHasProperty,
  isArrayEmpty,
  isObjectEmpty,
  canSeeEmployeeProfile,
  getItemById,
} from '../../../utility/helpers';
import { tagManagerDataLayer } from '../../../utility/tagManager';
import {
  getCategoriesWithTags,
  prepareUserTagCategories,
} from '../../../utility/tagUtils';
import {
  API_ORG_CHART,
  api_user,
  API_USERS_BASIC,
  API_USERS,
  api_user_share,
  api_user_shared_with,
} from '../../../constants/apiRoutes';
import {
  ROLES,
  ACCOUNT_TYPES,
  PERMISSIONS,
  EMPLOYEE_ROLES_LIST,
} from '../../../constants/rolesAndPermissionList';
import { UserStatuses } from '../../../constants/statuses';
import {
  USER_INFO,
  USER_INFO_KEYS,
  PEOPLE_DEFAULT_ORDERING,
} from '../../../constants/people';
import { PARAMS } from '../../../constants/pages';
import { ellipsis, sticky } from '../../../constants/helperCssRules';
import {
  getInitialUserData,
  getEditUserFields,
  getUserRoles,
} from '../peoplePage/config';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    paddingBottom: spacing(4),
    ...sticky(primary.white, 169),
  },
  statusWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  status: {
    display: 'flex',
    alignContent: 'center',
  },
  statusInfo: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: spacing(3),
  },
  icon: {
    marginLeft: spacing(2),
  },
  infoWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
    gridColumnGap: 16,
    marginBottom: spacing(10),
  },
  sectionTitle: {
    marginBottom: spacing(4),
  },
  sectionWrap: {
    marginBottom: spacing(4),
  },
  sectionField: {
    marginBottom: spacing(1),
    color: primary.bluish3,
  },
  sectionData: {
    ...ellipsis(),
    maxWidth: 330,
  },
  pointer: {
    cursor: 'pointer',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '20px',
  },
});

const DATE_FORMAT = 'y-MM-dd';
const RESEND = 'RESEND';
const STYLED_FORMAT = 'dd MMM y';

const {
  FIRST_NAME,
  LAST_NAME,
  REPORT_TO,
  JOB_TITLE,
  ROLE,
  TRACK,
  TRACK_LEVEL,
  STATUS,
  EMPLOYMENT_DATE,
  POSITION,
} = USER_INFO;
const [Idle, Invited, Activated, Suspended] = UserStatuses;

class PeopleProfilePage extends PureComponent {
  state = {
    isLoading: true,
    mentor: null,
    usersToTransfer: [],
    organizationMentor: null,
    updatedUserData: {},
    sharedWith: [],
    isEditUserDialogOpened: false,
    isDeleteUserDialogOpened: false,
    isTransferUsersDialogOpened: false,
    isReportToDisabled: false,
    availableManagers: [],
    isDeleteUser: false,
  };

  componentDidMount() {
    const { getJobTitles, getTagsCategories, user, getLevelsAndTracks } =
      this.props;
    tagManagerDataLayer(
      'User Profile General Info',
      'Open Page',
      'upGeneralInfoOpenPage'
    );

    Promise.all([
      getTagsCategories(),
      getJobTitles(),
      getLevelsAndTracks(),
      this.getOrganizationMentor(),
      this.getUserSharedWith(),
    ]).then(async () => {
      const { organizationMentor } = this.state;
      const [availableManagers] = await Promise.all([
        this.getAvailableManagers(),
        this.getMentor(user.report_to, user.report_to_organization),
      ]);

      this.setState({
        isLoading: false,
        isReportToDisabled: user[STATUS] === Suspended.id,
        availableManagers: [organizationMentor].concat(availableManagers.data),
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      user: { id, position, report_to, first_name, last_name },
      auth,
      updateCurrentUserInfo,
    } = this.props;
    const isUser = checkUserRole(auth[ROLE], ROLES.USER);

    if (prevProps.user[REPORT_TO] !== report_to) {
      this.getMentor(report_to, false);
    }

    if (
      (!isUser &&
        ((prevProps.user[REPORT_TO] === auth.id && report_to === null) ||
          (prevProps.user[REPORT_TO] === null && report_to === auth.id) ||
          this.isEmployeeInReportersList(
            auth.accessibleProfiles,
            prevProps.user[REPORT_TO]
          ) !==
            this.isEmployeeInReportersList(
              auth.accessibleProfiles,
              report_to
            ))) ||
      (prevProps.user[FIRST_NAME] !== first_name && auth.id === id) ||
      (prevProps.user[POSITION]?.id !== position?.id && auth.id === id) ||
      (prevProps.user[LAST_NAME] !== last_name && auth.id === id)
    ) {
      updateAuthData(updateCurrentUserInfo);
    }
  }

  componentWillUnmount() {
    const { clearTagsCategories, clearJobTitles, clearLevelsAndTracks } =
      this.props;

    clearTagsCategories();
    clearJobTitles();
    clearLevelsAndTracks();
  }

  handleRedirectToPeoplePage = userData => {
    const { history, auth } = this.props;
    const isModerator = checkUserRole(auth[ROLE], ROLES.MODERATOR);
    const isSuspended = userData[STATUS] === Suspended.id;
    const isReportToInReporters = this.isEmployeeInReportersList(
      auth.reporters,
      userData[REPORT_TO]
    );

    if ((isSuspended || !isReportToInReporters) && isModerator) {
      history.push('/people');
    }
  };

  getUserSharedWith = () => {
    const { user, auth } = this.props;
    const isAdmin = checkUserRole(auth[ROLE], ROLES.ADMIN);
    const isManager = checkUserRole(auth[ROLE], ROLES.MODERATOR);
    const isAccessible =
      isManager && isEmployeeInReporters(user.id, auth.reporters);
    if (isAccessible || isAdmin) {
      return http.get(api_user_shared_with(user.id)).then(({ data }) => {
        this.setState({
          sharedWith: data,
        });
      });
    }

    return Promise.resolve([]);
  };

  onUpdateSharedWith = async targetUsers => {
    const { user } = this.props;
    const result = await http.put(api_user_share(user.id), targetUsers);
    this.setState({
      sharedWith: result.data,
    });
  };

  handleSharePersonChange = value => {
    const { user } = this.props;

    const params = {
      page: 1,
      page_size: 12,
      ordering: 'first_name',
      search: value,
      status: [Idle.id, Invited.id, Activated.id],
      available_managers: user.id,
      role: EMPLOYEE_ROLES_LIST,
    };

    if (value) {
      return http
        .get(API_USERS, {
          params,
          paramsSerializer: data => parseDuplicateParameters(data),
        })
        .then(({ data }) => data.results || []);
    }

    return Promise.resolve([]);
  };

  isEmployeeInReportersList = (reporters, employeeId) => {
    const {
      auth: { id },
    } = this.props;
    const visibleEmployees = { ...reporters, [id]: true };

    if (!employeeId) return false;
    return objectHasProperty(visibleEmployees, employeeId);
  };

  gtmOnEditPersonalInformation = () => {
    tagManagerDataLayer(
      'User Profile General Info',
      'Edit Personal Information',
      'upGeneralInfoEditPersonalInformation'
    );
  };

  toggleTransferUsersDialog = (
    usersToTransfer = [],
    isDeleteUser = false,
    updatedUserData = {}
  ) =>
    this.setState(prevState => ({
      isTransferUsersDialogOpened: !prevState.isTransferUsersDialogOpened,
      usersToTransfer,
      isDeleteUser,
      updatedUserData,
    }));

  toggleDeleteUserDialog = () =>
    this.setState(prevState => ({
      isDeleteUserDialogOpened: !prevState.isDeleteUserDialogOpened,
    }));

  handleOpenDeleteUserDialog = async () => {
    const { user } = this.props;
    const isSuspendedUser = user?.[USER_INFO.STATUS] === Suspended.id;

    if (!isSuspendedUser) {
      const reporters = await this.checkUsersDirectReports(user.id);

      if (!isArrayEmpty(reporters)) {
        return this.toggleTransferUsersDialog(reporters, true);
      }

      return this.toggleDeleteUserDialog();
    }

    return this.toggleDeleteUserDialog();
  };

  handleDeleteUser = () => {
    const { history, translations, user } = this.props;

    return http.delete(api_user(user.id)).then(() => {
      showSuccessMessage(translations.deleteUserDialog.deleteSuccess);
      history.push('/people');
    });
  };

  handleTransferAndDelete = () => {
    const { history, translations, user } = this.props;
    const { isDeleteUser, updatedUserData } = this.state;

    if (isDeleteUser) {
      return this.handleDeleteUser().then(() => {
        showSuccessMessage(translations.deleteUserDialog.deleteSuccess);
        history.push('/people');
      });
    }

    return this.saveUserInfoChanges(user.id, updatedUserData);
  };

  saveUserInfoChanges = (userId, updatedData) => {
    const { updateEmploymentInfo, translations } = this.props;
    return updateEmploymentInfo(userId, updatedData).then(() => {
      this.gtmOnEditPersonalInformation();
      this.handleRedirectToPeoplePage(updatedData);
      tagManagerDataLayer(
        'User Profile General Info',
        'Edit Permissions',
        'upGeneralInfoEditPermissions'
      );
      showSuccessMessage(translations.successUpdate);
    });
  };

  onUpdateUser = async values => {
    const { user } = this.props;
    const userData = {};

    USER_INFO_KEYS.forEach(key => {
      if (user[key] !== values[key]) {
        if (key === ROLE) {
          if (user[key] !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        if (key === EMPLOYMENT_DATE && values[key]) {
          const formattedDate = formatDate(values[key], DATE_FORMAT);
          if (user[key] !== formattedDate) {
            userData[key] = formattedDate;
          }
          return;
        }
        if (key === REPORT_TO) {
          if (values[key] && user[key] !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        if (key === JOB_TITLE) {
          if (user[key]?.id !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        if (key === STATUS && values[key] === RESEND) {
          userData[key] = Invited.id;
          return;
        }
        userData[key] = values[key];
      }
    });

    if (userData[STATUS] === Suspended.id) {
      const reporters = await this.checkUsersDirectReports(user.id);

      if (!isArrayEmpty(reporters)) {
        return this.toggleTransferUsersDialog(reporters, false, userData);
      }
      return this.saveUserInfoChanges(user.id, userData);
    } else if (!isObjectEmpty(userData)) {
      return this.saveUserInfoChanges(user.id, userData);
    }
    return Promise.resolve();
  };

  getAvailableManagers = () => {
    const { user } = this.props;

    return http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE,
        [PARAMS.EXCLUDE_STATUS]: Suspended.id,
        [PARAMS.AVAILABLE_MANAGERS]: user.id,
      },
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  };

  getUserStatuses = (isIdle, isInvited, isSuspended) => {
    const { translations, user } = this.props;

    return [
      {
        name: translations.statuses[user.status],
        value: user[STATUS],
        action: () => {
          this.setState({ isReportToDisabled: isSuspended });
        },
      },
      ...(isSuspended
        ? [
            {
              action: () => {
                this.setState({ isReportToDisabled: false });
              },
              name: translations.statuses[Idle.id],
              id: translations.statuses[Idle.id],
              value: Idle.id,
            },
          ]
        : []),
      ...(isIdle
        ? [
            {
              shouldItemRender: () => isIdle,
              action: () => {
                this.setState({ isReportToDisabled: false });
              },
              name: translations.invite,
              value: Invited.id,
            },
          ]
        : []),
      ...(isInvited
        ? [
            {
              action: () => {
                this.setState({ isReportToDisabled: false });
              },
              name: translations.resendInvite,
              value: RESEND,
            },
          ]
        : []),
      ...(!isSuspended
        ? [
            {
              shouldItemRender: () => !isSuspended,
              action: () => {
                this.setState({ isReportToDisabled: true });
              },
              name: translations.deactivate,
              value: Suspended.id,
            },
          ]
        : []),
    ];
  };

  checkUsersDirectReports = async userId => {
    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [REPORT_TO]: userId,
      },
    });

    return data;
  };

  getMentor = (id, reportToOrganization) => {
    const { user } = this.props;
    const { organizationMentor } = this.state;
    const isUserSuspended = user[STATUS] === Suspended.id;

    if (isUserSuspended) {
      this.setState({ mentor: null });

      return Promise.resolve();
    }

    if (id && !reportToOrganization && id !== organizationMentor?.id) {
      return http.get(api_user(id)).then(response => {
        this.setState({ mentor: response.data });
      });
    }

    this.setState({ mentor: organizationMentor });

    return Promise.resolve();
  };

  getOrganizationMentor = () => {
    const { translations } = this.props;

    return http.get(API_ORG_CHART).then(({ data }) => {
      this.setState({
        organizationMentor: {
          ...data,
          last_name: translations.organization,
          isCeo: true,
        },
      });
    });
  };

  handleEditTagsSave = updatedTags => {
    const { translations, setDialogVisibility, updateEmploymentInfo, user } =
      this.props;
    setDialogVisibility({
      dialogName: 'addUserTagsDialog',
      opened: false,
    });
    updateEmploymentInfo(user.id, updatedTags).then(() => {
      tagManagerDataLayer(
        'User Profile General Info',
        'Edit Tags',
        'upGeneralInfoEditTags'
      );
      showSuccessMessage(translations.manageUserTagsDialog.successMessage);
    });
  };

  handleMentorClick = () => {
    const { history } = this.props;
    const { mentor } = this.state;

    history.push(`/people/${mentor.id}`);
  };

  onAddTagsButton = () => {
    const { setDialogVisibility } = this.props;
    return setDialogVisibility({
      dialogName: 'addUserTagsDialog',
      opened: true,
    });
  };

  handleCloseEditSidebar = () => {
    const { user } = this.props;

    this.setState({
      isEditUserDialogOpened: false,
      isReportToDisabled: user[STATUS] === Suspended.id,
    });
  };

  getReadModeUserData = () => {
    const { user, jobTitles, tracks } = this.props;
    const { mentor } = this.state;
    const userTrack = tracks.find(track => track.id === user.track);
    const roleName =
      user.role && ACCOUNT_TYPES.find(role => role.value === user.role).label;

    return {
      ...user,
      [JOB_TITLE]:
        jobTitles.find(jTitle => jTitle.id === user?.position?.id)?.name || '',
      [TRACK]: userTrack?.name || '',
      [TRACK_LEVEL]:
        userTrack?.track_levels.find(trLvl => trLvl?.id === user.track_level)
          ?.name || '',
      [REPORT_TO]: mentor,
      userName: getPersonFullName(user),
      [ROLE]: capitalize(roleName),
      [EMPLOYMENT_DATE]:
        user[EMPLOYMENT_DATE] &&
        formatDate(new Date(user[EMPLOYMENT_DATE]), STYLED_FORMAT),
    };
  };

  renderSection = (sectionData, isMentorClickable = false) => {
    const { classes } = this.props;
    const { organizationMentor } = this.state;
    const preparedUserData = this.getReadModeUserData();
    return (
      <div>
        <Typography variant="h5" className={classes.sectionTitle}>
          {sectionData.title}
        </Typography>
        {Object.keys(sectionData.fields).map(fieldKey => {
          const isReportToField = fieldKey === REPORT_TO;
          const user = preparedUserData[fieldKey];
          return (
            <div key={fieldKey} className={classes.sectionWrap}>
              <Typography variant="body2" className={classes.sectionField}>
                {sectionData.fields[fieldKey]}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                className={classNames({
                  [classes.sectionData]: !isReportToField,
                  [classes.pointer]: isReportToField && isMentorClickable,
                })}
                onClick={
                  isReportToField && isMentorClickable
                    ? this.handleMentorClick
                    : () => {}
                }
              >
                {isReportToField ? (
                  <>
                    {user ? (
                      <UserAvatar
                        labelClass={classes.userLabel}
                        user={user}
                        isOrganizationUser={organizationMentor?.id === user.id}
                        clickableCaption={isMentorClickable}
                        onClickHandler={this.handleMentorClick}
                        small
                        caption
                      />
                    ) : (
                      preparedUserData[fieldKey] || '-'
                    )}
                  </>
                ) : (
                  preparedUserData[fieldKey] || '-'
                )}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      classes,
      jobTitles,
      categories,
      translations,
      user,
      dialogs,
      setDialogVisibility,
      grantedPermissions,
      auth,
      tracks,
    } = this.props;
    const {
      mentor,
      organizationMentor,
      isLoading,
      usersToTransfer,
      isReportToDisabled,
      isDeleteUser,
      sharedWith,
      isEditUserDialogOpened,
      isDeleteUserDialogOpened,
      isTransferUsersDialogOpened,
      availableManagers,
    } = this.state;
    const { addUserTagsDialogOpened, employeeShareDialogOpened } = dialogs;
    const { USER, ADMIN } = ROLES;
    const isUser = checkUserRole(auth[ROLE], USER);
    const isAdmin = checkUserRole(auth[ROLE], ADMIN);
    const isOwnProfile = auth.id === user.id;
    const canEditProfile = isAdmin || Boolean(auth.reporters[user.id]);
    const roles = getUserRoles(translations, isAdmin);
    const isMentorClickable =
      user.report_to &&
      canSeeEmployeeProfile(auth, user.report_to) &&
      !user.report_to_organization;
    const isUserSuspended = user[STATUS] === Suspended.id;
    const isUserIdle = user[STATUS] === Idle.id;
    const isUserInvited = user[STATUS] === Invited.id;
    const userTags = sortAlphabetically(user.tags);

    return !isLoading ? (
      <div>
        <Typography className={classes.description} variant="body2">
          {isOwnProfile
            ? translations.descriptionOwnProfile
            : translations.description}
        </Typography>
        <div className={classes.statusWrapper}>
          <div className={classes.status}>
            <Typography variant="body2">{translations.statusLabel}</Typography>
            <div className={classes.statusInfo}>
              <Typography variant="subtitle2">
                {translations.statuses[user.status]}
              </Typography>
              <SharedIcon
                customIconWrapperClass={classes.icon}
                icon={getItemById(UserStatuses, user[STATUS])?.icon}
                showIcon
              />
            </div>
          </div>
          <VisibleForPermission
            permission={PERMISSIONS.canEditGeneralInfo}
            permissions={grantedPermissions}
            disableOnly={!canEditProfile || (isOwnProfile && isUserSuspended)}
          >
            <CustomButton
              onClick={() => this.setState({ isEditUserDialogOpened: true })}
              type="withTextLightRounded"
            >
              {translations.edit}
            </CustomButton>
          </VisibleForPermission>
        </div>
        <div className={classes.infoWrapper}>
          {this.renderSection(translations.personalInformation)}
          {this.renderSection(
            translations.employmentInformation,
            isMentorClickable
          )}
          {/* PeopleRolesAndPermissions should only be visible for admin or manager */}
          {!isUser && this.renderSection(translations.rolesAndPermissions)}
        </div>
        {canEditProfile && !isUser && (
          <EmployeeShare
            translations={translations.employeeShareSection}
            isOpened={dialogs.employeeUnshareDialogOpened}
            grantedPermissions={grantedPermissions}
            user={user}
            sharedWith={sharedWith}
            setDialogVisibility={setDialogVisibility}
            onDeleteItem={this.onUpdateSharedWith}
            onClickShareButton={() => {
              setDialogVisibility({
                dialogName: 'employeeShareDialog',
                opened: true,
              });
            }}
          />
        )}
        <EmployeeTags
          translations={translations}
          grantedPermissions={grantedPermissions}
          currentUser={auth}
          user={user}
          categories={prepareUserTagCategories(categories, userTags)}
          onAddButton={this.onAddTagsButton}
        />
        <ManageUserTagsDialog
          translations={translations.manageUserTagsDialog}
          selectedTags={userTags}
          categories={getCategoriesWithTags(categories)}
          isOpened={addUserTagsDialogOpened}
          onCancel={() => {
            setDialogVisibility({
              dialogName: 'addUserTagsDialog',
              opened: false,
            });
          }}
          onSave={this.handleEditTagsSave}
        />
        {!isLoading && !isUser && (
          <CustomFormDrawer
            translations={translations}
            titleText={translations.editTitle}
            initialData={getInitialUserData(user, mentor)}
            isOpened={isEditUserDialogOpened}
            fields={getEditUserFields(user, auth, isReportToDisabled)}
            statuses={this.getUserStatuses(
              isUserIdle,
              isUserInvited,
              isUserSuspended
            )}
            positions={jobTitles}
            tracks={prepareTracksWithLevels(tracks)}
            roles={roles}
            availableManagers={availableManagers}
            hideDelete={isOwnProfile}
            onClose={this.handleCloseEditSidebar}
            onSave={this.onUpdateUser}
            onDelete={this.handleOpenDeleteUserDialog}
            hasCancelButton
            isInitialValid
          />
        )}
        {!isUser ? (
          <EmployeeShareDialog
            translations={translations.shareEmployeeDialog}
            isOpened={employeeShareDialogOpened}
            currentUser={user}
            onAutocompleteChange={this.handleSharePersonChange}
            sharedWith={sharedWith}
            onClose={() => {
              setDialogVisibility({
                dialogName: 'employeeShareDialog',
                opened: false,
              });
            }}
            onSave={this.onUpdateSharedWith}
          />
        ) : null}
        <TransferEmployeesDialog
          translations={translations.transferEmployeesDialog}
          isOpened={isTransferUsersDialogOpened}
          currentUserId={user?.id}
          users={usersToTransfer}
          organizationUser={organizationMentor}
          isDeleteUser={isDeleteUser}
          onClose={this.toggleTransferUsersDialog}
          onConfirm={this.handleTransferAndDelete}
        />
        <AlertDialog
          translations={translations.deleteUserDialog}
          isOpened={isDeleteUserDialogOpened}
          onClose={this.toggleDeleteUserDialog}
          onConfirm={this.handleDeleteUser}
          isWarning
        />
      </div>
    ) : null;
  }
}

PeopleProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  getJobTitles: PropTypes.func.isRequired,
  clearJobTitles: PropTypes.func.isRequired,
  getLevelsAndTracks: PropTypes.func.isRequired,
  clearLevelsAndTracks: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateEmploymentInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(PeopleProfilePage);
