import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as fields from './fields';
import InputField from '../inputField';
import CustomLink from '../customLink';

import { API_DOMAIN } from '../../../utility/http';
import CustomButton from '../customButton';
import CheckIcon from '../../../assets/icons/check-icon-green.svg';
import XIcon from '../../../assets/icons/x-icon-red.svg';
import { validateOrganizationUrl } from '../../../utility/validation';
import {
  objectHasProperty,
  trimString,
  handleCustomHttpError,
} from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    flexGrow: 1,
    maxWidth: 395,
  },
  title: {
    marginBottom: spacing(4),
  },
  sectionTitle: {
    marginBottom: spacing(3),
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  companyWrapper: {
    display: 'flex',
  },
  field: {
    maxWidth: 392,
    marginBottom: spacing(6),
  },
  hasError: {
    marginBottom: spacing(1),
  },
  success: {
    border: `1px solid ${primary.green2}`,
  },
  successText: {
    color: primary.green2,
    fontSize: 12,
    lineHeight: '12px',
    paddingTop: 4,
    paddingBottom: 4,
    textTransform: 'none',
    letterSpacing: 'normal',
    margin: 0,
  },
  siteUrl: {
    display: 'flex',
    width: '48.5%',
    marginLeft: spacing(2),
  },
  url: {
    paddingTop: spacing(9),
  },
  checkbox: {
    width: 30,
    height: 30,
    padding: 0,
  },
  icon: {
    width: 28,
    height: 28,
    border: `1px solid ${primary.bluish4}`,
    borderRadius: 5,
  },
  checkedIcon: {
    border: `1px solid ${primary.bluish4}`,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
  },
  check: {
    height: 16,
    width: 16,
  },
  termsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: primary.bluish2,
    fontFamily: 'ProximaNova-Regular',
    fontSize: 11,
    lineHeight: '13px',
    width: 395,
  },
  link: {
    fontSize: 11,
    color: primary.blue1,
    textDecoration: 'underline',
    padding: '1px 4px',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      color: primary.blue1,
      fontFamily: 'ProximaNova-Regular',
      cursor: 'pointer',
    },
  },
  statusIcon: {
    position: 'absolute',
    right: -32,
    marginTop: spacing(7.5),
  },
  submit: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    marginBottom: spacing(4),
  },
});

const sections = [{ name: 'personalInformation' }, { name: 'companyInfo' }];

const DOMAIN_NAME = 'domain_name';
const MAX_LENGTH = 50;
const DELAY = 300;
let timerId;

class OrganizationSignUpFormLayout extends PureComponent {
  state = {
    isValidUrl: false,
    domainChecking: false,
  };

  debounceFunction = () => {
    clearTimeout(timerId);
    timerId = setTimeout(this.validateUrl, DELAY);
  };

  validateUrl = () => {
    const {
      validateOrganizationName,
      setFieldError,
      translations,
      touched,
      values,
    } = this.props;
    const domainName = values.domain_name;
    const validateUrl =
      trimString(domainName).length <= MAX_LENGTH &&
      trimString(domainName).length > 0 &&
      validateOrganizationUrl(trimString(domainName));
    if (validateUrl) {
      if (!touched[DOMAIN_NAME]) {
        this.setState({ domainChecking: true });
      }
      return validateOrganizationName(domainName)
        .then(({ data }) => {
          this.setState({ isValidUrl: !data.exists, domainChecking: false });
          if (data.exists) {
            setFieldError(DOMAIN_NAME, translations.isTaken);
          }
        })
        .catch(error => {
          this.setState({ isValidUrl: false, domainChecking: false });
          handleCustomHttpError(error, translations.somethingWentWrong);
        });
    }
    this.setState({ isValidUrl: false });
  };

  handleDomainNameChange = async e => {
    const { setFieldTouched, setFieldValue, touched } = this.props;
    await setFieldValue(DOMAIN_NAME, e.target.value);
    if (!touched[DOMAIN_NAME]) {
      setFieldTouched(DOMAIN_NAME, true);
      this.setState({ domainChecking: true });
    }
    this.debounceFunction();
  };

  renderSection = section => {
    const { classes, translations, touched, values } = this.props;
    const { isValidUrl, domainChecking } = this.state;
    const isCompany = section === 'companyInfo';
    const wrapperClasses = classNames({
      [classes.wrapper]: isCompany,
    });
    return (
      <div key={section}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          {translations[section]}
        </Typography>
        <div className={wrapperClasses}>
          {fields[section].map(input => this.renderInput(input))}
          {isCompany && (
            <div className={classes.siteUrl}>
              <Typography className={classes.url}>.{API_DOMAIN}</Typography>
              {touched[DOMAIN_NAME] && !domainChecking && (
                <img
                  className={classes.statusIcon}
                  src={
                    isValidUrl && values.domain_name.length > 0
                      ? CheckIcon
                      : XIcon
                  }
                  alt="status"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderInput(field) {
    const {
      values,
      classes,
      errors,
      handleChange,
      handleBlur,
      touched,
      translations,
    } = this.props;
    const { isValidUrl } = this.state;

    const label = translations.fields[field.label];
    const isSuccess =
      field.name === DOMAIN_NAME &&
      touched[field.name] &&
      isValidUrl &&
      values.domain_name.length > 0;
    const hasError =
      objectHasProperty(errors, field.name) && touched[field.name];
    const fieldClasses = classNames(classes.field, {
      [classes.hasError]: hasError || isSuccess,
    });
    const successClass = classNames({
      [classes.success]: isSuccess,
    });
    return (
      <div key={field.name} className={fieldClasses}>
        <InputField
          id={field.id}
          type={field.type}
          autoComplete={field.autoComplete}
          name={field.name}
          disabled={field.isDisabled || false}
          onChange={
            field.name === DOMAIN_NAME
              ? e => this.handleDomainNameChange(e)
              : handleChange
          }
          onBlur={
            field.name === DOMAIN_NAME
              ? e => {
                  handleBlur(e);
                  this.validateUrl();
                }
              : handleBlur
          }
          value={values[field.name] || ''}
          fullWidth
          label={label}
          error={hasError}
          errorMessage={errors[field.name]}
          customRootClass={successClass}
        />
        {isSuccess && (
          <FormHelperText classes={{ root: classes.successText }}>
            {translations.successText}
          </FormHelperText>
        )}
      </div>
    );
  }

  renderTermsAndPolicy() {
    const { classes, isSubmitting, history, translations, errors } = this.props;
    const { isValidUrl } = this.state;
    const { CREATE_ACCOUNT_BUTTON } = AUTOMATION_ID;

    return (
      <div>
        <CustomButton
          id={CREATE_ACCOUNT_BUTTON}
          className={classes.submit}
          submit
          type="withTextDarkRounded"
          disabled={
            isSubmitting || !isValidUrl || Boolean(Object.keys(errors).length)
          }
        >
          {translations.create}
        </CustomButton>
        <div className={classes.termsWrapper}>
          {translations.agree}
          <CustomLink
            className={classes.link}
            text={translations.termsOfService}
            handleClick={() => history.push('/terms-of-service/')}
          />
          {translations.and}
          <CustomLink
            className={classes.link}
            text={translations.privacyPolicy}
            handleClick={() => history.push('/privacy-policy')}
          />
        </div>
      </div>
    );
  }

  render() {
    const { classes, handleSubmit, translations } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classes.main}>
        <Typography variant="h1" className={classes.title}>
          {translations.title}
        </Typography>
        {sections.map(section => this.renderSection(section.name))}
        {this.renderTermsAndPolicy()}
      </form>
    );
  }
}

OrganizationSignUpFormLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  validateOrganizationName: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganizationSignUpFormLayout);
