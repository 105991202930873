import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { validateOrganizationName } from '../../store/modules/signUpResults';
import { loadingSelector } from '../../store/selectors/loadingSelector';
import SignUpFirstStepPage from '../../components/pages/signUpFirstStepPage';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...loadingSelector(state),
});

const mapDispatchToProps = () => ({
  validateOrganizationName: name => validateOrganizationName(name),
});

const SignUpFirstStepPageView = props => {
  const { priceId } = useParams();
  const translations = useTranslations(APP_PAGES.SIGN_UP);

  return (
    <SignUpFirstStepPage
      {...props}
      translations={translations}
      priceId={priceId}
    />
  );
};

SignUpFirstStepPageView.propTypes = {
  validateOrganizationName: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpFirstStepPageView);
