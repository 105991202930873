import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PageContainer from '../pageContainer';
import AvatarUploadZone from '../avatarUploadZone';
import ShareTypeBox from '../shareTypeBox';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ArrowTooltip from '../arrowTooltip';
import { updateCurrentUserInfo } from '../../../store/modules/auth';
import { ReactComponent as LogoSlack } from '../../../assets/icons/slack-no-text.svg';

import {
  clearPerson,
  getPerson,
  updatePersonPhoto,
} from '../../../store/modules/person';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import {
  canManagePerson,
  checkUserRole,
  isObjectEmpty,
} from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { getShareTypeLabel, getNavItems } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    position: 'relative',
  },
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spacing(5),
    ...sticky(primary.white),
  },
  stickyHorizontalNavigation: {
    position: 'sticky',
    zIndex: 999,
    background: primary.white,
    top: 84,
    paddingBottom: spacing(6),
  },
  avatar: {
    marginRight: spacing(4),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 600,
  },
  rightSide: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  shareType: {
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  slackIcon: {
    width: 48,
    height: 48,
  },
});

const mapStateToProps = state => ({
  user: state.person,
});

const mapDispatchToProps = dispatch => ({
  clearPerson: () => dispatch(clearPerson()),
  getPerson: id => getPerson(dispatch, id),
  updatePersonPhoto: (id, data) => updatePersonPhoto(dispatch, id, data),
  updateCurrentUserInfo: data => dispatch(updateCurrentUserInfo(data)),
});

class PeoplePageContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.navItems = getNavItems(
      props.translations.pageContainer,
      props.auth,
      props.userId
    );

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { history, userId } = this.props;
    const { VisitEMP } = EVENT_ACTION_TYPES;

    this.props.getPerson(userId).then(() => {
      const { user } = this.props;
      const isAssociate = checkUserRole(user.role, ROLES.ASSOCIATE);

      if (isAssociate) {
        return history.replace('/not-found');
      }

      this.setState({ isLoading: false });
    });

    tagManagerDataLayer(
      VisitEMP.action,
      VisitEMP.name,
      history.location.pathname.replace(userId, ':id')
    );
  }

  componentDidUpdate(prevProps) {
    // if the url user has changed
    const { userId } = this.props;

    if (prevProps.userId !== userId) {
      this.props.clearPerson();
      this.props.getPerson(userId).then(() => {
        const { translations, auth } = this.props;

        this.navItems = getNavItems(translations.pageContainer, auth, userId);
      });
    }
  }

  componentWillUnmount() {
    const { userId, history } = this.props;
    const currentLocation = history.location.pathname;

    if (
      currentLocation !== `/people/${userId}` &&
      currentLocation !== `/people/${userId}/general-info` &&
      !currentLocation.includes(`/people/${userId}/action-plans`) &&
      currentLocation !== `/people/${userId}/insights` &&
      currentLocation !== `/people/${userId}/my-people` &&
      currentLocation !== `/people/${userId}/notes` &&
      currentLocation !== `/people/${userId}/learning` &&
      !currentLocation.includes(`/people/${userId}/one-on-one`)
    ) {
      this.props.clearPerson();
    }
  }

  onSuccessUpload = files => {
    const {
      user: { id },
    } = this.props;
    const formData = new FormData();
    formData.append('avatar', files[0]);
    this.props.updatePersonPhoto(id, formData).then(() => {
      const { user, auth } = this.props;
      if (id === auth.id)
        this.props.updateCurrentUserInfo({ avatar: user.avatar });
    });
  };

  renderPageHeader = () => {
    const { classes, user, auth, translations } = this.props;
    const canChangeAvatar =
      auth.id === user.id || canManagePerson(auth, user.id);
    const shareTypeLabel = getShareTypeLabel(
      translations.pageContainer,
      auth,
      user
    );

    return (
      <div className={classes.headerTitle}>
        <div className={classes.avatar}>
          <AvatarUploadZone
            translations={translations.pageContainer.avatarUpload}
            user={user}
            disabled={!canChangeAvatar}
            onSuccessHandler={files => this.onSuccessUpload(files)}
          />
        </div>
        <TextBoxWithTooltip variant="h1" text={getPersonFullName(user)} />
        {user?.slack_id && (
          <ArrowTooltip
            position="right"
            tooltipLabel={translations.pageContainer.slackTooltip}
          >
            <LogoSlack className={classes.slackIcon} />
          </ArrowTooltip>
        )}
        {shareTypeLabel && (
          <ShareTypeBox className={classes.shareType} label={shareTypeLabel} />
        )}
      </div>
    );
  };

  render() {
    const { children, classes, user, userId, ...rest } = this.props;
    const { isLoading } = this.state;
    const isUserLoaded = !isObjectEmpty(user);

    return (
      <PageContainer
        {...rest}
        personNavItems={this.navItems}
        user={user}
        headerProps={{
          renderTitle: isUserLoaded ? this.renderPageHeader : undefined,
        }}
        isLoading={!isUserLoaded}
      >
        {!isLoading && isUserLoaded && user.id === userId ? (
          children
        ) : (
          <Fragment />
        )}
      </PageContainer>
    );
  }
}

PeoplePageContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  clearPerson: PropTypes.func.isRequired,
  getPerson: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  setAuthInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  updatePersonPhoto: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PeoplePageContainer));
