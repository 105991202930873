import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrow-down-dark.svg';
import { isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
  },
  status: {
    color: primary.white,
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  clickable: {
    cursor: 'pointer',
  },
  dropdownIcon: {
    flexShrink: 0,
    marginLeft: spacing(1.5),
    cursor: 'pointer',
    '& path': {
      fill: ({ status }) =>
        status?.value === 1 ? primary.blue1 : primary.white,
    },
  },
  toDo: {
    background: primary.bluish8,
    color: primary.blue1,
  },
  done: {
    background: primary.bluish1,
  },
  completed: {
    background: primary.bluish1,
  },
  inProgress: {
    background: primary.orange,
  },
  draft: {
    background: primary.bluish8,
    color: primary.bluish1,
  },
  menuItem: {
    fontFamily: 'ProximaNova-Bold',
  },
  scheduled: {
    background: primary.bluish8,
    color: primary.blue1,
  },
});

class StatusIndicator extends PureComponent {
  state = {
    anchorEl: null,
  };

  handleOpen = e => {
    const { isSelectable } = this.props;

    if (!isSelectable) return;

    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleSelect = e => {
    const { status, onSelect } = this.props;
    const { value } = e.target;

    if (value !== status.value) {
      onSelect(value);
    }

    this.handleClose(e);
  };

  render() {
    const { className, classes, status, statuses, isSelectable } = this.props;
    const { anchorEl } = this.state;

    return (
      <div
        className={classNames(
          classes.main,
          {
            [classes[status?.key]]: status,
          },
          className
        )}
        onClick={this.handleOpen}
      >
        <Typography
          variant="overline"
          className={classNames(classes.status, {
            [classes[status?.key]]: status,
            [classes.clickable]: isSelectable,
          })}
        >
          {status.name}
        </Typography>
        {isSelectable && <DropdownIcon className={classes.dropdownIcon} />}
        {isSelectable && !isArrayEmpty(statuses) && (
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={this.handleClose}
            keepMounted
          >
            {statuses.map(s => (
              <MenuItem
                key={s.key}
                classes={{ root: classes.menuItem }}
                value={s.value}
                selected={s.value === status.value}
                onClick={this.handleSelect}
              >
                {s.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    );
  }
}

StatusIndicator.defaultProps = {
  className: null,
  status: null,
  statuses: [],
  isSelectable: false,
  onSelect: () => {},
};

StatusIndicator.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  status: PropTypes.shape({}),
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  isSelectable: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(StatusIndicator);
