import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, FormHelperText, makeStyles } from '@material-ui/core';
import CustomCheckbox from '../customCheckbox';
import InputField from '../inputField';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { isItemInList } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';
import { CUSTOM_TOPIC_ERROR_TYPES } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    marginBottom: spacing(4),
  },
  topics: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  topic: {
    marginBottom: spacing(2),
  },
  topicLabel: {
    width: 350,
    ...ellipsis(),
  },
  customTopicWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  customTopicCheckbox: {
    marginRight: spacing(2.5),
  },
  errorMessage: {
    paddingLeft: spacing(122.5),
  },
}));

const Index = ({
  label,
  placeholder,
  topics,
  agenda,
  initialCustomTopic,
  hasError,
  errorType,
  errorMessage,
  onChange,
}) => {
  const [hasCustomTopic, setHasCustomTopic] = useState(
    !!initialCustomTopic?.id
  );
  const classes = useStyles();
  const { selectedTopics, customTopic } = agenda;
  const hasCustomTopicError =
    hasError && CUSTOM_TOPIC_ERROR_TYPES.includes(errorType);

  const handleRecommendedTopicChange = selectedTopic => isChecked => {
    if (isChecked) {
      return onChange({
        ...agenda,
        selectedTopics: [...selectedTopics, selectedTopic],
      });
    }

    return onChange({
      ...agenda,
      selectedTopics: selectedTopics.filter(
        topic => topic.id !== selectedTopic.id
      ),
    });
  };

  const handleCustomTopicChange = isChecked => {
    onChange({
      ...agenda,
      customTopic: isChecked
        ? { ...initialCustomTopic }
        : { ...customTopic, name: '' },
      hasCustomTopic: isChecked,
    });
    setHasCustomTopic(isChecked);
  };

  const handleTopicNameChange = event =>
    onChange({
      ...agenda,
      customTopic: { ...customTopic, name: event.target.value },
    });

  return (
    <div>
      <Typography variant="h4" component="p" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.topics}>
        {topics.map(topic => (
          <CustomCheckbox
            key={`topic_item_${topic.id}`}
            customRootClass={classes.topic}
            customLabelClass={classes.topicLabel}
            labelText={topic.name}
            isChecked={isItemInList(selectedTopics || [], topic)}
            onChange={handleRecommendedTopicChange(topic)}
            isControlled
          />
        ))}
        <div className={classes.customTopicWrapper}>
          <div className={classes.customTopicCheckbox}>
            <CustomCheckbox
              isChecked={hasCustomTopic}
              onChange={handleCustomTopicChange}
              isControlled
            />
          </div>
          <InputField
            className={classes.customTopicInput}
            name="newTopicName"
            type={FIELD_TYPES.TEXT}
            disabled={!hasCustomTopic}
            placeholder={placeholder}
            value={customTopic?.name || ''}
            error={hasCustomTopicError}
            onChange={handleTopicNameChange}
            fullWidth
          />
        </div>
      </div>
      {hasError && errorMessage && (
        <FormHelperText
          className={classNames({
            [classes.errorMessage]: hasCustomTopicError,
          })}
          error={hasError}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

Index.defaultProps = {
  agenda: {
    selectedTopics: [],
    customTopic: null,
    hasCustomTopic: false,
  },
  initialCustomTopic: null,
  hasError: false,
  errorType: '',
  errorMessage: '',
};

Index.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  agenda: PropTypes.shape({
    selectedTopics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    customTopic: PropTypes.shape({}),
    hasCustomTopic: PropTypes.bool,
  }),
  initialCustomTopic: PropTypes.shape({}),
  errorType: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default memo(Index);
