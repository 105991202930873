import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AuthContainer from '../../shared/authContainer';
import ResetPasswordForm from '../../shared/resetPasswordForm';
import {
  API_PROTOCOL,
  API_DOMAIN,
  createCustomHttpClient,
} from '../../../utility/http';
import { handleCustomHttpError } from '../../../utility/helpers';
import { API_ORGANIZATION_COMPLETE_SING_UP } from '../../../constants/apiRoutes';

const styles = ({ palette: { primary }, breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(10),
    },
  },
  expired: {
    color: primary.red1,
    textAlign: 'center',
  },
  expiredInstruction: {
    fontFamily: 'ProximaNova-Regular',
    paddingTop: spacing(5),
    textAlign: 'center',
  },
  expiredLink: {
    color: primary.bluish3,
  },
  loadingSection: {
    display: 'flex',
    marginBottom: spacing(5),
  },
  dontReloadMessage: {
    [breakpoints.down('xs')]: {
      marginBottom: spacing(15),
    },
  },
  circle: {
    marginRight: '10px',
    color: primary.blue2,
  },
  expiredContainer: {
    marginRight: 100,
    [breakpoints.down('xs')]: {
      marginBottom: 60,
      textAlign: 'left',
      marginRight: 0,
      '& a': {
        lineHeight: '24px',
      },
    },
  },
});

class SignUpSecondStepPage extends PureComponent {
  onUnload = e => {
    const { translations } = this.props;

    e.preventDefault();
    e.returnValue = translations.onUnloadMessage;
    return translations.onUnloadMessage;
  };

  handleSubmitHandler = (values, props, setSubmitting) => {
    const { token, translations, setSignUpAction } = props;

    if (token) {
      setSubmitting(true);
      setSignUpAction({
        inProgress: true,
      });
      props
        .createOrganizationUsingToken(token)
        .then(
          ({ data }) => {
            setSignUpAction({
              ...data,
              expired: false,
              stripeSessionUrl: data.subscription_session_url,
            });
            const baseUrl = `${API_PROTOCOL}://${data.domain_name}.api.${API_DOMAIN}/api/`;
            const httpClient = createCustomHttpClient(baseUrl, {
              Authorization: `Bearer ${data.token}`,
            });

            httpClient
              .post(API_ORGANIZATION_COMPLETE_SING_UP, {
                password: values.password,
              })
              .then(() => {
                if (data.subscription_session_url) {
                  window.location.href = data.subscription_session_url;
                } else {
                  const port =
                    window.location.port.length > 0
                      ? `:${window.location.port}`
                      : '';
                  const { protocol } = window.location;
                  window.location.href = `${protocol}//${data.domain_name}.${
                    API_DOMAIN.split(':')[0]
                  }${port}/login/${data.token}`;
                }
                setSignUpAction({ inProgress: false });
                setSubmitting(false);
              })
              .catch(error => {
                handleCustomHttpError(error, translations.somethingWentWrong);
                setSignUpAction({ inProgress: false });
              })
              .finally(() => {
                setSubmitting(false);
                setSignUpAction({ inProgress: false });
              });
          },
          error => {
            setSignUpAction({ expired: true, inProgress: false });
            handleCustomHttpError(error, translations.somethingWentWrong);
          }
        )
        .finally(() => {
          window.removeEventListener('beforeunload', this.onUnload);
        });
    }
  };

  render() {
    const { signUpResults, classes, translations, ...rest } = this.props;
    const TokenInvalid = translations.tokenInvalid;
    return (
      <AuthContainer
        translations={translations.authContainer}
        signUpPage
        removeTopButton
      >
        <div className={classes.root}>
          <div>
            {signUpResults.expired ? (
              <div className={classes.expiredContainer}>
                <Typography variant="h1" className={classes.expired}>
                  {TokenInvalid.warning}
                </Typography>
                <Typography
                  component="div"
                  variant="h4"
                  className={classes.expiredInstruction}
                >
                  {TokenInvalid.instruction}{' '}
                  <Link to="/login" className={classes.expiredLink}>
                    {TokenInvalid.login}
                  </Link>{' '}
                  {TokenInvalid.or}{' '}
                  <Link to="/sign-up" className={classes.expiredLink}>
                    {TokenInvalid.createNew}
                  </Link>
                  .
                </Typography>
              </div>
            ) : (
              <ResetPasswordForm
                {...rest}
                translations={translations}
                handleSubmitHandler={this.handleSubmitHandler}
                hideSubmitButton={signUpResults.inProgress}
                signUpResults={signUpResults}
                withoutBackLink
              />
            )}
          </div>
          {signUpResults.inProgress ? (
            <div>
              <div className={classes.loadingSection}>
                <CircularProgress
                  size="30px"
                  color="inherit"
                  className={classes.circle}
                />
                <Typography variant="h4">{translations.loadingText}</Typography>
              </div>
              <Typography>{translations.readyInMoment}</Typography>
              <Typography className={classes.dontReloadMessage}>
                {translations.dontReloadMessage}
              </Typography>
            </div>
          ) : null}
        </div>
      </AuthContainer>
    );
  }
}

export default withStyles(styles)(SignUpSecondStepPage);
