import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import CustomButton from '../customButton';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people-big-icon.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { checkUserRole } from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  paper: {
    display: 'flex',
    height: 298,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: spacing(7),
  },
  title: {
    marginBottom: spacing(2),
  },
  content: {
    marginBottom: spacing(6),
    textAlign: 'center',
  },
  closeIcon: {
    top: 16,
    right: 16,
  },
  button: {
    boxSizing: 'border-box',
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
  upgradePlanButton: {
    backgroundColor: primary.blue1,
    border: 'none',
    color: primary.white,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
  },
  logoutButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
    marginTop: spacing(3),
  },
}));

const UpgradeRequiredDialog = ({
  isOpened,
  onClickHandler,
  auth,
  onClose,
  removeClose,
  hasLogout,
  onLogout,
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.UPGRADE_REQUIRED);
  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

  return (
    <CustomModal
      paperClass={classes.paper}
      closeIconButtonClass={classes.closeIcon}
      isOpened={isOpened}
      shouldRemoveCloseIconButton={removeClose}
      onClose={onClose}
      isSmall
      shouldRemoveFooter
    >
      <PeopleIcon className={classes.icon} />
      <Typography className={classes.title} variant="h3" component="div">
        {translations.peopleLimit}
      </Typography>
      <Typography className={classes.content}>
        {isAdmin
          ? translations.upgradeRequiredMessage
          : translations.contactAdminMessage}
      </Typography>
      {isAdmin && (
        <CustomButton
          className={classNames(classes.button, classes.upgradePlanButton)}
          type="withTextDarkRounded"
          onClick={onClickHandler}
        >
          {translations.upgradeButtonText}
        </CustomButton>
      )}
      {!isAdmin && hasLogout && (
        <CustomButton
          className={classNames(classes.button, classes.logoutButton)}
          type="withTextLightRounded"
          onClick={onLogout}
        >
          {translations.logout}
        </CustomButton>
      )}
    </CustomModal>
  );
};

UpgradeRequiredDialog.defaultProps = {
  isOpened: false,
  removeClose: true,
  hasLogout: false,
  onLogout: () => {},
  onClose: () => {},
};
UpgradeRequiredDialog.propTypes = {
  auth: PropTypes.object.isRequired,
  isOpened: PropTypes.bool,
  removeClose: PropTypes.bool,
  hasLogout: PropTypes.bool,
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
};

export default memo(UpgradeRequiredDialog);
