import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PricingPlansPage from '../../components/pages/pricingPlansPage';
import PageContainer from '../../components/shared/pageContainer';
import { getOrganizationSettings } from '../../store/modules/organizationSettings';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapDispatchToProps = dispatch => ({
  getOrganizationSettings: () => getOrganizationSettings(dispatch),
});

const PricingPlanPageView = props => {
  const translations = useTranslations(APP_PAGES.PRICING);
  const { priceId } = useParams();

  return (
    <PageContainer
      {...props}
      translations={translations}
      isFullWidthContent
      headerProps={{
        shouldHideUpgrade: true,
      }}
      priceId={priceId}
    >
      <PricingPlansPage />
    </PageContainer>
  );
};

export default connect(null, mapDispatchToProps)(PricingPlanPageView);
