import { useState, useMemo, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Typography, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import UserCompare from '../../shared/userCompare';
import NotificationCard from '../../shared/notificationCard';
import SelectField from '../../shared/selectField';
import http from '../../../utility/http';
import { parseDuplicateParameters } from '../../../utility/uiUtils';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import { getJobTitles, clearJobTitles } from '../../../store/modules/jobTitles';
import {
  getTagsCategories,
  clearTagsCategories,
} from '../../../store/modules/tags';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../../store/modules/levelsAndTracks';
import { getEmployees, isArrayEmpty } from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import { jobTitlesSelector } from '../../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../../store/selectors/levelsAndTracksSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import { hasActiveSubscription } from '../../../utility/subscriptionHelper';
import { APP_PAGES } from '../../../constants/pages';
import {
  API_USERS_BASIC,
  API_REPORT_USER_COMPARE,
} from '../../../constants/apiRoutes';
import { sticky } from '../../../constants/helperCssRules';
import { getPageFilters, getUserRoles } from '../peoplePage/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {},
  pageDescription: {
    padding: spacing(8, 0, 6, 0),
    width: '100%',
    ...sticky(primary.white, 105),
  },
  userSelectWrapper: {
    paddingBottom: spacing(6),
    ...sticky(primary.white, 179),
  },
  userSelect: {
    minWidth: 300,
    maxWidth: 300,
  },
}));

const CompareReportPage = ({
  navigate,
  auth,
  organizationSettings,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.COMPARE);

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { allUsers } = useSelector(peopleSelector);
  const { jobTitles } = useSelector(jobTitlesSelector);
  const { tracks } = useSelector(levelsAndTracksSelector);
  const { categories } = useSelector(tagsSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [createdForUser, setCreatedForUser] = useState(state?.user || null);

  const hasSubscription = hasActiveSubscription(
    organizationSettings.subscription,
    organizationSettings.subscription_end_at
  );
  const allEmployees = useMemo(() => getEmployees(allUsers), [allUsers]);
  const userFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true, hasSubscription),
            false,
            auth
          )
        : [],
    [
      translations,
      isLoading,
      auth,
      hasSubscription,
      jobTitles,
      tracks,
      categories,
    ]
  );
  const initialReviewers = state?.user ? allUsers : [];
  const initialSurveyType = state?.surveyType ? state.surveyType : null;

  const goToUserProfilePage = userId => () => navigate(`/people/${userId}`);

  const onGetReviewers = useCallback(data => {
    const params = {
      ...data,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getAllUsers(dispatch, {}, true),
        getJobTitles(dispatch),
        getTagsCategories(dispatch),
        getLevelsAndTracks(dispatch),
      ]);

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [dispatch]);

  const cleanup = useCallback(() => {
    dispatch(clearAllUsers());
    dispatch(clearLevelsAndTracks());
    dispatch(clearJobTitles());
    dispatch(clearTagsCategories());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const onGetAttributeScores = useCallback(async (filters, isSelf = false) => {
    if (isSelf || !isArrayEmpty(filters.reviewer)) {
      try {
        const params = {
          ...filters,
        };

        const { data } = await http.get(API_REPORT_USER_COMPARE, {
          params,
          paramsSerializer: d => parseDuplicateParameters(d),
        });

        return data;
      } catch {
        return [];
      }
    }

    return [];
  }, []);

  const handleCreatedForUserSelect = selectedUser =>
    setCreatedForUser(selectedUser);

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
    >
      {!isLoading && (
        <div className={classes.root}>
          <Typography className={classes.pageDescription} variant="body2">
            {translations.description}
          </Typography>
          <div className={classes.userSelectWrapper}>
            <SelectField
              className={classes.userSelect}
              label={translations.user.label}
              placeholder={translations.user.placeholder}
              parser={{
                value: 'id',
                label: 'first_name',
              }}
              value={createdForUser}
              options={allEmployees}
              onChange={handleCreatedForUserSelect}
              shouldReturnOption
              isUser
            />
          </div>
          {createdForUser ? (
            <UserCompare
              translations={translations.compare}
              initialReviewers={initialReviewers}
              initialSurveyType={initialSurveyType}
              allUsers={allUsers}
              allEmployees={allEmployees}
              userFilters={userFilters}
              createdForUser={createdForUser}
              onGetAttributeScores={onGetAttributeScores}
              onGoToProfilePage={goToUserProfilePage}
              onGetReviewers={onGetReviewers}
            />
          ) : (
            <NotificationCard content={translations.noResults} />
          )}
        </div>
      )}
    </PageContainer>
  );
};

CompareReportPage.propTypes = {
  auth: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  organizationSettings: PropTypes.object.isRequired,
};

export default memo(CompareReportPage);
