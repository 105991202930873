import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import fields from './fields';
import BackLink from '../backLink';
import AuthFormLayout from '../authFormLayout';
import { AUTOMATION_ID } from '../../../constants/automationId';

class ForgottenPasswordFormLayout extends PureComponent {
  render() {
    const { translations, history } = this.props;

    return (
      <AuthFormLayout {...this.props} fields={fields} multiLine>
        <BackLink
          id={AUTOMATION_ID.SIGN_IN_BUTTON}
          title={translations.backLink}
          history={history}
          to="/login"
        />
      </AuthFormLayout>
    );
  }
}

ForgottenPasswordFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ForgottenPasswordFormLayout;
