import { memo, useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse, makeStyles } from '@material-ui/core';
import AttributeInfoBar from '../attributeInfoBar';
import ReportAnswerGrid from '../reportAnswerGrid';
import ReportTextReplies from '../reportTextReplies';
import ReportFeedback from '../reportFeedback';
import UsersFilter from './usersFilter';
import ReportChart from './reportChart';
import {
  prepareAnswerGridResults,
  prepareTextReplyResults,
  getReportChartResults,
} from '../../../utility/reportUtils';
import { customSearch } from '../../../utility/uiUtils';
import { isArrayEmpty, trimString } from '../../../utility/helpers';
import { CHART_COLORS } from './reportChart/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    borderBottom: `1px solid ${primary.bluish7}`,
  },
  content: {
    paddingBottom: spacing(7),
  },
  info: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(3, 4),
    transition: 'background-color .3 ease',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: primary.bluish9,
    },
  },
  title: {
    position: 'relative',
    zIndex: 1,
    marginRight: spacing(2),
  },
  collapseButton: {
    borderRadius: 4,
    margin: '0px !important',
    marginRight: `${spacing(4)}px !important`,
    alignSelf: 'flex-start',
    width: 52,
    height: 52,
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
    width: 14,
    height: 14,
    '& path': {
      fill: primary.white,
    },
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
  answerGrid: {
    marginTop: spacing(6),
  },
  feedback: {
    marginTop: spacing(16),
  },
}));

const CHART_MAX_SELECTED_USERS = 6;
const MIN_ANSWERS_QUESTIONS_FOR_CHART = 2;
const MAX_SCORE_VALUE = 10;

const ReviewReport = ({
  translations,
  attribute,
  survey,
  review,
  reviewResults,
  isExpanded,
  feedback,
  participants,
  textReplyQuestionId,
  isUserClickable,
  isTeamReview,
  onViewIndividualScores,
  onGoToPersonProfile,
  onViewTextReply,
  onViewReport,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAverageView, setIsAverageView] = useState(false);
  const { isAnonymous } = survey;
  const { averageScore } = attribute;
  const {
    questions,
    totalAnswers,
    hasNeutralAnswer,
    hasAdditionalFeedback,
    isTextReply,
  } = review;

  const isSingleParticipant = participants.length === 1;
  const hasFilter = useMemo(
    () => !isAnonymous && participants.length !== 1,
    [participants, isAnonymous]
  );
  const hasReportChart = useMemo(
    () =>
      !isAnonymous &&
      !isTextReply &&
      totalAnswers > MIN_ANSWERS_QUESTIONS_FOR_CHART &&
      questions.length >= MIN_ANSWERS_QUESTIONS_FOR_CHART,
    [isAnonymous, isTextReply, totalAnswers, questions]
  );

  const reportChartResults = useCallback(
    isAverage =>
      getReportChartResults(
        reviewResults,
        questions,
        hasNeutralAnswer,
        selectedUsers,
        isAverage
      ),
    [reviewResults, questions, hasNeutralAnswer, selectedUsers]
  );

  const chartResults = useMemo(
    () => isExpanded && hasReportChart && reportChartResults(isAverageView),
    [reportChartResults, isExpanded, hasReportChart, isAverageView]
  );

  const yMax = isAverageView ? MAX_SCORE_VALUE : totalAnswers;

  const handleChangeView = useCallback(
    value => {
      if (value !== isAverageView) {
        setIsAverageView(value);
      }
    },
    [isAverageView]
  );

  const hasAverageChart = useCallback(() => {
    if (!hasReportChart) return false;
    const chart = isAverageView ? chartResults : reportChartResults(true);
    if (!isArrayEmpty(chart)) {
      if (chart[0].some(result => result !== null)) {
        return true;
      }
      handleChangeView(false);
      return false;
    }
    return true;
  }, [
    chartResults,
    isAverageView,
    hasReportChart,
    handleChangeView,
    reportChartResults,
  ]);

  useEffect(() => {
    if (!isExpanded) {
      setSelectedUsers([]);
    }
  }, [isExpanded]);

  useEffect(() => {
    const [participant] = participants;
    if (hasReportChart && isSingleParticipant && participant.finished) {
      setSelectedUsers([participant.id]);
    }
  }, [hasReportChart, participants, isSingleParticipant]);

  const handleViewReport = e => {
    e.stopPropagation();
    onViewReport();
  };

  const handleSearch = searchTerm => {
    setSearchValue(searchTerm);
  };

  const handleUserSelect = (selectedUserId, isSelected) => {
    if (isSelected) {
      return setSelectedUsers(
        selectedUsers.filter(userId => userId !== selectedUserId)
      );
    }

    return setSelectedUsers([...selectedUsers, selectedUserId]);
  };

  const handleClearSelection = () => {
    setSelectedUsers([]);
    setSearchValue('');
  };

  return (
    <div className={classes.root}>
      <AttributeInfoBar
        translations={translations}
        attribute={attribute}
        averageScore={averageScore}
        isAnonymous={isAnonymous}
        isExpanded={isExpanded}
        onViewIndividualScores={onViewIndividualScores}
        onClickHandler={handleViewReport}
        isClickable
        hasCollapseButton
      />
      <Collapse
        className={classes.content}
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        {isExpanded && (
          <>
            <UsersFilter
              translations={translations.usersFilter}
              title={
                hasReportChart
                  ? translations.usersFilter.titleChart
                  : translations.usersFilter.titleMatrix
              }
              searchTerm={searchValue}
              users={customSearch(participants, trimString(searchValue), true)}
              activeColors={hasReportChart ? CHART_COLORS.LINE_COLORS : []}
              selectedUsers={selectedUsers}
              maxSelectedUsers={hasReportChart ? CHART_MAX_SELECTED_USERS : 0}
              isTeamReview={isTeamReview}
              onClear={handleClearSelection}
              onSearch={handleSearch}
              onSelect={handleUserSelect}
              hasReportChart={hasReportChart}
              hasAverageChart={hasAverageChart()}
              isAverageView={isAverageView}
              handleChangeView={handleChangeView}
              hasFilter={hasFilter}
              shouldEnableOnlyFinishedUsers
            />
            {hasReportChart && (
              <ReportChart
                translations={translations}
                noResultsMessage={
                  isSingleParticipant
                    ? translations.noChartResultsSingle
                    : translations.noChartResultsGroup
                }
                hasNeutralAnswer={isAverageView ? false : hasNeutralAnswer}
                isAverageView={isAverageView}
                xMax={questions.length}
                yMax={yMax}
                results={chartResults}
              />
            )}
            {isTextReply ? (
              <ReportTextReplies
                isAnonymous={isAnonymous}
                anonymousUser={translations.anonymousUser}
                notApplicableLabel={translations.neutral.notApplicable}
                questions={questions}
                results={prepareTextReplyResults(
                  reviewResults,
                  selectedUsers,
                  !hasReportChart
                )}
                selectedQuestionId={textReplyQuestionId}
                onExpand={onViewTextReply}
              />
            ) : (
              <ReportAnswerGrid
                className={classes.answerGrid}
                translations={translations}
                hasNeutralAnswer={hasNeutralAnswer}
                hasPercentageView={!isSingleParticipant}
                totalAnswers={totalAnswers}
                rows={questions}
                attributeColor={attribute.color}
                results={prepareAnswerGridResults(reviewResults, selectedUsers)}
              />
            )}
            {hasAdditionalFeedback && (
              <ReportFeedback
                className={classes.feedback}
                translations={translations.feedback}
                feedback={feedback}
                isUserClickable={isUserClickable}
                onGoToPersonProfile={onGoToPersonProfile}
              />
            )}
          </>
        )}
      </Collapse>
    </div>
  );
};

ReviewReport.defaultProps = {
  textReplyQuestionId: null,
};

ReviewReport.propTypes = {
  translations: PropTypes.object.isRequired,
  attribute: PropTypes.shape({}).isRequired,
  survey: PropTypes.shape({}).isRequired,
  review: PropTypes.shape({}).isRequired,
  reviewResults: PropTypes.shape({}).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  textReplyQuestionId: PropTypes.number,
  feedback: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserClickable: PropTypes.func.isRequired,
  isTeamReview: PropTypes.bool.isRequired,
  onViewTextReply: PropTypes.func.isRequired,
  onGoToPersonProfile: PropTypes.func.isRequired,
  onViewReport: PropTypes.func.isRequired,
  onViewIndividualScores: PropTypes.func.isRequired,
};

export default memo(ReviewReport);
