import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import GeneralMoodChart from '../generalMoodChart';
import Tooltip from '../tooltip';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/info-dark.svg';
import { prepareUserMoodData } from '../../../utility/dashboardUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 500,
    height: 170,
    userSelect: 'none',
  },
  user: {
    marginBottom: spacing(6),
  },
  name: {
    lineHeight: '16px',
  },
  jobTitle: {
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 0,
  },
  statsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(0,1fr))',
    gridColumnGap: 8,
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  label: {
    lineHeight: '16px',
  },
  scoreWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreValue: {
    marginRight: spacing(1),
  },
  tooltipIcon: {
    height: 14,
    width: 14,
    marginLeft: spacing(1),
  },
  section: {
    cursor: 'pointer',
  },
}));

const TeamUserStatsCard = ({
  className,
  translations,
  user,
  onGoToUserProfile,
}) => {
  const classes = useStyles();
  const {
    impression,
    active_plans,
    finished_plans,
    active_course,
    finished_course,
    notes,
    ...rest
  } = user;
  const userMood = prepareUserMoodData(impression);

  const handleGoToOneOnOne = useCallback(() => {
    onGoToUserProfile(user.id, 'one-on-one');
  }, [user, onGoToUserProfile]);

  const handleGoToPlans = useCallback(() => {
    onGoToUserProfile(user.id, 'action-plans');
  }, [user, onGoToUserProfile]);

  const handleGoToCourses = useCallback(() => {
    onGoToUserProfile(user.id, 'learning');
  }, [user, onGoToUserProfile]);

  const handleGoToNotes = useCallback(() => {
    onGoToUserProfile(user.id, 'notes');
  }, [user, onGoToUserProfile]);

  return (
    <div className={classNames(classes.root, className)}>
      <UserAvatar
        className={classes.user}
        labelClass={classes.name}
        captionDescriptionClass={classes.jobTitle}
        user={rest}
        variant="subtitle1"
        captionDescription={rest.position?.name}
        withDescription={!!rest.position}
        caption
      />
      <div className={classes.statsWrapper}>
        <div className={classes.section} onClick={handleGoToOneOnOne}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.label} variant="subtitle2">
              {translations.labels.oneOnOnes}
            </Typography>
            <Tooltip
              customIconClass={classes.tooltipIcon}
              text={translations.tooltipMessages.oneOnOnes}
              icon={TooltipIcon}
            />
          </div>
          <GeneralMoodChart data={userMood} />
        </div>
        <div className={classes.section} onClick={handleGoToPlans}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.label} variant="subtitle2">
              {translations.labels.actionPlans}
            </Typography>
          </div>
          <div className={classes.scoreWrapper}>
            <Typography className={classes.scoreValue} variant="overline">
              {active_plans}
            </Typography>
            <Typography variant="caption">
              {translations.labels.inProgress.toLowerCase()}
            </Typography>
          </div>
          <div className={classes.scoreWrapper}>
            <Typography className={classes.scoreValue} variant="overline">
              {finished_plans}
            </Typography>
            <Typography variant="caption">
              {translations.labels.done.toLowerCase()}
            </Typography>
          </div>
        </div>
        <div className={classes.section} onClick={handleGoToCourses}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.label} variant="subtitle2">
              {translations.labels.courses}
            </Typography>
          </div>
          <div className={classes.scoreWrapper}>
            <Typography className={classes.scoreValue} variant="overline">
              {active_course}
            </Typography>
            <Typography variant="caption">
              {translations.labels.inProgress.toLowerCase()}
            </Typography>
          </div>
          <div className={classes.scoreWrapper}>
            <Typography className={classes.scoreValue} variant="overline">
              {finished_course}
            </Typography>
            <Typography variant="caption">
              {translations.labels.completed.toLowerCase()}
            </Typography>
          </div>
        </div>
        <div className={classes.section} onClick={handleGoToNotes}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.label} variant="subtitle2">
              {translations.labels.notes}
            </Typography>
          </div>
          <div className={classes.scoreWrapper}>
            <Typography className={classes.scoreValue} variant="overline">
              {notes}
            </Typography>
            <Typography variant="caption">
              {translations.labels.total.toLowerCase()}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamUserStatsCard.defaultProps = {
  className: undefined,
  user: {},
};

TeamUserStatsCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  user: PropTypes.object,
  onGoToUserProfile: PropTypes.func.isRequired,
};

export default TeamUserStatsCard;
