import { useState, useCallback, useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Fade, Typography, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import SelectField from '../../shared/selectField';
import CustomDateRange from '../../shared/customDateRange';
import SectionTitle from '../../shared/sectionTitle';
import MultipartPeoplePicker from '../../shared/multipartPeoplePicker';
import AttributeInfoBar from '../../shared/attributeInfoBar';
import ReportDetailsTable from '../../shared/reportDetailsTable';
import DeepDiveReport from '../../shared/deepDiveReport';
import NotificationCard from '../../shared/notificationCard';
import ReadLessMore from '../../shared/readLessMore';
import { surveysSelector } from '../../../store/selectors/surveysSelector';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import { jobTitlesSelector } from '../../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../../store/selectors/levelsAndTracksSelector';
import {
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
} from '../../../store/modules/surveys';
import { getJobTitles, clearJobTitles } from '../../../store/modules/jobTitles';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../../store/modules/levelsAndTracks';
import {
  getTagsCategories,
  clearTagsCategories,
} from '../../../store/modules/tags';
import {
  getEmployees,
  isArrayEmpty,
  getObjectToNumberArray,
  isObjectEmpty,
  isUserClickable,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import {
  getGroupedSurveyOptions,
  prepareAnswerGridResults,
  prepareTextReplyResults,
  transformReviewData,
} from '../../../utility/reportUtils';
import { useTranslations } from '../../../utility/useTranslations';
import { hasActiveSubscription } from '../../../utility/subscriptionHelper';
import { parseDuplicateParameters } from '../../../utility/uiUtils';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import {
  API_USERS_BASIC,
  API_REPORT_ATTRIBUTE_ANSWERS,
  API_REPORT_ATTRIBUTE_REPLIES,
  API_REPORT_ATTRIBUTE_FEEDBACK,
  API_REPORT_ATTRIBUTE_TIMELINE,
  API_REPORT_ATTRIBUTE_SCORE,
  api_attribute,
} from '../../../constants/apiRoutes';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { getLast12Months } from '../../shared/customDateRange/config';
import { getSurveyStatuses } from '../surveysPage/config';
import { getPageFilters, getUserRoles } from '../peoplePage/config';
import {
  GROUPED_SURVEY_REPORTING_TYPES,
  GROUPED_SURVEY_TYPES_KEYS,
} from '../../../constants/survey';
import { sticky } from '../../../constants/helperCssRules';
import { APP_PAGES, PARAMS } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  pageDescription: {
    ...sticky(primary.white, 105),
    width: '100%',
    padding: spacing(8, 0, 8, 0),
  },
  filtersWrapper: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0,300px))',
    gridColumnGap: 16,
    marginBottom: spacing(8),
    paddingBottom: spacing(6),
    '&:last-of-type': {
      marginBottom: spacing(0),
    },
  },
  filtersUsersWrapper: {
    display: 'block',
  },
  usersSectionDescription: {
    margin: spacing(1, 0, 6),
  },
  topSpacing: {
    marginTop: spacing(6),
  },
  attributeInfoNoScore: {
    padding: spacing(3, 4, 3, 0),
  },
}));

const AttributeReportPage = ({
  navigate,
  auth,
  organizationSettings,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ATTRIBUTE_REPORT);
  const { SELF_ASSESSMENT, PULSE } = GROUPED_SURVEY_TYPES_KEYS;
  const [PeerTeanAndDirect] = GROUPED_SURVEY_REPORTING_TYPES;

  const dispatch = useDispatch();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isReadMore, setIsReadMore] = useState(true);
  const [surveyType, setSurveyType] = useState(PeerTeanAndDirect);
  const [attribute, setAttribute] = useState(state?.attribute || null);
  const [currentAttributeData, setCurrentAttributeData] = useState(null);
  const [period, setPeriod] = useState(getLast12Months());
  const [reviewedUsers, setReviewedUsers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [results, setResults] = useState({});
  const [attributeScore, setAttributeScore] = useState(null);
  const [openEndedQuestionId, setOpenEndedQuestionId] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [surveyReference, setSurveyReference] = useState([]);

  const { attributesWithQuestions } = useSelector(surveysSelector);
  const { allUsers } = useSelector(peopleSelector);
  const { jobTitles } = useSelector(jobTitlesSelector);
  const { tracks } = useSelector(levelsAndTracksSelector);
  const { categories } = useSelector(tagsSelector);

  const isOpenEndedAttribute = currentAttributeData?.text_reply_questions;
  const isSelfReport = surveyType.key === SELF_ASSESSMENT;
  const isPulseReport = surveyType.key === PULSE;
  const surveyTypeOptions = getGroupedSurveyOptions(
    translations.surveyOptions,
    true
  );
  const surveyStatuses = getSurveyStatuses(translations.surveyStatuses);
  const detailsTableColumns = [
    { label: translations.review.details.date },
    { label: translations.review.details.commentText },
    { label: translations.review.details.status },
  ];
  const hasSubscription = hasActiveSubscription(
    organizationSettings.subscription,
    organizationSettings.subscription_end_at
  );
  const allEmployees = useMemo(() => getEmployees(allUsers), [allUsers]);
  const reviewedUsersFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true),
            false,
            auth
          )
        : [],
    [translations, isLoading, auth, jobTitles, tracks, categories]
  );
  const reviewersFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true, hasSubscription),
            false,
            auth
          )
        : [],
    [
      translations,
      isLoading,
      auth,
      hasSubscription,
      jobTitles,
      tracks,
      categories,
    ]
  );

  const reportParams = useMemo(() => {
    const { ATTRIBUTE, SURVEY_TYPE, START, END, START_AT, END_AT } = PARAMS;
    const subject = getObjectToNumberArray(reviewedUsers);
    const reviewer = getObjectToNumberArray(reviewers);

    return {
      [ATTRIBUTE]: attribute?.id,
      [SURVEY_TYPE]: surveyType?.values,
      [START_AT]: getStartEndOfDayUtc(period[START]),
      [END_AT]: getStartEndOfDayUtc(period[END], true),
      ...(isPulseReport ? {} : { subject }),
      ...(isSelfReport ? {} : { reviewer }),
    };
  }, [
    attribute,
    surveyType,
    period,
    reviewedUsers,
    reviewers,
    isSelfReport,
    isPulseReport,
  ]);

  const goToUserProfilePage = userId => navigate(`/people/${userId}`);

  const getSurveyLinkPath = survey => `/surveys/${survey.id}/report`;

  const canGoToUserProfile = user =>
    isUserClickable(auth, organizationSettings.global_see_himself)(user);

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getAttributesWithQuestions(dispatch),
        getAllUsers(dispatch, {}, true),
        getJobTitles(dispatch),
        getTagsCategories(dispatch),
        getLevelsAndTracks(dispatch),
      ]);

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [dispatch]);

  const onGetReviewedUsers = useCallback(data => {
    const { EXCLUDE_ROLE } = PARAMS;

    const params = {
      ...data,
      [EXCLUDE_ROLE]: ROLES.ASSOCIATE,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const onGetReviewers = useCallback(data => {
    const params = {
      ...data,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const handleChange = useCallback(async () => {
    const isOpenEnded = attribute?.text_reply_questions;
    const reviewersIds = getObjectToNumberArray(reviewers);
    let currentScore = null;
    let reviewFeedback = [];

    if (
      attribute &&
      (isSelfReport || !isArrayEmpty(reviewers)) &&
      (isPulseReport || !isArrayEmpty(reviewedUsers))
    ) {
      try {
        const [reportResults, currentReference, currentAttribute] =
          await Promise.all([
            http.get(
              isOpenEnded
                ? API_REPORT_ATTRIBUTE_REPLIES
                : API_REPORT_ATTRIBUTE_ANSWERS,
              {
                params: reportParams,
                paramsSerializer: d => parseDuplicateParameters(d),
              }
            ),
            http.get(API_REPORT_ATTRIBUTE_TIMELINE, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            }),
            http.get(api_attribute(attribute.id)),
          ]);

        if (!isObjectEmpty(reportResults?.data)) {
          let currentAttributeScore = null;
          let currentFeedback = [];

          if (!isOpenEnded) {
            currentAttributeScore = await http.get(API_REPORT_ATTRIBUTE_SCORE, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            });
          }

          if (currentAttribute?.data?.feedback_field) {
            currentFeedback = await http.get(API_REPORT_ATTRIBUTE_FEEDBACK, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            });
          }

          currentScore = currentAttributeScore?.data?.score || null;
          reviewFeedback = currentFeedback?.data || [];
        }

        setAttributeScore(currentScore);
        setCurrentAttributeData(transformReviewData(currentAttribute?.data));
        setFeedback(reviewFeedback);
        setSurveyReference(currentReference?.data || []);
        setOpenEndedQuestionId(null);
        return setResults(
          isOpenEnded
            ? prepareTextReplyResults(reportResults?.data, reviewersIds)
            : prepareAnswerGridResults(reportResults?.data, reviewersIds)
        );
      } catch {
        setResults({});
        setAttributeScore(currentScore);
        setCurrentAttributeData(null);
        setFeedback(reviewFeedback);
        setSurveyReference([]);
        setOpenEndedQuestionId(null);
      }
    }

    setSurveyReference([]);
    setOpenEndedQuestionId(null);
    setAttributeScore(currentScore);
    setFeedback(reviewFeedback);

    return setResults({});
  }, [
    attribute,
    reviewers,
    reviewedUsers,
    reportParams,
    isSelfReport,
    isPulseReport,
  ]);

  const cleanup = useCallback(() => {
    dispatch(clearAttributesWithQuestions());
    dispatch(clearAllUsers());
    dispatch(clearLevelsAndTracks());
    dispatch(clearJobTitles());
    dispatch(clearTagsCategories());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  const handlePreviewOpenEndedQuestion = questionId => e => {
    e.stopPropagation();

    setOpenEndedQuestionId(prevQuestionId =>
      prevQuestionId !== questionId ? questionId : null
    );
  };

  const handleAttributeChange = selectedAttribute =>
    setAttribute(selectedAttribute);

  const handlePeriodChange = newRange => setPeriod(newRange);

  const handleSurveyTypeChange = selectedSurveyType => {
    setSurveyType(selectedSurveyType);
  };

  const handleReviewedUsersChange = useCallback(
    selectedUsers => setReviewedUsers(selectedUsers),
    []
  );

  const handleReviewersUsersChange = useCallback(
    selectedUsers => setReviewers(selectedUsers),
    []
  );

  const handleToggleReadMore = () =>
    setIsReadMore(prevReadMore => !prevReadMore);

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isLoading && (
        <div>
          <div className={classes.pageDescription}>
            <ReadLessMore
              translations={translations}
              isReadMore={isReadMore}
              toggleReadMore={handleToggleReadMore}
            >
              <Typography variant="body2">
                {translations.descriptionPartOne}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartTwo}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartThree}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartFour}
              </Typography>
            </ReadLessMore>
          </div>
          <div className={classes.filtersWrapper}>
            <SelectField
              value={surveyType}
              label={translations.selectFilters.surveyType}
              options={surveyTypeOptions}
              parser={{ value: 'key', label: 'name' }}
              onChange={handleSurveyTypeChange}
              shouldReturnOption
              isSearchDisabled
            />
            <SelectField
              label={translations.selectFilters.attribute.label}
              placeholder={translations.selectFilters.attribute.placeholder}
              value={attribute}
              options={attributesWithQuestions}
              parser={{ value: 'id', label: 'name' }}
              onChange={handleAttributeChange}
              shouldReturnOption
              hasColorBox
              isAttribute
            />
            <CustomDateRange
              label={translations.selectFilters.period}
              initialRange={{
                startDate: period[PARAMS.START],
                endDate: period[PARAMS.END],
              }}
              startAtKey={PARAMS.START}
              endAtKey={PARAMS.END}
              onChange={handlePeriodChange}
            />
          </div>
          <Fade in={!isPulseReport} appear={false} unmountOnExit>
            <div
              className={classNames(
                classes.filtersWrapper,
                classes.filtersUsersWrapper
              )}
            >
              <SectionTitle
                title={translations.users.objectsOfReview.title}
                variant="h4"
              />
              <Typography
                className={classes.usersSectionDescription}
                variant="body2"
              >
                {translations.users.objectsOfReview.description}
              </Typography>
              <MultipartPeoplePicker
                translations={translations.objectsPicker}
                filters={reviewedUsersFilters}
                allUsers={allEmployees}
                selectedUsers={reviewedUsers}
                onGetUsers={onGetReviewedUsers}
                onSave={handleReviewedUsersChange}
              />
            </div>
          </Fade>
          <Fade in={!isSelfReport} appear={false} unmountOnExit>
            <div
              className={classNames(
                classes.filtersWrapper,
                classes.filtersUsersWrapper
              )}
            >
              <SectionTitle
                title={translations.users.reviewers.title}
                variant="h4"
              />
              <Typography
                className={classes.usersSectionDescription}
                variant="body2"
              >
                {translations.users.reviewers.description}
              </Typography>
              <MultipartPeoplePicker
                translations={translations.reviewersPicker}
                filters={reviewersFilters}
                allUsers={allUsers}
                selectedUsers={reviewers}
                onGetUsers={onGetReviewers}
                onSave={handleReviewersUsersChange}
              />
            </div>
          </Fade>
          {currentAttributeData && !isObjectEmpty(results) && (
            <AttributeInfoBar
              className={classes.topSpacing}
              translations={translations.attributeInfoBar}
              attribute={currentAttributeData}
              averageScore={attributeScore}
              isOpenEndedAttribute={isOpenEndedAttribute}
              shouldDisableIndividualScores
            />
          )}
          <DeepDiveReport
            translations={translations.review}
            results={results}
            feedback={feedback}
            attribute={currentAttributeData}
            openEndedQuestionId={openEndedQuestionId}
            isOpenEndedAttribute={isOpenEndedAttribute}
            canGoToUserProfile={canGoToUserProfile}
            onOpenEndedQuestionPreview={handlePreviewOpenEndedQuestion}
            onGoToUserProfile={goToUserProfilePage}
          />
          <NotificationCard
            className={classes.topSpacing}
            shouldFade={isObjectEmpty(results)}
            content={translations.noResults}
          />
          {!isArrayEmpty(surveyReference) && (
            <ReportDetailsTable
              className={classes.topSpacing}
              translations={translations.review.details}
              columns={detailsTableColumns}
              statuses={surveyStatuses}
              details={surveyReference}
              getRedirectLinkPath={getSurveyLinkPath}
              isTimeline
            />
          )}
        </div>
      )}
    </PageContainer>
  );
};

AttributeReportPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
};

export default memo(AttributeReportPage);
