import { connect } from 'react-redux';
import CompetenceMapPage from '../../components/pages/competenceMapPage';
import PageContainer from '../../components/shared/pageContainer';
import {
  getMeasuredAttributes,
  getAttributesScoreDistribution,
  getAttributesUsersScores,
  clearAttributesScoreDistribution,
  clearMeasuredAttributes,
  clearAttributesUsersScores,
} from '../../store/modules/competenceMap';
import { competenceMapSelector } from '../../store/selectors/competenceMapSelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...competenceMapSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getMeasuredAttributes: data => getMeasuredAttributes(dispatch, data),
  getAttributesScoreDistribution: data =>
    getAttributesScoreDistribution(dispatch, data),
  getAttributesUsersScores: data => getAttributesUsersScores(dispatch, data),
  clearMeasuredAttributes: () => dispatch(clearMeasuredAttributes()),
  clearAttributesScoreDistribution: () =>
    dispatch(clearAttributesScoreDistribution()),
  clearAttributesUsersScores: () => dispatch(clearAttributesUsersScores()),
});

const CompetenceMapPageView = props => {
  const translations = useTranslations(APP_PAGES.COMPETENCE_MAP);

  return (
    <PageContainer {...props} translations={translations}>
      <CompetenceMapPage />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenceMapPageView);
