import { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import NotificationCard from '../../shared/notificationCard';
import SectionLoader from '../../shared/sectionLoader';
import DashboardSection from '../../shared/dashboardSection';
import CustomButton from '../../shared/customButton';
import ActionButton from '../../shared/actionButton';
import CourseMiniCard from '../../shared/courseMiniCard';
import OneOnOneMiniCard from '../../shared/oneOnOneMiniCard';
import TaskCard from '../../shared/taskCard';
import TeamUserStatsCard from '../../shared/teamUserStatsCard';
import PlanMiniCard from '../../shared/planMiniCard';
import ToDoCard from '../../shared/toDoCard';
import AlertDialog from '../../shared/alertDialog';
import ManageActionPlan from '../../shared/manageActionPlan';
import AiSuggestionsDialog from '../../shared/aiSuggestionsDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import ManageOneOnOne from '../../shared/manageOneOnOne';
import { ReactComponent as CleanIcon } from '../../../assets/icons/cleaning.svg';
import { ReactComponent as TasksIcon } from '../../../assets/icons/clipboard.svg';
import { ReactComponent as CoursesIcon } from '../../../assets/icons/school-dark.svg';
import { ReactComponent as PlansIcon } from '../../../assets/icons/assignment_darker.svg';
import { ReactComponent as OneOnOneIcon } from '../../../assets/icons/people-dark.svg';
import { ReactComponent as MyTeamIcon } from '../../../assets/icons/groups.svg';
import { dashboardSelector } from '../../../store/selectors/dashboardSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import { personSelector } from '../../../store/selectors/personSelector';
import { coursesSelector } from '../../../store/selectors/coursesSelector';
import { oneOnOneTopicsSelector } from '../../../store/selectors/oneOnOneTopicsSelector';
import {
  getMyOneOnOne,
  clearMyOneOnOne,
  getMyOneOnOneRequest,
  clearMyOneOnOneRequest,
  getMyActionPlans,
  clearMyActionPlans,
  getMyCourses,
  clearMyCourses,
  getMyTasks,
  clearMyTasks,
  getMyToDo,
  getMyTeamStats,
  clearMyToDo,
  clearMyTeamStats,
  clearAllLoaders,
  setLoaders,
  changeTaskStatus,
} from '../../../store/modules/dashboard';
import {
  getOneOnOneTopics,
  clearOneOnOneTopics,
} from '../../../store/modules/oneOnOneTopics';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../../store/modules/tags';
import { getPerson, clearPerson } from '../../../store/modules/person';
import { setIsInlineSurvey } from '../../../store/modules/fillSurvey';
import { getCourses, clearCourses } from '../../../store/modules/courses';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../../store/modules/courseDetails';
import { useTranslations } from '../../../utility/useTranslations';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  isArrayEmpty,
  checkUserRole,
  trimString,
  isObject,
  isObjectEmpty,
} from '../../../utility/helpers';
import { hasNextPage, showSuccessMessage } from '../../../utility/uiUtils';
import {
  prepareCourses,
  prepareEnrollmentStatuses,
} from '../../../utility/courseUtils';
import http from '../../../utility/http';
import {
  prepareOneOnOneRecordsData,
  prepareEmployeeTopicData,
  getEmployeeTopicSidebarTranslations,
  getEmployeeTopicFields,
} from '../../../utility/oneOnOne';
import {
  preparePlansData,
  getActionPlanStatuses,
} from '../../../utility/actionPlans';
import { prepareToDoItems } from '../../../utility/dashboardUtils';
import { getTranslatedSurveyTypes } from '../surveysPage/config';
import {
  API_AI,
  API_ONE_ON_ONE_MY_HISTORY,
  API_ONE_ON_ONE_USER_REQUEST,
  API_ONE_ON_ONE_TOPICS,
  api_get_survey_tracking_by_id,
  api_course_users,
  api_one_on_one_topic,
  api_action_plan,
  api_one_on_one_record,
} from '../../../constants/apiRoutes';
import { INITIAL_TOPIC_DATA } from '../../../constants/oneOnOne';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { FEATURE_COLORS } from '../../../constants/appConfig';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { APP_PAGES } from '../../../constants/pages';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { DASHBOARD_DEFAULT_PARAMS } from '../../../constants/dashboard';

const useStyles = makeStyles(
  ({ palette: { primary }, breakpoints, spacing }) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
      gridAutoRows: '280px',
      gridGap: 16,
      paddingTop: spacing(8),
      [breakpoints.up('xLg')]: {
        gridGap: 32,
      },
    },
    fullHeight: {
      justifyContent: 'center',
      height: '100%',
    },
    twoColumns: {
      gridColumn: 'span 2',
    },
    fullRow: {
      gridColumn: 'span 3',
    },
    tasksSection: {
      gridRow: 'span 2;',
    },
    noDataIcon: {
      height: 22,
      width: 'auto',
    },
    loaderWrapper: {
      backgroundColor: primary.bluish8,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },
    aiButton: {
      width: 32,
      height: 32,
      marginRight: spacing(-2),
    },
    aiButtonIcon: {
      width: 18,
      height: 18,
      '& path': {
        fill: primary.blue1,
      },
    },
  })
);

const DashboardPage = ({ navigate, auth, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.DASHBOARD);

  const [myCoursesPage, setMyCoursesPage] = useState(1);
  const [myPlansPage, setMyPlansPage] = useState(1);
  const [myOneOnOnePage, setMyOneOnOnePage] = useState(1);
  const [hasUpcomingOneOnOne, setHasUpcomingOneOnOne] = useState(false);
  const [isEmployeeTopicOpened, setIsEmployeeTopicOpened] = useState(false);
  const [employeeTopic, setEmployeeTopic] = useState(INITIAL_TOPIC_DATA);
  const [isDeleteRequestOpened, setIsDeleteRequestOpened] = useState(false);
  const [isDismissRequestOpened, setIsDismissRequestOpened] = useState(false);
  const [oneOnOneRequest, setOneOnOneRequest] = useState({});
  const [isAcceptRequestOpened, setIsAcceptRequestOpened] = useState(false);
  const [myTasksPage, setMyTasksPage] = useState(1);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isPlanProposalOpened, setIsPlanProposalOpened] = useState(false);
  const [isDismissProposalOpened, setIsDismissProposalOpened] = useState(false);
  const [planProposal, setPlanProposal] = useState(null);
  const [isPlanProposal, setIsPlanProposa] = useState(false);
  const [isAiSuggestionsOpened, setIsAiSuggestionsOpened] = useState(false);
  const [isAiSuggestionsLoading, setIsAiSuggestionsLoading] = useState(false);
  const [aiSuggestions, setAiSuggestions] = useState([]);

  const dispatch = useDispatch();
  const {
    myToDo,
    myTasks,
    myCourses,
    myPlans,
    myOnOneRequest,
    myOneOnOne,
    myTeamStats,
    loadings,
  } = useSelector(dashboardSelector);
  const { categories } = useSelector(tagsSelector);
  const { courses } = useSelector(coursesSelector);
  const { topics } = useSelector(oneOnOneTopicsSelector);
  const myManager = useSelector(personSelector);

  const {
    myToDoLoader,
    myTasksLoader,
    myCoursesLoader,
    myPlansLoader,
    myOneOnOneLoader,
    myTeamStatsLoader,
  } = loadings;
  const isUser = checkUserRole(auth.role, ROLES.USER);
  const hasReporters = !isUser && !isArrayEmpty(auth.reporters);
  const hasTasks = !isArrayEmpty(myTasks.results);
  const hasToDo = !isArrayEmpty(myToDo);
  const hasCourses = !isArrayEmpty(myCourses.results);
  const hasPlans = !isArrayEmpty(myPlans.results);
  const hasOneOnOneRequest = !isObjectEmpty(myOnOneRequest);
  const hasOneOnOne = !isArrayEmpty(myOneOnOne.results) || hasOneOnOneRequest;
  const hasOneOnOneRequestButton =
    !myOneOnOneLoader && !isObjectEmpty(myManager);
  const hasTeam = !isArrayEmpty(myTeamStats);
  const planStatuses = getActionPlanStatuses(translations.plans.plan.statuses);
  const courseStatuses = prepareEnrollmentStatuses(
    translations.courses.statuses
  );
  const surveyTypes = getTranslatedSurveyTypes(translations.toDo.survey);

  const getDashboardData = useCallback(() => {
    Promise.all([
      getMyOneOnOneRequest(dispatch),
      getMyOneOnOne(dispatch),
      getPerson(dispatch, auth.id).then(({ data }) => {
        if (data?.report_to && !data?.report_to_organization) {
          return getPerson(dispatch, data?.report_to);
        }

        return dispatch(clearPerson());
      }),
    ]).finally(() => dispatch(setLoaders({ myOneOnOneLoader: false })));
    getMyActionPlans(dispatch, auth.id);
    getMyTasks(dispatch, auth.id);
    getMyCourses(dispatch);
    getMyTeamStats(dispatch);
    getMyToDo(dispatch);
    getTagsCategories(dispatch);
    getOneOnOneTopics(dispatch);
  }, [dispatch, auth]);

  const cleanup = useCallback(() => {
    dispatch(clearMyActionPlans());
    dispatch(clearMyCourses());
    dispatch(clearMyOneOnOne());
    dispatch(clearMyToDo());
    dispatch(clearMyTeamStats());
    dispatch(clearAllLoaders());
    dispatch(clearPerson());
    dispatch(clearMyOneOnOneRequest());
    dispatch(clearMyTasks());
    dispatch(clearTagsCategories());
    dispatch(clearCourses());
    dispatch(clearCourseEnrolledUsers());
    clearOneOnOneTopics(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const onGoToUserProfile = (userId, section) =>
    navigate(`/people/${userId}/${section}`);

  const onViewCourseDetails = courseId => navigate(`/learning/${courseId}`);

  const onLoadMoreCourses = () => {
    const page = myCoursesPage + 1;

    setMyCoursesPage(page);
    return getMyCourses(dispatch, { page }, true);
  };

  const onGetCourses = params => getCourses(dispatch, params);

  const onGetAiSuggestions = async () => {
    try {
      setIsAiSuggestionsLoading(true);
      setIsAiSuggestionsOpened(true);

      const { data } = await http.get(API_AI);

      setAiSuggestions(data?.choices || []);
      setIsAiSuggestionsLoading(false);
    } catch {
      setIsAiSuggestionsLoading(false);
      setIsAiSuggestionsOpened(false);
    }
  };

  const onCloseAiSuggestions = () => {
    setIsAiSuggestionsOpened(false);
    setAiSuggestions([]);
  };

  const onGetCourseEnrolledUsers = (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params);

  const onCoursesSearch = value => {
    const text = trimString(value);
    const params = {
      ...(text ? { search: text } : {}),
    };

    return onGetCourses(params);
  };

  const onCourseStatusChange = (courseId, statusId) => {
    return http
      .patch(`${api_course_users(courseId)}${auth.id}/`, {
        status: statusId,
      })
      .then(() => showSuccessMessage(translations.courses.successStatus))
      .finally(() => {
        setMyCoursesPage(DASHBOARD_DEFAULT_PARAMS.page);
        getMyCourses(dispatch, { page: DASHBOARD_DEFAULT_PARAMS.page });
      });
  };

  const onViewOneOnOneDetails = oneOnOneId =>
    navigate(`/1-1/${oneOnOneId}/details`);

  const onLoadMoreOneOnOne = () => {
    const page = myOneOnOnePage + 1;

    setMyOneOnOnePage(page);
    return getMyOneOnOne(dispatch, { page }, true);
  };

  const onOpenOneOnOneRequest = () => {
    setIsEmployeeTopicOpened(true);
    setEmployeeTopic(
      prepareEmployeeTopicData(
        { moderator: myManager, ...myOnOneRequest },
        auth.id
      )
    );
  };

  const onCloseOneOnOneRequest = () => {
    setIsEmployeeTopicOpened(false);
    setEmployeeTopic(INITIAL_TOPIC_DATA);
  };

  const onToggleUpcomingOneOnOneDialog = () =>
    setHasUpcomingOneOnOne(prevUpcoming => !prevUpcoming);

  const onConfirmUpcomingMeetingDialog = () => {
    onToggleUpcomingOneOnOneDialog();
    onOpenOneOnOneRequest();
  };

  const onAddOneOnOneRequest = () => {
    return http
      .get(API_ONE_ON_ONE_MY_HISTORY, {
        params: { completion_status: 'upcoming' },
      })
      .then(({ data }) => {
        const hasUpcoming = data.results.some(
          m => m.moderator?.id === myManager.id
        );

        return hasUpcoming
          ? onToggleUpcomingOneOnOneDialog()
          : onOpenOneOnOneRequest();
      });
  };

  const onSaveOneOnOneRequest = topic => {
    if (topic.id) {
      return http
        .patch(api_one_on_one_topic(topic.id), {
          name: topic.name,
        })
        .then(() => {
          getMyOneOnOneRequest(dispatch);
          showSuccessMessage(
            translations.oneOnOne.successMessages.meetingRequestUpdated
          );
        });
    }

    return http
      .post(API_ONE_ON_ONE_TOPICS, { name: topic.name })
      .then(({ data }) => {
        return http
          .post(API_ONE_ON_ONE_USER_REQUEST, {
            agenda: [data.id],
          })
          .then(() => {
            setMyOneOnOnePage(DASHBOARD_DEFAULT_PARAMS.page);
            showSuccessMessage(
              translations.oneOnOne.successMessages.meetingRequestAdded
            );
            getMyOneOnOneRequest(dispatch);
            getMyOneOnOne(dispatch, {
              page: DASHBOARD_DEFAULT_PARAMS.page,
            });
          });
      });
  };

  const onDeleteRequestOpen = () => {
    setEmployeeTopic(
      prepareEmployeeTopicData(
        { moderator: myManager, ...myOnOneRequest },
        auth.id
      )
    );
    setIsDeleteRequestOpened(true);
  };

  const onCloseDeleteRequestDialog = () => setIsDeleteRequestOpened(false);

  const onDeleteRequest = async () => {
    try {
      await http.delete(API_ONE_ON_ONE_USER_REQUEST);
      onCloseDeleteRequestDialog();
      dispatch(clearMyOneOnOneRequest());
      showSuccessMessage(
        translations.oneOnOne.deleteMeetingRequestDialog.deleteSuccess
      );
    } catch {
      onCloseDeleteRequestDialog();
    }
  };

  const onOpenDismissRequest = requestId => {
    setOneOnOneRequest(requestId);
    setIsDismissRequestOpened(true);
  };

  const onCloseDismissRequest = () => {
    setIsDismissRequestOpened(false);
    setOneOnOneRequest({});
  };

  const onDismissRequest = async () => {
    try {
      await http.delete(api_one_on_one_record(oneOnOneRequest));
      onCloseDismissRequest();
      showSuccessMessage(
        translations.oneOnOne.dismissOneOnOneRequestDialog.dismissSuccess
      );
      getMyToDo(dispatch);
    } catch {
      onCloseDismissRequest();
      setPlanProposal(null);
    }
  };

  const onAcceptRequest = async requestId => {
    const { data } = await http.get(api_one_on_one_record(requestId));

    setIsAcceptRequestOpened(true);
    setOneOnOneRequest(data);
  };

  const onCloseRequest = () => {
    setIsAcceptRequestOpened(false);
    setOneOnOneRequest({});
  };

  const onSaveAcceptedRequest = async values => {
    const { user, hasCustomTopic, customTopic, ...requestData } = values;
    const payload = { ...requestData };

    if (hasCustomTopic) {
      const { data } = customTopic?.id
        ? await http.patch(api_one_on_one_topic(customTopic?.id), {
            name: customTopic?.name,
          })
        : await http.post(API_ONE_ON_ONE_TOPICS, {
            name: customTopic?.name,
          });

      if (data?.id) {
        payload.agenda = [...payload.agenda, data.id];
      }
    }

    await http.patch(api_one_on_one_record(oneOnOneRequest.id), {
      ...payload,
      user: user.id,
    });

    onCloseRequest();
    showSuccessMessage(translations.oneOnOne.successMessages.meetingCreated);

    return getMyToDo(dispatch);
  };

  const onViewPlanDetails = planId =>
    navigate(`/action-plans/${planId}/details`);

  const onLoadMorePlans = () => {
    const page = myPlansPage + 1;

    setMyPlansPage(page);
    return getMyActionPlans(dispatch, auth.id, { page }, true);
  };

  const onOpenPlanProposal = () => {
    setIsPlanProposalOpened(true);
    setIsPlanProposa(true);
  };

  const onAcceptProposal = async proposalId => {
    const { data } = await http.get(api_action_plan(proposalId));

    setIsPlanProposalOpened(true);
    setPlanProposal(data);
  };

  const onClosePlanProposal = () => {
    setIsPlanProposalOpened(false);
    setIsPlanProposa(false);
    setPlanProposal(null);
  };

  const onSavePlan = () => {
    if (isPlanProposal) {
      onClosePlanProposal();
      setMyPlansPage(DASHBOARD_DEFAULT_PARAMS.page);
      return getMyActionPlans(dispatch, auth.id);
    }
    onClosePlanProposal();
    return getMyToDo(dispatch);
  };

  const onDismissProposal = proposal => {
    setPlanProposal(proposal);
    setIsDismissProposalOpened(true);
  };

  const onCloseDismissProposal = () => {
    setIsDismissProposalOpened(false);
    setPlanProposal(null);
  };

  const onDeleteProposal = async () => {
    try {
      await http.delete(api_action_plan(planProposal.id));
      onCloseDismissProposal();
      showSuccessMessage(
        translations.plans.dismissActionPlanProposalDialog.deleteSuccess
      );
      getMyToDo(dispatch);
    } catch {
      onCloseDismissProposal();
    }
  };

  const onLoadMoreTasks = () => {
    const page = myTasksPage + 1;

    setMyTasksPage(page);
    return getMyTasks(dispatch, auth.id, { page }, true);
  };

  const onCloseTaskStatus = () => setSelectedTask(null);

  const onTaskStatusChange = task => setSelectedTask(task);

  const onConfirmTaskStatus = () => {
    changeTaskStatus(dispatch, selectedTask.id);
    onCloseTaskStatus();
    showSuccessMessage(translations.tasks.taskStatusDialog.success);
  };

  const onCreateTag = async tagName => {
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    dispatch(addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags }));

    return tag;
  };

  const onFillSurvey = async surveyId => {
    const { data } = await http.get(api_get_survey_tracking_by_id(surveyId));
    const trackingToken =
      data && data.tracking_token ? data.tracking_token : '';

    if (trackingToken) {
      dispatch(setIsInlineSurvey(true));

      navigate(`/fill-survey/${trackingToken}`);
    }
  };

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      shouldPassProps={false}
      isFullWidthContent
    >
      <div className={classes.root}>
        <div className={classes.twoColumns}>
          <DashboardSection
            translations={translations.toDo}
            color={FEATURE_COLORS.TO_DO}
            itemPropKey="data"
            itemComponent={ToDoCard}
            items={prepareToDoItems(myToDo, surveyTypes, auth)}
            shouldFade={!myToDoLoader && hasToDo}
            onFillSurvey={onFillSurvey}
            onAcceptProposal={onAcceptProposal}
            onDismissProposal={onDismissProposal}
            onAcceptRequest={onAcceptRequest}
            onDismissRequest={onOpenDismissRequest}
          />
          <NotificationCard
            className={classes.fullHeight}
            title={translations.toDo.noData.title}
            content={translations.toDo.noData.message}
            customIcon={CleanIcon}
            shouldFade={!myToDoLoader && !hasToDo}
            hasIcon
          />
          {myToDoLoader && (
            <div className={classes.loaderWrapper}>
              <SectionLoader isLoading={myToDoLoader} relativePosition />
            </div>
          )}
        </div>
        <div className={classes.tasksSection}>
          <DashboardSection
            translations={translations.tasks}
            color={FEATURE_COLORS.TASKS}
            itemPropKey="task"
            itemComponent={TaskCard}
            items={myTasks?.results}
            currentUser={auth}
            shouldFade={!myTasksLoader && hasTasks}
            hasLoadMore={hasNextPage(myTasks)}
            onLoadMoreItems={onLoadMoreTasks}
            onChange={onTaskStatusChange}
            isVertical
          />
          <NotificationCard
            className={classes.fullHeight}
            iconClass={classes.noDataIcon}
            title={translations.tasks.title}
            content={translations.tasks.noData}
            customIcon={TasksIcon}
            shouldFade={!myTasksLoader && !hasTasks}
            hasIcon
          />
          {myTasksLoader && (
            <div className={classes.loaderWrapper}>
              <SectionLoader isLoading={myTasksLoader} relativePosition />
            </div>
          )}
        </div>
        <div className={classes.twoColumns}>
          <DashboardSection
            translations={translations.oneOnOne}
            color={FEATURE_COLORS.ONE_ON_ONE}
            itemPropKey="oneOnOne"
            itemComponent={OneOnOneMiniCard}
            items={prepareOneOnOneRecordsData(
              [
                ...(hasOneOnOneRequest
                  ? [{ ...myOnOneRequest, isPendingRequest: true }]
                  : []),
                ...myOneOnOne.results,
              ],
              auth
            )}
            actionButtons={[
              ...(hasOneOnOneRequestButton
                ? [
                    {
                      id: 'request',
                      button: CustomButton,
                      type: 'rounded',
                      text: translations.oneOnOne.requestButton,
                      disabled: hasOneOnOneRequest,
                      onClick: onAddOneOnOneRequest,
                    },
                  ]
                : []),
            ]}
            myManager={myManager}
            currentPage={myOneOnOnePage}
            hasLoadMore={hasNextPage(myOneOnOne)}
            shouldFade={!myOneOnOneLoader && hasOneOnOne}
            onDeleteRequest={onDeleteRequestOpen}
            onEditRequest={onOpenOneOnOneRequest}
            onLoadMoreItems={onLoadMoreOneOnOne}
            onCardClick={onViewOneOnOneDetails}
          />
          <NotificationCard
            className={classes.fullHeight}
            iconClass={classes.noDataIcon}
            title={translations.oneOnOne.title}
            content={translations.oneOnOne.noData}
            customIcon={OneOnOneIcon}
            hasIcon
            shouldFade={!myOneOnOneLoader && !hasOneOnOne}
          />
          {myOneOnOneLoader && (
            <div className={classes.loaderWrapper}>
              <SectionLoader isLoading={myOneOnOneLoader} relativePosition />
            </div>
          )}
        </div>
        <div className={classes.twoColumns}>
          <DashboardSection
            translations={translations.plans}
            color={FEATURE_COLORS.PLANS}
            itemPropKey="plan"
            itemComponent={PlanMiniCard}
            items={preparePlansData(myPlans.results, auth, planStatuses)}
            actionButtons={[
              {
                id: 'propose',
                button: CustomButton,
                type: 'rounded',
                text: translations.plans.proposeButton,
                onClick: onOpenPlanProposal,
              },
            ]}
            currentPage={myPlansPage}
            shouldFade={!myPlansLoader && hasPlans}
            hasLoadMore={hasNextPage(myPlans)}
            onCardClick={onViewPlanDetails}
            onLoadMoreItems={onLoadMorePlans}
          />
          <NotificationCard
            className={classes.fullHeight}
            iconClass={classes.noDataIcon}
            title={translations.plans.title}
            content={translations.plans.noData}
            customIcon={PlansIcon}
            shouldFade={!myPlansLoader && !hasPlans}
            hasIcon
          />
          {myPlansLoader && (
            <div className={classes.loaderWrapper}>
              <SectionLoader isLoading={myPlansLoader} relativePosition />
            </div>
          )}
        </div>
        <div>
          <DashboardSection
            translations={translations.courses}
            color={FEATURE_COLORS.COURSES}
            itemPropKey="course"
            itemComponent={CourseMiniCard}
            items={
              hasCourses
                ? prepareCourses(myCourses.results, courseStatuses)
                : []
            }
            statuses={courseStatuses}
            actionButtons={[
              {
                id: 'ai',
                className: classes.aiButton,
                iconClass: classes.aiButtonIcon,
                button: ActionButton,
                type: ACTION_BUTTON_TYPES.AI,
                tooltipText: translations.courses.aiButtonTooltip,
                onClickHandler: onGetAiSuggestions,
              },
            ]}
            shouldFade={!myCoursesLoader && hasCourses}
            hasLoadMore={hasNextPage(myCourses)}
            onStatusChange={onCourseStatusChange}
            onCardClick={onViewCourseDetails}
            onLoadMoreItems={onLoadMoreCourses}
          />
          <NotificationCard
            className={classes.fullHeight}
            iconClass={classes.noDataIcon}
            title={translations.courses.title}
            content={translations.courses.noData}
            customIcon={CoursesIcon}
            shouldFade={!myCoursesLoader && !hasCourses}
            hasIcon
          />
          {myCoursesLoader && (
            <div className={classes.loaderWrapper}>
              <SectionLoader isLoading={myCoursesLoader} relativePosition />
            </div>
          )}
        </div>
        {hasReporters && (
          <div className={classes.fullRow}>
            <DashboardSection
              translations={translations.myTeam}
              color={FEATURE_COLORS.TEAM}
              itemPropKey="user"
              itemComponent={TeamUserStatsCard}
              items={myTeamStats}
              shouldFade={!myTeamStatsLoader && hasTeam}
              onGoToUserProfile={onGoToUserProfile}
            />
            <NotificationCard
              className={classes.fullHeight}
              iconClass={classes.noDataIcon}
              title={translations.myTeam.title}
              content={translations.myTeam.noData}
              customIcon={MyTeamIcon}
              shouldFade={!myTeamStatsLoader && !hasTeam}
              hasIcon
            />
            {myTeamStatsLoader && (
              <div className={classes.loaderWrapper}>
                <SectionLoader isLoading={myTeamStatsLoader} relativePosition />
              </div>
            )}
          </div>
        )}
        <CustomFormDrawer
          translations={getEmployeeTopicSidebarTranslations(
            translations.oneOnOne.manageTopicForm,
            !!employeeTopic?.name,
            true
          )}
          isOpened={isEmployeeTopicOpened}
          isInitialValid={!!employeeTopic?.id}
          initialData={employeeTopic}
          fields={getEmployeeTopicFields()}
          onClose={onCloseOneOnOneRequest}
          onSave={onSaveOneOnOneRequest}
          hideDelete
        />
        <ManageActionPlan
          translations={translations.plans.manageActionPlan}
          isOpen={isPlanProposalOpened}
          statuses={planStatuses}
          user={auth}
          categories={categories}
          courses={courses.results}
          isPlanProposal={isPlanProposal}
          actionPlan={planProposal || {}}
          isInitialValid={!!isPlanProposal}
          getCourses={onGetCourses}
          getCourseEnrolledUsers={onGetCourseEnrolledUsers}
          onCoursesSearch={onCoursesSearch}
          onCreateTag={onCreateTag}
          onClose={onClosePlanProposal}
          onConfirm={onSavePlan}
          hideDelete
        />
        <ManageOneOnOne
          translations={translations.oneOnOne.oneOnOneRecordDialog}
          isOpened={isAcceptRequestOpened}
          topics={topics}
          record={
            isObject(oneOnOneRequest) && !isObjectEmpty(oneOnOneRequest)
              ? oneOnOneRequest
              : {}
          }
          createdFor={oneOnOneRequest?.user}
          currentUser={auth}
          categories={categories}
          onCreateTag={onCreateTag}
          onClose={onCloseRequest}
          onSave={onSaveAcceptedRequest}
          isOneOnOneRequest
        />
        <AiSuggestionsDialog
          translations={translations.courses.aiSuggestionsDialog}
          isOpened={isAiSuggestionsOpened}
          isLoading={isAiSuggestionsLoading}
          suggestions={aiSuggestions}
          onClose={onCloseAiSuggestions}
        />
        <AlertDialog
          translations={{
            ...translations.tasks.taskStatusDialog,
            contentMessage: selectedTask?.is_finished
              ? translations.tasks.taskStatusDialog.contentMessageUncheck
              : translations.tasks.taskStatusDialog.contentMessageCheck,
          }}
          isOpened={!!selectedTask}
          onClose={onCloseTaskStatus}
          onConfirm={onConfirmTaskStatus}
        />
        <AlertDialog
          translations={translations.oneOnOne.upcomingMeetingDialog}
          isOpened={hasUpcomingOneOnOne}
          onClose={onToggleUpcomingOneOnOneDialog}
          onConfirm={onConfirmUpcomingMeetingDialog}
        />
        <AlertDialog
          translations={translations.oneOnOne.deleteMeetingRequestDialog}
          isOpened={isDeleteRequestOpened}
          onClose={onCloseDeleteRequestDialog}
          onConfirm={onDeleteRequest}
          isWarning
        />
        <AlertDialog
          translations={translations.plans.dismissActionPlanProposalDialog}
          isOpened={isDismissProposalOpened}
          onClose={onCloseDismissProposal}
          onConfirm={onDeleteProposal}
          isWarning
        />
        <AlertDialog
          translations={translations.oneOnOne.dismissOneOnOneRequestDialog}
          isOpened={isDismissRequestOpened}
          onClose={onCloseDismissRequest}
          onConfirm={onDismissRequest}
          isWarning
        />
      </div>
    </PageContainer>
  );
};

DashboardPage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default memo(DashboardPage);
