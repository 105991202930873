import { connect } from 'react-redux';
import ProtectedRoute from 'components/shared/protectedRoute';
import { setAuthInfo } from '../../store/modules/auth';
import { authSelector } from '../../store/selectors/authSelector';
import { organizationSettingsSelector } from '../../store/selectors/organizationSettingsSelector';
import { grantedPermissionsSelector } from '../../store/selectors/grantedPermissionsSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { setDialogVisibility } from '../../store/modules/dialogs';

const mapStateToProps = state => ({
  ...authSelector(state),
  ...grantedPermissionsSelector(state),
  ...organizationSettingsSelector(state),
  ...dialogSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthInfo: data => dispatch(setAuthInfo(data)),
  setDialogVisibility: data => dispatch(setDialogVisibility(data)),
});

const ProtectedRouteView = props => {
  return <ProtectedRoute {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRouteView);
