import { cloneElement, memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { hasActiveSubscription } from 'utility/subscriptionHelper';
import PageHeader from '../pageHeader';
import { showErrorMessage } from '../../../utility/uiUtils';
import http, { getHeaders } from '../../../utility/http';
import StorageService from '../../../utility/storageService';
import { STRIPE_BUSINESS_PRICE_ID } from '../../../constants/appConfig';
import { API_CUSTOMER_PORTAL } from '../../../constants/apiRoutes';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_SMALL,
} from '../../../constants/sidebarNavigation';
import { getUserMenuItems } from './config';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginLeft: SIDEBAR_WIDTH_SMALL,
    width: `calc(100vw - ${SIDEBAR_WIDTH_SMALL}px)`,
    [breakpoints.up('xl')]: {
      marginLeft: SIDEBAR_WIDTH,
      width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
    },
  },
  mainWrapper: {
    display: 'flex',
    height: '100vh',
  },
  main: {
    flexGrow: 1,
    padding: spacing(0, 4.5, 0, 4.5),
    height: '100%',
    overflowY: 'auto',
    [breakpoints.up('xLg')]: {
      padding: spacing(0, 10, 0, 6),
    },
    [breakpoints.up('xl')]: {
      padding: spacing(0, 12, 0, 12),
    },
  },
  content: {
    width: '100%',
    maxWidth: 900,
    paddingBottom: spacing(12),
  },
  mediumWidthContent: {
    maxWidth: 1350,
  },
  fullWidthContent: {
    maxWidth: '100%',
  },
  noContentSpacing: {
    paddingBottom: 0,
  },
}));

const PageContainer = ({
  translations,
  children,
  auth,
  setAuthInfo,
  grantedPermissions,
  organizationSettings,
  headerProps,
  isLoading,
  personNavItems,
  isFullWidthContent,
  isMediumWidthContent,
  shouldRemoveSpacing,
  location,
  navigate,
  shouldPassProps,
  ...rest
}) => {
  const classes = useStyles();
  const { shouldHideUpgrade } = headerProps;
  const [anchorSubEl, setAnchorSubEl] = useState(null);
  const { subscription_end_at, subscription, global_see_himself, user_count } =
    organizationSettings;

  const hasSubscription = hasActiveSubscription(
    subscription,
    subscription_end_at
  );
  const organizationData = {
    hasSubscription,
    userCount: user_count,
    globalSeeHimself: global_see_himself,
    stripeCustomer: organizationSettings?.stripe_customer,
  };

  const handleOpenUpgradeDialog = event => {
    setAnchorSubEl(event.currentTarget);
  };

  const handleCloseUpgradeDialog = () => {
    setAnchorSubEl(null);
  };

  const goToProfilePage = userId => navigate(`/people/${userId}`);

  const onGoToPricingPlan = () =>
    navigate(`/pricing-plans/${STRIPE_BUSINESS_PRICE_ID}`);

  const onRedirect = url => () => navigate(url);

  const onLogout = () => {
    StorageService.clear();
    http.defaults.headers = getHeaders(StorageService);
    setAuthInfo({});
    navigate('/login');
  };

  const onManageSubscription = () => {
    http
      .post(API_CUSTOMER_PORTAL, {
        return_url: `${window.location.origin}${window.location.pathname}`,
      })
      .then(({ data }) => {
        if (data.customer_portal_url) {
          window.location.href = data.customer_portal_url;
        } else if (data.stripe_customer) {
          showErrorMessage(data.stripe_customer);
        } else {
          showErrorMessage('Something goes wrong.');
        }
      });
  };

  return (
    <div className={classes.root} id="pageContainer">
      <div className={classes.mainWrapper}>
        <div className={classes.main}>
          {!isLoading && (
            <PageHeader
              translations={translations.pageHeader}
              title={translations.title}
              user={auth}
              permissions={grantedPermissions}
              organizationData={organizationData}
              navItems={personNavItems}
              userMenuItems={getUserMenuItems(
                translations.pageHeader.userMenu,
                location.pathname,
                grantedPermissions,
                organizationData,
                onRedirect,
                onLogout,
                onManageSubscription,
                shouldHideUpgrade,
                handleOpenUpgradeDialog
              )}
              onGoToProfile={goToProfilePage}
              onGoToPricingPlan={onGoToPricingPlan}
              anchorSubEl={anchorSubEl}
              handleClose={handleCloseUpgradeDialog}
              {...headerProps}
            />
          )}
          <div
            className={classNames(classes.content, {
              [classes.mediumWidthContent]: isMediumWidthContent,
              [classes.fullWidthContent]: isFullWidthContent,
              [classes.noContentSpacing]: shouldRemoveSpacing,
            })}
          >
            {shouldPassProps
              ? cloneElement(children, {
                  location,
                  navigate,
                  translations,
                  auth,
                  grantedPermissions,
                  organizationSettings,
                  goToProfilePage,
                  ...rest,
                })
              : children}
          </div>
        </div>
      </div>
    </div>
  );
};

PageContainer.defaultProps = {
  isFullWidthContent: false,
  isMediumWidthContent: false,
  personNavItems: [],
  headerProps: {},
  shouldRemoveSpacing: false,
  isLoading: false,
  shouldPassProps: true,
};

PageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  auth: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isFullWidthContent: PropTypes.bool,
  isMediumWidthContent: PropTypes.bool,
  isLoading: PropTypes.bool,
  shouldRemoveSpacing: PropTypes.bool,
  shouldPassProps: PropTypes.bool,
  headerProps: PropTypes.shape({}),
  personNavItems: PropTypes.arrayOf(PropTypes.shape({})),
  setAuthInfo: PropTypes.func.isRequired,
};

export default memo(PageContainer);
