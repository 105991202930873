import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store/configureStore';
import StorageService from './utility/storageService';
import { setAuthInfo } from './store/modules/auth';
import {
  getOrganizationSettings,
  getOrganizationMenuSetup,
  getAllPresets,
} from './store/modules/organizationSettings';
import http, { ACCESS_TOKEN, TOKEN_TYPE } from './utility/http';
import { API_ME } from './constants/apiRoutes';
import { PERMISSIONS_LIST_BY_ROLE } from './constants/rolesAndPermissionList';
import { mergeAccessibleProfilesForUser } from './utility/uiUtils';
import { initSubscriptionValidation } from './utility/subscriptionHelper';

const AUTH = 'auth';

const initialState = {};

export const history = createBrowserHistory();

export const store = configureStore(initialState, history);

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root')
  );
};

if (StorageService.has(AUTH)) {
  const auth = JSON.parse(StorageService.get(AUTH));
  store.dispatch(setAuthInfo(auth));
  http.defaults.headers.Authorization = `${TOKEN_TYPE} ${auth[ACCESS_TOKEN]}`;

  http.get(API_ME).then(user => {
    const authInfo = {
      ...auth,
      ...user.data,
      permissions: PERMISSIONS_LIST_BY_ROLE[user.data.role],
      accessibleProfiles: mergeAccessibleProfilesForUser(auth),
    };
    store.dispatch(setAuthInfo(authInfo));
    StorageService.set(AUTH, JSON.stringify(authInfo));
    Promise.all([
      getOrganizationSettings(store.dispatch),
      getOrganizationMenuSetup(store.dispatch),
      getAllPresets(store.dispatch),
    ]).then(renderApp, renderApp);
    initSubscriptionValidation(store, getOrganizationSettings);
  }, renderApp);
} else {
  renderApp();
}
