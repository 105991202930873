import { ReactComponent as StatusIcon } from '../../../assets/icons/status-icon.svg';
import { ReactComponent as TypeIcon } from '../../../assets/icons/survey-type-icon.svg';
import { ReactComponent as ReviewersIcon } from '../../../assets/icons/reviewer-icon.svg';
import { ReactComponent as CreatorIcon } from '../../../assets/icons/creator-icon.svg';
import { ReactComponent as SubjectIcon } from '../../../assets/icons/subject-icon.svg';
import http from '../../../utility/http';
import {
  formatDate,
  addDaysFromSpecificHour,
  addHoursToCurrentStartHour,
} from '../../../utility/dateUtils';
import {
  getObjectToNumberArray,
  getItemById,
  trimString,
  isUserSuspended,
  isArrayEmpty,
} from '../../../utility/helpers';
import { getFilterableUsers } from '../../../utility/uiUtils';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../../../utility/validation';
import {
  SURVEY_TYPES,
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TYPES_MAP,
  SURVEY_VIEW_OPTIONS,
} from '../../../constants/survey';

import { API_SURVEY_GROUPS_BASIC } from '../../../constants/apiRoutes';
import {
  SURVEY_STATUSES,
  SURVEY_STATUS_TYPES,
} from '../../../constants/statuses';
import { PARAMS } from '../../../constants/pages';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { USER_INFO } from '../../../constants/people';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const INIT_SORT = {
  column: 'sentAt',
  asc: false,
  sortKey: '-sent_at',
};

export const SURVEY_TITLE_FIELD = {
  name: 'title',
  type: FIELD_TYPES.TEXT,
  translationKey: 'name',
  required: true,
  validators: [
    { type: 'required', validator: isEmpty },
    { type: 'forbiddenCharacters', validator: forbiddenCharacters },
    {
      type: 'maxLength',
      validator: value => validateLength(value, 0, 150),
    },
  ],
};

export const SURVEYS_GROUP_FIELD = {
  name: 'title',
  type: FIELD_TYPES.TEXT,
  translationKey: 'name',
  required: true,
  validators: [
    { type: 'required', validator: isEmpty },
    { type: 'forbiddenCharacters', validator: forbiddenCharacters },
    {
      type: 'maxLength',
      validator: value => validateLength(value, 0, 500),
    },
    {
      type: 'availableTitle',
      async: true,
      validator: value => {
        const params = {
          title: trimString(value),
        };
        return http
          .get(API_SURVEY_GROUPS_BASIC, { params })
          .then(({ data }) => isArrayEmpty(data));
      },
    },
  ],
};

export const getSurveyTitle = (
  defaultTitle,
  attributes = [],
  previousAttributes = [],
  currentTitle = ''
) => {
  const [initialTitle] = defaultTitle.split(' -');
  const [currentTitlePartOne] = currentTitle.split(' -');

  const attributesText =
    attributes.length > 0
      ? attributes.reduce((acc, attribute) => {
          if (acc.length >= 30) {
            return acc;
          }
          return `${acc} ${attribute.code}`;
        }, ' -')
      : '';
  const previousAttributesText =
    previousAttributes.length > 0
      ? previousAttributes.reduce((acc, attribute) => {
          if (acc.length >= 30) {
            return acc;
          }
          return `${acc} ${attribute.code}`;
        }, ' -')
      : '';

  if (
    (currentTitlePartOne && trimString(currentTitlePartOne) !== initialTitle) ||
    (currentTitle &&
      trimString(currentTitle) !== `${initialTitle}${previousAttributesText}`)
  )
    return currentTitle;

  return `${initialTitle}${attributesText}`;
};

export const getTranslatedSurveyTypes = translations => {
  return Object.keys(SURVEY_TYPES_MAP).map(type => ({
    ...SURVEY_TYPES_MAP[type],
    ...translations.surveyTypes[type.toLowerCase()],
  }));
};

export const getSurveyStatuses = labels =>
  SURVEY_STATUSES.map(asu => ({
    ...asu,
    name: labels[asu.key],
  }));

export const getSurveyViewOptions = labels =>
  SURVEY_VIEW_OPTIONS.map(view => ({
    ...view,
    name: labels[view.key],
  }));

export const getActiveUsers = users => {
  return users.filter(user => !isUserSuspended(user));
};

export const getActiveAttributes = attributes => {
  return attributes.filter(attribute => attribute.is_active);
};

const getEmployees = users =>
  users.filter(user => user[USER_INFO.ROLE] !== ROLES.ASSOCIATE);

export const getPageFilters = (translations, users) => {
  const { TYPE, CREATOR, STATUS, REVIEWERS, CREATED_FOR } = PARAMS;
  const types = getTranslatedSurveyTypes(translations);
  const allUsers = getFilterableUsers(users);
  const allEmployees = getEmployees(allUsers);
  const defaultOption = { id: null, name: translations.all };
  const translatedStatuses = getSurveyStatuses(translations.statuses);

  return [
    {
      id: STATUS,
      name: translations.filters.labels[STATUS],
      icon: StatusIcon,
      isSingleSelect: true,
      items: [defaultOption].concat(translatedStatuses),
    },
    {
      id: TYPE,
      name: translations.filters.labels[TYPE],
      icon: TypeIcon,
      isSingleSelect: true,
      items: [{ id: null, name: translations.all }, ...types],
    },
    {
      id: REVIEWERS,
      name: translations.filters.labels[REVIEWERS],
      icon: ReviewersIcon,
      items: allUsers,
    },
    {
      id: CREATOR,
      name: translations.filters.labels[CREATOR],
      icon: CreatorIcon,
      items: allEmployees,
    },
    {
      id: CREATED_FOR,
      name: translations.filters.labels.subject,
      icon: SubjectIcon,
      items: allEmployees,
    },
  ];
};

export const getHeaderActions = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    align: 'right',
    isHeaderAction: true,
    rowCellClass: 'actions-dots-menu',
    isActionCell: true,
    minWidth: 60,
    maxWidth: 80,
  },
];

export const getTableHeaders = (labels, isListView = false) => [
  {
    id: 1,
    title: labels.type,
    rowKey: 'type',
    sortAs: 'type',
    minWidth: 77,
    maxWidth: 77,
  },
  {
    id: 2,
    title: labels.title,
    rowKey: 'title',
    sortAs: 'title',
    isHighlighted: true,
    minWidth: 140,
    maxWidth: '1.5fr',
  },
  ...(isListView
    ? [
        {
          id: 3,
          title: labels.group,
          rowKey: 'group',
          sortAs: 'group',
          hasConditionalTooltip: true,
          maxWidth: '1fr',
        },
      ]
    : []),
  {
    id: 4,
    title: labels.createdBy,
    rowKey: 'createdBy',
    sortAs: 'creator__first_name',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 5,
    title: labels.sentAt,
    rowKey: 'sentAt',
    sortAs: 'sent_at',
    minWidth: 90,
    maxWidth: '1fr',
  },
  {
    id: 6,
    title: labels.expiry,
    rowKey: 'expireAt',
    sortAs: 'expire_at',
    minWidth: 90,
    maxWidth: '1fr',
  },
  {
    id: 7,
    title: labels.status,
    rowKey: 'status',
    isSortingDisabled: true,
    rowCellClass: 'status-cell',
    minWidth: 160,
    maxWidth: '1fr',
  },
];

export const prepareTableData = (
  surveys,
  translations,
  surveyTypes,
  surveyGroups,
  renderTitle,
  renderType,
  renderStatusAndCompleted,
  renderActions,
  isAdmin
) => {
  const translatedStatuses = getSurveyStatuses(translations.statuses);

  return surveys.map(survey => {
    const type = getItemById(surveyTypes, survey.type);
    const status = getItemById(translatedStatuses, survey.status);
    const surveyGroup = surveyGroups.find(group => group.id === survey.group);
    const isDraft = status.id === SURVEY_STATUS_TYPES.DRAFT;

    return {
      id: survey.id,
      creatorId: survey.creator,
      title: renderTitle(survey),
      type: renderType(type),
      createdBy: survey.creator_full_name,
      sentAt: formatDate(survey.sent_at),
      expireAt: formatDate(survey.expire_at),
      status: renderStatusAndCompleted(survey, status, type, isDraft),
      actions: renderActions(survey, isAdmin),
      group: surveyGroup?.title || '',
      isDraft,
    };
  });
};

export const prepareSurveyGroupsData = (
  surveyGroups,
  translations,
  surveyTypes,
  renderTitle,
  renderType,
  renderStatusAndCompleted,
  renderActions,
  renderReportButton,
  isAdmin
) => {
  return surveyGroups.map(group => ({
    ...group,
    surveys: !isArrayEmpty(group.surveys)
      ? prepareTableData(
          group.surveys,
          translations,
          surveyTypes,
          renderTitle,
          renderType,
          renderStatusAndCompleted,
          renderActions,
          renderReportButton,
          isAdmin
        )
      : [],
  }));
};

export const getCreateSurveyInitialData = (
  translations,
  type,
  surveyTypes,
  organizationUser,
  surveyGroup,
  initialData = {}
) => {
  const surveyType = getItemById(surveyTypes, type || SURVEY_TYPES.SELF);
  const {
    TITLE,
    ATTRIBUTES,
    CREATED_FOR,
    REVIEWERS,
    SURVEY_TYPE,
    IS_ANONYMOUS,
    IS_STATISTICAL,
    FILL_INLINE,
    SCHEDULED_FOR,
    IS_SCHEDULE,
    EXPIRATION_DATE,
    SHARE_TO,
    HAS_EMAIL_MESSAGE,
    EMAIL_MESSAGE,
    GROUP,
  } = CREATE_SURVEY_FIELD_NAMES;
  return {
    [TITLE]: getSurveyTitle(surveyType?.name, initialData?.[ATTRIBUTES]),
    [ATTRIBUTES]: [],
    [CREATED_FOR]: [],
    [SHARE_TO]: [],
    [REVIEWERS]: [],
    [SURVEY_TYPE]: type,
    [IS_ANONYMOUS]: false,
    [IS_STATISTICAL]: true,
    [HAS_EMAIL_MESSAGE]: false,
    [EMAIL_MESSAGE]: translations.defaultEmailMessage,
    [FILL_INLINE]: false,
    [SCHEDULED_FOR]: addHoursToCurrentStartHour(),
    [EXPIRATION_DATE]: addDaysFromSpecificHour(),
    [IS_SCHEDULE]: false,
    ...(surveyGroup ? { [GROUP]: surveyGroup.id } : {}),
    company: organizationUser(),
    ...initialData,
  };
};

export const getCreateSurveyColumns = (
  labels,
  defaultEmailMessage,
  surveyType,
  surveyTypes,
  predefinedData,
  onChangeSurveyType
) => {
  const selectedType = getItemById(surveyTypes, surveyType);
  const { CREATED_FOR, SURVEY_TYPE, SHARE_TO, HAS_EMAIL_MESSAGE } =
    CREATE_SURVEY_FIELD_NAMES;

  return (
    selectedType?.columns?.map(column => {
      if (column.id === 1) {
        return {
          ...column,
          title: labels[column.translationKey],
          fields: column.fields.map(field => {
            if (field.name === SURVEY_TYPE) {
              return {
                ...field,
                isDisabled: !!predefinedData?.isDraftActivation,
                callbackFnc: onChangeSurveyType,
              };
            }

            if (
              field.name === SHARE_TO &&
              surveyType !== SURVEY_TYPES.SELF &&
              surveyType !== SURVEY_TYPES.PULSE
            ) {
              return {
                ...field,
                circularDependant: CREATED_FOR,
              };
            }

            if (field.name === HAS_EMAIL_MESSAGE) {
              return {
                ...field,
                dependants: [
                  {
                    name: CREATE_SURVEY_FIELD_NAMES.EMAIL_MESSAGE,
                    value: defaultEmailMessage,
                  },
                ],
              };
            }

            return field;
          }),
        };
      }
      return {
        ...column,
        fields: column.fields,
        title: labels[column.translationKey],
      };
    }) || []
  );
};

export const prepareSurveyDataForCreation = (
  surveyData,
  isGroupView,
  isDraftActivation,
  isDraftCreation
) => {
  const {
    TITLE,
    ATTRIBUTES,
    CREATED_FOR,
    REVIEWERS,
    SURVEY_TYPE,
    IS_ANONYMOUS,
    IS_STATISTICAL,
    FILL_INLINE,
    SCHEDULED_FOR,
    IS_SCHEDULE,
    CLIENT_URL,
    EXPIRATION_DATE,
    SHARE_TO,
    HAS_EMAIL_MESSAGE,
    EMAIL_MESSAGE,
    GROUP,
    STATUS,
    SEND,
  } = CREATE_SURVEY_FIELD_NAMES;
  const { PEER, TEAM, DIRECT } = SURVEY_TYPES;

  return {
    [TITLE]: surveyData[TITLE],
    ...(surveyData[HAS_EMAIL_MESSAGE]
      ? { [EMAIL_MESSAGE]: surveyData[EMAIL_MESSAGE] }
      : {}),
    [IS_ANONYMOUS]: surveyData[IS_ANONYMOUS],
    [IS_STATISTICAL]: surveyData[IS_STATISTICAL],
    [CREATED_FOR]: [DIRECT, PEER].includes(surveyData[SURVEY_TYPE])
      ? surveyData[CREATED_FOR][0].id
      : getObjectToNumberArray(surveyData[CREATED_FOR]),
    [REVIEWERS]: [DIRECT, TEAM].includes(surveyData[SURVEY_TYPE])
      ? surveyData[REVIEWERS][0].id
      : getObjectToNumberArray(surveyData[REVIEWERS]),
    [ATTRIBUTES]: getObjectToNumberArray(surveyData[ATTRIBUTES]),
    [SCHEDULED_FOR]: surveyData[IS_SCHEDULE] ? surveyData[SCHEDULED_FOR] : null,
    [CLIENT_URL]: `${window.location.origin}/fill-survey`,
    [FILL_INLINE]: !isDraftCreation ? surveyData[FILL_INLINE] : false,
    [EXPIRATION_DATE]: !isDraftCreation ? surveyData[EXPIRATION_DATE] : null,
    [SHARE_TO]: surveyData[SHARE_TO],
    ...(isDraftCreation
      ? { [STATUS]: SURVEY_STATUS_TYPES.DRAFT, [SEND]: false }
      : {}),
    ...(isGroupView || isDraftActivation
      ? { ...(surveyData[GROUP] ? { [GROUP]: surveyData[GROUP] } : {}) }
      : {}),
  };
};

export const prepareDuplicateSurveyData = (survey, sharedWith) => {
  const {
    title,
    attributes,
    created_for,
    is_statistical,
    is_anonymous,
    reviewers,
    message,
    group,
    type,
  } = survey;
  const {
    ATTRIBUTES,
    CREATED_FOR,
    REVIEWERS,
    IS_ANONYMOUS,
    IS_STATISTICAL,
    CLIENT_URL,
    EXPIRATION_DATE,
    SHARE_TO,
    EMAIL_MESSAGE,
    GROUP,
    STATUS,
    SEND,
  } = CREATE_SURVEY_FIELD_NAMES;
  const { PEER, TEAM, DIRECT } = SURVEY_TYPES;
  const createdFor = getActiveUsers(created_for);
  const activeReviewers = getActiveUsers(reviewers);

  return {
    title,
    [ATTRIBUTES]: getObjectToNumberArray(getActiveAttributes(attributes)),
    [CREATED_FOR]: [DIRECT, PEER].includes(type)
      ? createdFor[0]?.id || null
      : getObjectToNumberArray(createdFor),
    [IS_STATISTICAL]: is_statistical,
    [IS_ANONYMOUS]: is_anonymous,
    [REVIEWERS]: [DIRECT, TEAM].includes(type)
      ? activeReviewers[0]?.id || null
      : getObjectToNumberArray(activeReviewers),
    [EMAIL_MESSAGE]: message,
    [SHARE_TO]: getObjectToNumberArray(getActiveUsers(sharedWith)),
    ...(group ? { [GROUP]: group } : {}),
    [STATUS]: SURVEY_STATUS_TYPES.DRAFT,
    [SEND]: false,
    [EXPIRATION_DATE]: null,
    [CLIENT_URL]: `${window.location.origin}/fill-survey`,
  };
};

export const getNoResultsData = (
  translations,
  isLoading,
  isGroupView,
  hasFilters
) => {
  if (isLoading) {
    return {
      title: '',
      content: '',
    };
  }

  if (isGroupView) {
    return {
      title: hasFilters ? '' : translations.noGroupResultsTitle,
      content: hasFilters
        ? translations.noSearchResultsMessage
        : translations.noGroupResultsMessage,
    };
  }

  return {
    title: hasFilters ? '' : translations.noResultsTitle,
    content: hasFilters
      ? translations.noSearchResultsMessage
      : translations.noResultsMessage,
  };
};
