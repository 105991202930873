import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import AlertBody from '../components/shared/alertBody';
import Avatar from '../components/shared/avatar';
import ExternalUserIcon from '../assets/icons/vpn_lock.svg';
import OrganizationIcon from '../assets/icons/apartment.svg';
import { checkUserRole, isArray, isArrayEmpty } from './helpers';
import { formatDate } from './dateUtils';
import { ROLES } from '../constants/rolesAndPermissionList';
import { USER_INFO } from '../constants/people';

export const showErrorMessage = text =>
  toast.error(<AlertBody text={text} type="error" />);
export const showSuccessMessage = text =>
  toast.success(<AlertBody text={text} type="success" />);

export const getInitials = (firstName, lastName) => {
  if (lastName && lastName.length > 0 && firstName && firstName.length > 0) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  if (firstName && firstName.length > 1) {
    return `${firstName.charAt(0)}${firstName.charAt(1)}`;
  }
  if (firstName && firstName.length > 0) {
    return `${firstName.charAt(0)}`;
  }
  return '?';
};

export const getPersonFullName = person => {
  if (!person) return '';

  if (person?.first_name || person?.last_name) {
    return `${person.first_name} ${person.isCeo ? '(' : ''}${person.last_name}${
      person.isCeo ? ')' : ''
    }`;
  }

  return person.email;
};

export const parseQuery = query =>
  query.length === 0
    ? []
    : query
        .replace(/^\?/, '')
        .split('&')
        .map(x => {
          const [key, value] = x.split('=');
          return { key, value };
        });

export const parseQueryParams = (query, whiteListedParams = []) =>
  query.length === 0
    ? {}
    : query
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=');
          const isAllowed = whiteListedParams.includes(key);

          if (isAllowed) {
            return {
              ...acc,
              ...(acc[key]
                ? {
                    [key]: [
                      ...(isArray(acc[key])
                        ? [...acc[key], +value || value]
                        : [acc[key], +value || value]),
                    ],
                  }
                : { [key]: +value || value }),
            };
          }
          return acc;
        }, {});

export const parseDuplicateParameters = params => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach(key => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach(element => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

export const manageQuery = ({ key, value, query, shouldDelete }) => {
  const currentQuery = parseQuery(query);
  const queryItemIndex = currentQuery.findIndex(q => q.key === key);

  if (shouldDelete) {
    if (queryItemIndex !== -1) {
      currentQuery.splice(queryItemIndex, 1);
    }
  } else {
    currentQuery.splice(
      queryItemIndex === -1 ? currentQuery.length : queryItemIndex,
      queryItemIndex === -1 ? 0 : 1,
      {
        key,
        value,
      }
    );
  }
  return currentQuery;
};

export const parameterizeQuery = parsedQuery =>
  parsedQuery.reduce(
    (acc, queryItem) => ({ ...acc, [queryItem.key]: queryItem.value }),
    {}
  );

export const orderingString = (column, asc) =>
  `${asc ? '' : '-'}${column.sortAs || column.recordKey}`;

export const capitalize = str => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const getSubdomain = hostname => {
  const regexParse = /[a-z-0-9]{2,63}.[a-z.]{2,5}$/;
  const urlParts = regexParse.exec(hostname);
  return hostname.replace(urlParts[0], '').slice(0, -1);
};

export const isEmployeeInReporters = (employeeId, reporters = {}) => {
  return Object.keys(reporters).includes(employeeId?.toString());
};

export const hasCollaborationPrivileges = (users, userId) =>
  users?.[userId]?.read_only === false;

export const mergeAccessibleProfilesForUser = ({
  reporters,
  accessible,
  role,
}) => {
  const shared = {};
  const isUser = checkUserRole(role, ROLES.USER);

  if (accessible) {
    Object.keys(accessible).forEach(key => {
      shared[key] = true;
    });
  }

  return isUser ? shared : { ...reporters, ...shared };
};

export const prepareTagsForAdding = (tags, categoryId, tagId) => {
  return tags
    .trim()
    .split(',')
    .reduce((acc, el) => {
      const tagName = el.trim().replace(/[\n\r]/g, '');

      if (tagName === '') {
        return acc;
      }
      const newTag = {
        name: tagName,
        is_active: true,
        category: categoryId,
      };

      if (tagId) {
        newTag.id = tagId;
      }
      return acc.concat(newTag);
    }, []);
};

export const reformatUserForSurvey = user => {
  return {
    id: user.id,
    avatar: user.avatar,
    first_name: user.first_name,
    last_name: user.last_name,
  };
};

export const reformatAttributeForSurvey = attribute => {
  return {
    id: attribute.id,
    code: attribute.code,
    name: attribute.name,
    questions: attribute.questions,
    color: attribute.color,
    total_questions: attribute.questions.length,
  };
};

export const truncateText = (str, truncateAfter) =>
  str.length > truncateAfter
    ? `${str.substring(0, truncateAfter).trimEnd().concat('...')}`
    : str;

export const getWorkspaceNameFromHostName = url => {
  return url.slice(0, url.search(process.env.REACT_APP_API_DOMAIN) - 1);
};

export const formatUserForOrgChart = (user, hasAccessToProfile = false) => {
  return {
    id: user.id,
    person: {
      ...user,
      name: truncateText(getPersonFullName(user), 18),
      title: user?.position?.name || '',
      totalReports: user.users.length,
      hasAccessToProfile,
    },
    hasChild: user.users.length > 0,
    hasParent: user.report_to > 0,
    children: [],
  };
};

export const toggleTidioChatVisibility = (shouldHideChat = true) => {
  const chatApi = window.tidioChatApi;

  if (chatApi) {
    if (shouldHideChat) {
      chatApi.hide();
    } else {
      chatApi.show();
      chatApi.open();
    }
  }
};

export const getContentLength = htmlString => {
  return htmlString.replace(/(<([^>]+)>)/gi, '').length;
};

export const customSearch = (
  array,
  string = '',
  isUserSearch = false,
  searchKey = 'name'
) => {
  const searchTerm = string.toLowerCase();

  if (!searchTerm) return array;

  if (isUserSearch) {
    return array.filter(item =>
      getPersonFullName(item).toLowerCase().includes(searchTerm)
    );
  }

  return array.filter(
    item => item[searchKey].toLowerCase().indexOf(searchTerm) !== -1
  );
};

export const hasNextPage = results => !!results.next;

export const prepareTracksWithLevels = (tracks = []) => {
  return tracks.reduce((acc, track) => {
    const trackLevels = track.track_levels.filter(trLvl => trLvl !== null);

    if (!isArrayEmpty(trackLevels)) {
      return [...acc, { ...track, track_levels: trackLevels }];
    }

    return acc;
  }, []);
};

export const onScrollIntoView = ({ current }) => {
  if (current) {
    current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }
};

export const getUserAvatarPicture = (
  role,
  avatar,
  slackImage,
  isOrganizationUser = false
) => {
  const isExternal = checkUserRole(role, ROLES.ASSOCIATE);

  if (isOrganizationUser) return OrganizationIcon;

  if (isExternal) return ExternalUserIcon;

  if (avatar) return avatar;

  return slackImage;
};

export const getPersonalAttributeTooltipText = (label, user) =>
  label.replace('[FULL NAME]', getPersonFullName(user));

export const getUserProfileLink = (userId, isVisible) =>
  isVisible ? `/people/${userId}` : undefined;

export const getRandomColorFromColorArray = colors => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const sortAlphabetically = (items, key = 'name') =>
  items.sort((a, b) => a[key].localeCompare(b[key]));

export const getShareTypeUserLabel = (translations, user) =>
  user.read_only ? translations.viewer : translations.collaborator;

export const getProgressWidth = progress => ({
  width: `${progress === 0 ? 100 : progress * 10}%`,
});

export const getFilterableUsers = users =>
  users.map(user => ({
    ...user,
    name: getPersonFullName(user),
    icon: () => (
      <Avatar
        avatarPicture={getUserAvatarPicture(
          user[USER_INFO.ROLE],
          user.avatar,
          user.slack_image
        )}
        avatarColor={stringToColor(user.id)}
        avatarText={getInitials(
          user[USER_INFO.FIRST_NAME],
          user[USER_INFO.LAST_NAME]
        )}
        extraSmall
      />
    ),
  }));

export const getUserRequestDateLabel = (label, date) =>
  `${label} \u2022 ${formatDate(date, 'MMM d, yyyy')}`;

export const getTimeDifference = (difference, translations) => {
  if (difference === 0) return translations.today;
  if (difference === -1) return translations.yesterday;
  return `${Math.abs(difference)} ${translations.days} ${translations.ago}`;
};
