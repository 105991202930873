import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import AttributeChip from '../attributeChip';
import AsyncListTooltip from '../asyncListTooltip';
import UserAvatar from '../userAvatar';
import Tag from '../tag';
import ConditionalTooltip from '../conditionalTooltip';
import { isArrayEmpty } from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    width: '100%',
  },
  label: {
    color: primary.bluish4,
    lineHeight: '20px',
    marginBottom: spacing(1),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: ({ visibleItemsCount }) =>
      `repeat(${visibleItemsCount}, minmax(auto, min-content)) minmax(auto, min-content)`,
    gridColumnGap: spacing(1),
  },
  moreItems: {
    cursor: 'pointer',
    color: primary.bluish1,
    backgroundColor: primary.bluish8,
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '20px',
    padding: spacing(1, 2),
    maxWidth: 41,
    flexShrink: 0,
    textAlign: 'center',
    userSelect: 'none',
  },
  attributesTooltip: {
    width: '100%',
  },
  noItems: {
    lineHeight: '28px',
  },
  attributeChip: {
    width: 'fit-content',
    maxWidth: '100%',
  },
  userTooltip: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
  },
  tagItemsList: {
    minHeight: 28,
  },
}));

const ListWithTooltip = ({
  className,
  labelClass,
  listClass,
  moreItemsClass,
  label,
  organizationUser,
  items,
  isUserList,
  isTagsList,
  asyncListProps,
  userAvatarProps,
  visibleItemsCount,
  isItemClickable,
  onItemClick,
}) => {
  const classes = useStyles({ visibleItemsCount });
  const totalItems = items?.length;

  const renderTooltipLabel = (item, isClickable) => {
    if (isUserList) {
      return (
        <UserAvatar
          variant="subtitle1"
          user={item}
          clickableCaption={isClickable}
          small
          caption
          {...userAvatarProps}
        />
      );
    }

    if (isTagsList) {
      return (
        <Tag tag={item} color={item?.category?.color} isSelected isSmall />
      );
    }

    return (
      <AttributeChip
        customClass={classes.attributeChip}
        name={item.name}
        color={item.color}
      />
    );
  };

  const renderItem = (item, index) => {
    if (isUserList) {
      return (
        <ConditionalTooltip
          key={`user_${item.id}_item_${index}`}
          className={classes.userTooltip}
          message={getPersonFullName(item)}
          addTooltip
        >
          <UserAvatar
            user={item}
            isOrganizationUser={organizationUser?.id === item.id}
            clickableCaption={isItemClickable(item)}
            onClickHandler={() => onItemClick(item.id)}
            small
            {...userAvatarProps}
          />
        </ConditionalTooltip>
      );
    }

    if (isTagsList) {
      return (
        <Tag
          key={`tag_${item.id}_item_${index}`}
          tag={item}
          color={item?.category?.color}
          isSelected
          isSmall
        />
      );
    }

    return (
      <AttributeChip
        key={`attribute_${item.id}_item_${index}`}
        name={item.name}
        color={item.color}
        attributeNumber={totalItems}
      />
    );
  };

  return (
    <div className={classNames(classes.main, className)}>
      {label && (
        <Typography
          variant="body2"
          className={classNames(classes.label, labelClass)}
        >
          {label}
        </Typography>
      )}
      {isArrayEmpty(items) ? (
        <Typography variant="subtitle2" className={classes.noItems}>
          -
        </Typography>
      ) : (
        <div
          className={classNames(
            classes.list,
            { [classes.tagItemsList]: isTagsList },
            listClass
          )}
        >
          {items
            .slice(0, visibleItemsCount)
            .map((item, index) => renderItem(item, index))}
          {totalItems > visibleItemsCount && (
            <AsyncListTooltip
              labelData={items.slice(visibleItemsCount)}
              renderLabelItem={renderTooltipLabel}
              isItemClickable={isItemClickable}
              onItemClickHandler={onItemClick}
              {...asyncListProps}
            >
              <Typography
                variant="body2"
                className={classNames(classes.moreItems, moreItemsClass)}
              >
                {`+${totalItems - visibleItemsCount}`}
              </Typography>
            </AsyncListTooltip>
          )}
        </div>
      )}
    </div>
  );
};

ListWithTooltip.defaultProps = {
  className: null,
  listClass: null,
  labelClass: null,
  moreItemsClass: null,
  label: '',
  items: [],
  visibleItemsCount: 5,
  asyncListProps: {},
  userAvatarProps: {},
  isUserList: false,
  isTagsList: false,
  organizationUser: null,
  isItemClickable: () => {},
  onItemClick: () => {},
};

ListWithTooltip.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  listClass: PropTypes.string,
  moreItemsClass: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isUserList: PropTypes.bool,
  isTagsList: PropTypes.bool,
  asyncListProps: PropTypes.shape({}),
  organizationUser: PropTypes.object,
  userAvatarProps: PropTypes.shape({}),
  visibleItemsCount: PropTypes.number,
  isItemClickable: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default memo(ListWithTooltip);
